import { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AccStyles from './ExperienceStudio.module.css';


const AccountSuccess = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem('isAccSuccess', true);
      navigate('/seller-experiences/publish-experience-with-stripe');
    }, 2000);
  }, [navigate]);
  

  return (
    <Container fluid className={`${AccStyles.Background}`}>
            <Row className={`mainPad ${AccStyles.viewportSetup}`}>
                <Row className={`text-center ${AccStyles.contentSection}`}>
                    <img
                      src="/Content/Images/General/account_success.png"
                      className={AccStyles.stripeStatusIcon}
                      alt="success"
                    />
                    <h2  className={AccStyles.stripeAccountStatus}>Success</h2>
                    <label className={AccStyles.stripeAccountContent}> Your account is connected. </label>
                    <label className={AccStyles.stripeAccountContent}> Thank You..! </label>
                </Row>
            </Row>
    </Container>
  );
};

export default AccountSuccess;
