import { useEffect } from 'react';
import { Container, Image, Row, ListGroup } from 'react-bootstrap';
import styles from './Education.module.css';
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import { useParams } from 'react-router-dom';


const EducationDetailsPage = () => {

    const { educationObj } = useParams();
    // Decode the item object from the URL parameters
    const data = JSON.parse(decodeURIComponent(educationObj));
    // Now you can use the item object as needed
    // console.log(data);

     // Scroll to the top when the component mounts
     useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <Container>
            <Row className={`boxBody minPadding `} key={data.educationId}>
                        <div className={`mt-1 mb-1 ${styles.backeducationDiv}`} >
                            <Link to="/home">
                            <Image
                                src="/Content/Images/Svgs/logo_background.svg" alt="Logo"
                                className={`roundedCircle ${styles.backeducationlogo}`} />
                            </Link>
                        </div>

                        <div className={`mt-1 mb-1 ${styles.backeducationDiv}`} >
                            <Link to="/seller/education-hub">
                                <FaChevronLeft className={styles.backeducation} /> &nbsp;&nbsp;
                            </Link>
                            <h3 className={styles.educationCaption}>{data.title}</h3>
                        </div>
                        <div>
                            <label className={styles.educationSubCaption}>{data.subTitle}</label>
                            {/* <Image src={data.content} alt={`Feed Theme`}
                                width='100%' height='100%' style={{ borderRadius: '6px' }} /> */}
                        </div>

                        <div className='mt-1 mb-1'>
                            <label className={`mb-2 ${styles.descHeader}`}>Description</label>
                            
                            {data.educationId === "education_1" && (
                                <div>
                                    <p className={styles.descDescription}>
                                    There is a lot to consider when you are planning your workshop to be delivered through the Hapifyr platform.
                                    The best workshops are targeted at a specific audience, focused on a single learning outcome, 
                                    and centered around creating something unique that the user will want to boast about to their friends and colleagues. 
                                    <br /><br />

                                    The key things to consider are:

                                    <ListGroup variant="flush">
                                        <ListGroup.Item className={styles.ListGroupStyles}>
                                            1. <strong>Learning Outcomes:</strong>
                                            <ul>
                                                <li>Define clear, measurable objectives for what participants will achieve.</li>
                                                <li>Ensure outcomes are relevant to the user's goals and needs.</li>
                                            </ul>
                                        </ListGroup.Item>
                                        <ListGroup.Item className={styles.ListGroupStyles}>
                                            2. <strong>Understand Your Audience:</strong>
                                            <ul>
                                                <li>Research the demographic and psychographic characteristics of the participants.</li>
                                                <li>Tailor content complexity and delivery to their existing knowledge and skills.</li>
                                            </ul>
                                        </ListGroup.Item>
                                        <ListGroup.Item className={styles.ListGroupStyles}>
                                            3. <strong>Select Appropriate Content:</strong>
                                            <ul>
                                                <li>Choose content that aligns with the learning outcomes and interests of the audience.</li>
                                                <li>Balance theoretical knowledge with practical applications.</li>
                                            </ul>
                                        </ListGroup.Item>
                                        <ListGroup.Item className={styles.ListGroupStyles}>
                                            4. <strong>Create Interactive Workshops:</strong>
                                            <ul>
                                                <li>Design activities that encourage active participation and guide your users through your creative process.</li>
                                                <li>Allow for some creative freedom by the user so they can adapt your creative process to develop unique creations.</li>
                                            </ul>
                                        </ListGroup.Item>
                                        <ListGroup.Item className={styles.ListGroupStyles}>
                                            5. <strong>Assess Outcomes:</strong>
                                            <ul>
                                                <li>Encourage your users to upload their creations to get feedback from the community.</li>
                                                <li>Encourage your users to create their own workshops, building on the learnings they have made from your workshop.</li>
                                            </ul>
                                        </ListGroup.Item>
                                        <ListGroup.Item className={styles.ListGroupStyles}>
                                            6. <strong>Evaluate and Iterate:</strong>
                                            <ul>
                                                <li>Gather feedback from participants through surveys and analytics.</li>
                                                <li>Regularly review and update the workshop content and delivery methods to reflect best practices and participant feedback.</li>
                                            </ul>
                                        </ListGroup.Item>
                                    </ListGroup>

                                    Each of these considerations plays a critical role in the design and execution of an online workshop that is educational, engaging, and effective.
                                    </p>
                                </div>
                            )}
                            {data.educationId === "education_2" && (
                                <div>
                                <p className={styles.descDescription}>
                                Have you ever watched a video that captivated you from start to finish? The secret behind such compelling content is often excellent storytelling.
                                In this digital age, where videos are a dominant form of communication, mastering the art of storytelling can set your content apart.
                                Whether you're a budding YouTuber, a brand marketer, or just passionate about video creation, understanding how to weave a captivating narrative is key.
                                <br /><br />

                                <strong>Section 1: The Essence of Story telling in Video Content </strong> Story telling is an ancient art form, but it's also a 
                                crucial skill in modern video production. A good story can evoke emotions, convey messages, and connect with audiences on a deeper level.
                                In video content, story telling combines visual and auditory elements, creating a powerful medium to engage viewers.
                                Remember, every video, no matter how short, tells a story. The challenge is to make it a compelling one.
                                <br /><br />

                                <ListGroup.Item className={styles.ListGroupStyles}>
                                    <strong>Section 2: Building Blocks of a Good Story </strong> Every memorable story has a structure: a beginning, middle, and end. In video storytelling, this translates to:
                                        <br/>
                                        <ul>
                                            <li><strong>Introduction:</strong> Set the scene and introduce the characters. Grab your audience's attention early.</li>
                                            <li><strong>Development:</strong> This is where the main content or message of your video unfolds. Keep it engaging and relevant.</li>
                                            <li><strong>Conclusion:</strong> End with a strong, memorable message or call to action. Leave your audience with something to think about or act upon.</li>
                                        </ul>
                                </ListGroup.Item>

                                <ListGroup.Item className={styles.ListGroupStyles}>
                                    <strong>Section 3: Engaging Your Audience </strong> Engagement is key in video storytelling. Here are some tips:
                                        <br/>
                                        <ul>
                                            <li><strong>Relatability:</strong> Create content that resonates with your audience. Understand their interests, fears, and desires.</li>
                                            <li><strong>Emotion:</strong> Whether it's humour, sadness, or excitement, tapping into emotions can make your story more impactful.</li>
                                            <li><strong>Visuals and Sound:</strong> Use high-quality visuals and sound to enhance your story. They should complement, not distract.</li>
                                        </ul>
                                </ListGroup.Item>

                                <ListGroup.Item className={styles.ListGroupStyles}>
                                    <strong>Section 4: Common Pitfalls to Avoid </strong> Even the best ideas can falter with poor execution. Common storytelling mistakes include:
                                        <br/>
                                        <ul>
                                            <li><strong>Lack of Clarity:</strong> Don’t confuse your audience. Keep your story focused and straightforward.</li>
                                            <li><strong>Overcomplication:</strong> Simplicity often works best. Don’t overload your video with too many elements.</li>
                                            <li><strong>Ignoring the Audience:</strong> Always keep your audience in mind. What works for one group may not resonate with another.</li>
                                        </ul>
                                </ListGroup.Item>


                                <strong>Conclusion:</strong> Storytelling in videos is an art that requires practice, creativity, and a deep understanding of your audience. By mastering this skill, you can create videos that are not only watched but remembered and shared. Start experimenting with these storytelling techniques in your next video project and see the difference for yourself.
                                <br/><br/>
                                Remember, the world of video content is vast and ever-evolving. Stay curious, keep learning, and keep creating. Your next video could be the one that touches hearts and inspires minds.

                                </p>
                                </div>
                            )}
                            {data.educationId === "education_3" && (
                                <div>
                                <p className={styles.descDescription}>
                                Embarking on your video creation journey can be exciting, but also daunting, especially when it comes to equipment.
                                The good news is, you don't need to break the bank to get started. This guide will walk you through essential,
                                budget-friendly video equipment for beginners, ensuring you have everything you need to create quality content without overspending.
                                <br /><br />

                                <strong>Section 1: The Basics of Video Equipment </strong> Before diving into specific products,
                                it's important to understand the basic equipment needed for video production. This typically includes a camera, microphone, lighting, and a tripod.
                                Each piece plays a crucial role in ensuring the quality of your videos.
                                <br /><br />

                                <ListGroup.Item className={styles.ListGroupStyles}>
                                    <strong>Section 2: Choosing the Right Camera </strong>
                                        <br/>
                                        <ul>
                                            <li><strong>Smartphones:</strong> Modern smartphones are a great starting point. They offer good video quality and are perfect for beginners.</li>
                                            <li><strong>Budget-Friendly Cameras:</strong> If you're looking for something more professional, consider entry-level DSLRs or mirrorless cameras that offer more control over your footage.</li>
                                        </ul>
                                </ListGroup.Item>

                                <ListGroup.Item className={styles.ListGroupStyles}>
                                    <strong>Section 3: Sound Matters - Affordable Microphones </strong> Good audio quality is essential. Here are some cost-effective options:
                                        <br/>
                                        <ul>
                                            <li><strong>Lapel Microphones:</strong> Great for interviews and vlogs.</li>
                                            <li><strong>Shotgun Microphones:</strong> Ideal for general use, especially if you're recording in different environments.</li>
                                        </ul>
                                </ListGroup.Item>

                                <ListGroup.Item className={styles.ListGroupStyles}>
                                    <strong>Section 4: Lighting on a Budget </strong> Proper lighting can dramatically improve your video quality. Budget-friendly options include:
                                        <br/>
                                        <ul>
                                            <li><strong>Ring Lights:</strong> Popular among vloggers, they provide even lighting and are great for close-ups.</li>
                                            <li><strong>Softbox Lights:</strong> These offer more diffused light, ideal for larger spaces or more cinematic looks.</li>
                                        </ul>
                                </ListGroup.Item>

                                <ListGroup.Item className={styles.ListGroupStyles}>
                                    <strong>Section 5: Keeping it Steady - Tripods and Stabilizers </strong> A shaky video can be distracting. Consider these affordable options:
                                        <br/>
                                        <ul>
                                            <li><strong>Basic Tripods:</strong> Essential for stationary shots.</li>
                                            <li><strong>Handheld Stabilizers:</strong> For more dynamic, moving shots.</li>
                                        </ul>
                                </ListGroup.Item>

                                <strong>Section 6: Editing Software - Free and Low-Cost Options</strong> Editing is where your video comes to life. There are several free or affordable editing software options that are user-friendly and offer a range of features.
                                <br/><br/>

                                <strong>Conclusion:</strong> Starting your video production journey doesn't have to be expensive. With the right budget-friendly equipment, you can produce high-quality videos and gradually upgrade your gear as you grow. Remember, the story and content of your video are as important as the quality of your equipment. Happy filming!

                                </p>
                                </div>
                            )}
                            {data.educationId === "education_4" && (
                                <div>
                                    <p className={styles.descDescription}>
                                    Embarking on a journey to become a content creator is an exhilarating venture in today's digital era. With the rise of platforms like YouTube, Instagram, and TikTok, the opportunity to turn your passion into a thriving online presence has never been easier. However, navigating the world of content creation requires more than just a creative spark. Here are the top five essential tips for aspiring content creators, designed to help you build a successful, engaging, and profitable online presence.
                                    <br/><br/>

                                    1. <strong>Discover Your Unique Niche and Understand Your Audience</strong>
                                    <br/><br/>
                                    The first step in your content creation journey is identifying your niche. What are you passionate about? What unique insights can you offer? Whether it's lifestyle, technology, travel, or any other field, pinpointing your niche is crucial. Once identified, understanding your target audience is key. Who are they? How do they prefer to interact with content on your niche topic? Tailoring your content to their interests and needs will make it more engaging and relevant, helping you to build a loyal following.
                                    <br/><br/>
                                    Hot Tip from Hapifyr: On Hapifyr our Hosts create video workshops, both live and pre-recorded to be shared by individuals or in groups. When Hosts set up their Product in the online store, key attributes about the potential audience and the video workshop are collected to help prospective buyers find and purchase workshops that are relevant to their interests.
                                    <br/><br/>


                                    2. <strong>Consistency: The Secret Ingredient to Content Success</strong>
                                    <br/><br/>
                                    Consistency in content creation is not just about how often you post, but also maintaining a steady theme, style, and quality. A consistent approach helps in building a dedicated audience. Implement a content calendar to plan and schedule your posts or product releases effectively. Remember, always prioritise quality over quantity.
                                    <br/><br/>


                                    3. <strong>Engagement: Building and Nurturing Your Online Community</strong>
                                    <br/><br/>
                                    Creating a community is about more than just accumulating large numbers of followers. By engaging with your audience through comments, feedback, and discussions you are building relationships with your connections. Collaborations with other content creators can also broaden your reach. An engaged and supportive community is fundamental to the long-term success of your content creation efforts.
                                    <br/><br/>
                                    Hot Tip from Hapifyr: On the Hapfiyr platform we've created tools to help build community connections, including reviews, referrals, comments and a messaging capability for hosts and community members to connect with each other.
                                    <br/><br/>


                                    4. <strong>Adaptability: Staying Ahead in the Fast-Paced Digital Landscape</strong>
                                    <br/><br/>
                                    The digital content world is dynamic, with trends constantly evolving. Stay informed about the latest developments in your niche and the wider content creation sphere. While adapting to new trends and technologies is important, maintaining your authenticity is crucial.
                                    <br/><br/>


                                    5. <strong>Monetization Strategies and Legal Considerations</strong>
                                    <br/><br/>
                                    For those looking to monetize their content, it's important to explore various revenue streams such as ad revenue, sponsorships, and affiliate marketing. However, be mindful of the legal aspects related to content monetization, including copyright laws and disclosure requirements.
                                    <br/><br/>
                                    Hot Tip from Hapifyr: Our philosophy is to reward our community fairly for their contribution to the platform and the broader community. That's why our Hosts earn 50% of the revenue made from the sale of the products (workshops) they sell. In addition, anyone who refers a host to the platform is rewarded with a 5% referral fee for any products sold by that host. And, for referral of buyers who successfully purchase a recommended workshop, our community can earn 5% of the purchase price.
                                    <br/><br/>


                                    <strong>Conclusion</strong>
                                    <br/><br/>
                                    Becoming a successful content creator in the digital age is a journey of continuous learning and adaptation. By focusing on these five key strategies, you can establish a strong foundation for your online presence. Remember, the path to success in content creation is paved with patience, persistence, and a genuine passion for engaging with your audience.
                                    
                                    </p>
                                </div>
                            )}
                            {data.educationId === "education_5" && (
                                <div>
                                    <p className={styles.descDescription}>
                                    Honing your editing skills can elevate your online workshop videos! Here are some essential editing tips to make your content stand out:
                                    <br/><br/>

                                    <strong>Start with a Plan:</strong> Organise your footage and plan the video structure before diving into editing. It helps maintain a coherent flow.
                                    <br/><br/>

                                    <strong>Quality over Quantity:</strong> Trim the fat! Remove unnecessary segments or pauses to keep the video concise and engaging.
                                    <br/><br/>

                                    <strong>Use Jump Cuts Wisely:</strong> Jump cuts can maintain the pace but ensure they don't disrupt the narrative flow. They're handy for removing filler moments.
                                    <br/><br/>

                                    <strong>Add B-Roll Footage:</strong> Incorporate supplementary visuals or clips to add depth and context. It makes the video visually engaging.
                                    <br/><br/>

                                    <strong>Smooth Transitions:</strong> Use transitions like fades, cuts, or wipes to create smooth scene changes. Avoid overly flashy transitions that distract from the content.
                                    <br/><br/>

                                    <strong>Balance Audio:</strong> Ensure a consistent audio level throughout the video. Adjust volume levels, use background music judiciously, and eliminate background noise.
                                    <br/><br/>

                                    <strong>Captivating Intros and Outros:</strong> Create attention-grabbing introductions and satisfying conclusions. They set the tone and leave a lasting impression.
                                    <br/><br/>

                                    <strong>Graphics and Text:</strong> Incorporate text overlays, titles, or graphics to emphasize key points or guide viewers. Make them clear and easy to read.
                                    <br/><br/>

                                    <strong>Color Correction and Grading:</strong> Enhance visuals by adjusting colours, brightness, and contrast. Maintain consistency in colour grading for a polished look.
                                    <br/><br/>

                                    <strong>Test and Review:</strong> Watch your video multiple times to spot areas for improvement. Get feedback, if possible, to refine your editing skills further.
                                    <br/><br/>
                                    

                                    Remember, practice makes perfect! Don't be afraid to experiment with different editing techniques to find your unique style. With each video, you'll refine your skills and create more captivating content for your online creative workshops! 🎬✨
                                    </p>
                                </div>
                            )}
                            {data.educationId === "education_6" && (
                                <div>
                                    <p className={styles.descDescription}>
                                    
                                    Ready to make your videos super engaging? Want to know how to make professional-looking workshops that will engage your audience, without needing a whole lot of expensive kit or hours and hours of training? Here's the lowdown on capturing top-notch footage with just your trusty smartphone:
                                    <br/><br/>

                                    <strong>Stability Rules:</strong> Pop that phone on a tripod or grab a stabilizer. Steady footage? Check!
                                    <br/><br/>

                                    <strong>Position, Position, Position:</strong> Camera placement is super important. When hosting a yoga workshop, consider positioning the camera to capture your entire body during poses and transitions. Try different angles to find the most flattering and practical view. And, if you're hosting a cooking or art workshop shots that capture the whole cooking or creative area are important, and so too are the close-ups for detailed techniques or when showcasing key ingredients.
                                    <br/><br/>

                                    <strong>Let There Be Light:</strong> Natural light's your BFF. Face a window for that radiant glow or snag some soft box lights for that extra oomph! Soft lighting highlights movements, so avoid harsh shadows or overexposure, and try to illuminate your area evenly.
                                    <br/><br/>

                                    <strong>Space Set-Up:</strong> Keep it clean, keep it tidy. A clutter-free zone keeps the focus on you, and not your background. Consider the mood you're creating for your workshop, whether that's a serene zone for yoga, a creative space or home-cook's paradise, the space around you will help set the mood for your workshop. Ensure all your tools and ingredients are easily accessible and within reach. Consider the great outdoors, but remember, the outdoors creates additional challenges for sound and those uncontrollable elements like the weather!
                                    <br/><br/>

                                    <strong>Audio A-Game:</strong> Clear audio is key! Remove as much background noise as possible, by finding a nice quiet location. And, for workshops where the sound effects will add to the experience, make sure the workshop participants can hear the pan sizzle! No matter what your workshop is, your voice is the most essential ingredient for clear instructions and an enjoyable experience. It will set the tone for your workshop, so consider whether it needs to be calm and relaxing, or fun and energetic! Finally, consider using a lavalier mic for primo sound.
                                    <br/><br/>

                                    <strong>Camera Charms:</strong> Explore your phone's video settings. Adjust things like resolution, frame rate, and exposure for primo quality.
                                    <br/><br/>

                                    <strong>Frame It Up:</strong> Plan your shots! Consider close-ups for detailed movements or instructions and wide shots for a broader perspective. This adds variety and depth to the workshop experience. Remember, workshop participants will want to see you as well as what your demonstrating, so make sure you feature in your video.
                                    <br/><br/>

                                    <strong>Consistent Vibes:</strong> Keep those frames consistent, like a pro! No jarring changes here, just smooth transitions. Plan your content and the sequence of events carefully, clearly explain each step, helping your participants to follow along seamlessly. Keep the video engaging by balancing the pace. Avoid lengthy stretches without interaction. Speed up or skip repetitive tasks if needed.
                                    <br/><br/>

                                    <strong>Content That Wows:</strong> Break down your workshops, so they're easy to follow. Use visuals and demos to support your explanations. Remember, some people will learn by watching what you do.
                                    <br/><br/>

                                    <strong>Content For All:</strong> Consider variations and modifications for different skill levels, including the processes participants will follow, the ingredients or tools they have available, and their personal skill or experience level. Highlight specific techniques or tricky steps, with close-up shots and detailed explanations.
                                    <br/><br/>

                                    <strong>Edit Like a Boss:</strong> Fire up those editing apps. Trim unnecessary sections, caption key moments, and spice up colours for that captivating finish.
                                    <br/><br/>

                                    <strong>Practice, Practice, Practice:</strong> Run a test. Check lighting, audio, and framing. Maybe even get some friends to try out your workshop and ask them for their honest feedback. Tweak and tweak till it's perfection!
                                    <br/><br/>

                                    Remember, it's not just about the tech – it's your expertise and passion that'll light up those videos! Embody the persona you're seeking to create and make it a delightful experience by sharing not just the steps to follow, but the joy of creating or achieving something amazing! 🌟
                                    <br/><br/>
                                    </p>
                                </div>
                            )}

    
                        </div>
            </Row>   
        </Container>

    );
};

export default EducationDetailsPage;
