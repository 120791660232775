import React, { useState, useRef } from 'react';
import { Row, Col, Button, Modal, Form, InputGroup } from 'react-bootstrap';
import styles from './hub.module.css'; 
// import { FaTimes } from "react-icons/fa";
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import hubServices from '../../Services/hubServices';


function BuyerCreateReview({ show, handleClose, experienceId }) {
  
    const fileInputRef = useRef(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [review, setReview] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailure, setIsFailure] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errors, setErrors] = useState({
        selectedImagesError: "",
        ratingError: "",
        reviewError: "",
    });
    
    const [rating, setRating] = useState(0);
    const handleStarClick = (starId) => {
        setRating(starId);
      };

      const openFileInput = () => {
        fileInputRef.current.click();
      };
    
      const handleImageUpload = (event) => {
        const files = event.target.files;
        const images = Array.from(files);
        setSelectedImages(images);
      };
    
    //   const removeImage = (index) => {
    //     const updatedImages = [...selectedImages];
    //     updatedImages.splice(index, 1);
    //     setSelectedImages(updatedImages);
    //   };

  const saveBuyerReview = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    const newErrors = { 
      selectedImagesError: "",
      ratingError: "",
      reviewError: "",
    };
    setErrors(newErrors);
    
    if (!experienceId) {
      newErrors.ratingError = "Please provide experienceId first.";
      setErrors(newErrors);
      return;
    }
    if (selectedImages != null && selectedImages.length === 0) {
      newErrors.selectedImagesError = "Please upload an image for an review";
      setErrors(newErrors);
      return;
    }
    if (!rating) {
      newErrors.ratingError = "Please select rating.";
      setErrors(newErrors);
      return;
    }
    if (!review) {
      newErrors.reviewError = "Please provide your review.";
      setErrors(newErrors);
      return;
    }
    
    if (newErrors.selectedImagesError || newErrors.ratingError || newErrors.reviewError) {
      setErrors(newErrors);
      return;
    }
    
    try {
      setIsLoading(true);
      setIsSuccess(false);
      setIsFailure(false);

      const formData = new FormData();

      formData.append('story', experienceId);
      formData.append('contentCount', selectedImages.length);
      formData.append('rating', rating);
      formData.append('comment', review);

      selectedImages.forEach((media, index) => {
        formData.append(`seq_${index + 1}`, media);
      });
        // Convert FormData to a plain JavaScript object
      const formDataObj = Object.fromEntries(formData.entries());
      const response = await hubServices.saveBuyerReviewPost(formDataObj);
      if (response.statusCode === 200) {
        if(response.data !== null && response.data.length > 0 && response.data[0].inserted){
          setIsSuccess(true);
          setIsFailure(false);
        } else {
          setIsSuccess(false);
          setIsFailure(true);
          setErrorMessage(response.message);
        }
        
        } else {
          setIsSuccess(false);
          setIsFailure(true);
          setErrorMessage(response.message);
        }
      
    } catch (error) {
      console.error("API Error:", error);
      setIsSuccess(false);
      setIsFailure(false);
    } finally {
      setIsLoading(false);
      setSelectedImages([]);
      setReview("");
      setRating("");
      // setTimeout(() => {
      //   setIsSuccess(false);
      //   setIsFailure(false);
      //   setErrors({
      //     selectedImagesError: "",
      //     ratingError: "",
      //     reviewError: "",
      //   });
      // }, 3000);
    }
  };


  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName={styles.dialogClass} className={styles.BuyerReviewModaldiv}>
        <Modal.Header closeButton className={`ModalCloseBtn`}>
        </Modal.Header>

        <Modal.Body>
        {isLoading ? (
            <LoadingOverlay />

          ) : isSuccess ? (

              <div>
                <Row className={'text-center'}>
                <Col xs={12}>
                  <h5 style={{color: 'green',fontWeight: 'bold', fontStyle: 'revert'}}>Submission Success!</h5>
                  <label>Thank you for submitting your review. </label>
                </Col>
                </Row>
              </div>

          ) : isFailure ? (
            
              <div>
                <Row className={'text-center'}>
                <Col xs={12}>
                  {errorMessage ? (
                      <div style={{ color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em' }}>
                        {errorMessage}
                      </div>
                    ) : (
                      <div style={{ color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '1.2em' }}>
                        Review Failed!
                      </div>
                    )}
                    <label className='mt-2'>Thank you. </label>
                </Col>
                </Row>
              </div>

          ) : (

              <div>
              <Row className={'text-center'}>
                <Col xs={12}>
                  <h5 className={`mb-2 ${styles.buyerReviewHeader}`}>How was your experience?</h5>
                  <label className={styles.buyerReviewContent}>It’s time to share your creation! Take a photo and upload it now. Then provide your star rating and review. Your ‘<b>Boast</b>’ will appear in the feed for other users to enjoy.</label>
                </Col>

                <Col xs={12} className='mt-3 mb-2 text-center'>
                <Button className={`mb-3 ${styles.uploadReviewBtn}`} onClick={openFileInput}>
                        Upload a photo
                        <input
                        type="file"
                        accept="image/*, video/*"
                        multiple
                        onChange={handleImageUpload}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        />
                    </Button>

                    {selectedImages.map((image, index) => (
                        <div key={index} className="uploaded-image">
                          <img src={URL.createObjectURL(image)} alt={`Uploaded ${index + 1}`}
                          width={100} height={80} />
                          {/* <FaTimes onClick={() => removeImage(index)} /> */}
                        </div>
                    ))}

                {errors.selectedImagesError && <div className={styles.errorMessage}>{errors.selectedImagesError}</div>}
                <br/>
                
                <div>
                    {[1, 2, 3, 4, 5].map((starId) => (
                        <Star
                        key={starId}
                        starId={starId}
                        filled={starId <= rating}
                        onClick={handleStarClick}
                        />
                    ))}
                    </div>
                    {errors.ratingError && <div className={styles.errorMessage}>{errors.ratingError}</div>}
                  <br/>

                <Form.Group controlId="BuyerReviewMsg" className='mb-2'>
                    <InputGroup>
                        <Form.Control 
                        as="textarea"
                        className={styles.buyerReviewFormControl}
                        onChange={(e) => setReview(e.target.value)}
                        aria-label="Enter Message"
                        placeholder="Enter Message" 
                        rows={4} />
                    </InputGroup>
                    {errors.reviewError && <div className={styles.errorMessage}>{errors.reviewError}</div>}
                    </Form.Group>
                </Col>

              </Row>
              <Row xs={12}>
                <Button onClick={saveBuyerReview} className={styles.buyerReviewSubmit}> Submit </Button>
              </Row>
            </div>

            )}
        </Modal.Body>
      </Modal>
    </>
  );
}


const Star = ({ starId, filled, onClick }) => (
    <span style={{fontSize: '2em'}}
      className={filled ? 'star filled' : 'star'}
      onClick={() => onClick(starId)}
    >
      {/* You can use any star icon or representation here */}
      {filled ? '★' : '☆'}
    </span>
  );



export default BuyerCreateReview;