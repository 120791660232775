import React, { useState, useEffect } from 'react';
import Header from './Navbar/Header';
import SellerdbContent from './SellerDashboard/SellerdbContent';
import EducationHubDetails from './SellerDashboard/EducationHubDetails';
import SellerPublicProfilePage from './SellerDashboard/PublicProfile/SellerPublicProfilePage';
import UnpublishedContent from './SellerDashboard/UnpublishedContent';
import ScrollToTop from './BaseComponents/ScrollToTop';
import { useParams } from 'react-router-dom';


const SellarDashboard = () => {

  const [loginStatus] = useState('loggedIn');
  const { sellerStatus } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [sellerStatus]);


  return (
    <div className="SellarDashboardDiv">
      <ScrollToTop />
      <Header loginStatus={loginStatus} />
      
      {sellerStatus === 'dashboard' && <SellerdbContent />}
      {sellerStatus === 'education-hub' && <EducationHubDetails type={sellerStatus} />}
      {sellerStatus === 'public-profile' && <SellerPublicProfilePage type={sellerStatus} />}
      {sellerStatus === 'unpublished-workshops' && <UnpublishedContent type={sellerStatus} />}
       
    </div>

  );
}

export default SellarDashboard;
