import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CartProvider } from './Components/Navbar/CartContext';
import { AuthProvider } from './Components/Context/AuthContext';
import AuthPage from './Components/AuthPage';
import Home from './Components/Home';
import CRP from './Components/CRP';
import Signin from './Components/User/Login/Signin';
import Signup from './Components/User/Signup/Signup';
import Loader from './Components/BaseComponents/Loader';
import SellarDashboard from './Components/SellarDashboard';
import SellerExperiences from './Components/SellerExperiences';
import ManageExperiences from './Components/ManageExperiences';
import ProductDetails from './Components/ProductDetails';
import ViewCartDetails from './Components/ViewCartDetails';
import ExperienceHubDashboard from './Components/ExperienceHubDashboard';
import SellerBankDetails from './Components/SellerBankDetails';
import ExperienceHubItem from './Components/ExperienceHubItem';
import PaymentStatus from './Components/PaymentStatus';
import UpdateUserProfile from './Components/UpdateUserProfile';
import GeneralPages from './Components/GeneralPages';
import AccountStatus from './Components/AccountStatus';
import EducationDetails from './Components/EducationDetails';
import SellerPublicProfile from './Components/SellerPublicProfile';
import RepublishContents from './Components/SellerDashboard/Republish/RepublishContents';
import ScrollToTop from './Components/BaseComponents/ScrollToTop';
import ResponsiveMessaging from './Components/ResponsiveMessaging';
import CommonSuccess from './Components/BaseComponents/CommonSuccess';


const App = () => {


  return (
    <AuthProvider>
    <CartProvider>
      <Loader />
      <Router>
        <Loader />
        <ScrollToTop />
        <div className="App">
          <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="/:homestatus/:substatus?" element={<Home />} />
            <Route path="/chat" element={<ResponsiveMessaging />} />
            <Route path="/signin/:loginstatus" element={<Signin />} />
            <Route path="/signup/:signupstatus/" element={<Signup />} />
            <Route path="/seller/:sellerStatus" element={<SellarDashboard />} />
            <Route path="/seller-experiences/:currentStatus" element={<SellerExperiences />} />
            <Route path="/workshops/:type" element={<ManageExperiences />} />
            <Route path="/product_details/:feedId/" element={<ProductDetails />} />
            <Route path="/education-details/:educationObj" element={<EducationDetails />} />
            <Route path="/cart/:cartStatus" element={<ViewCartDetails />} />
            <Route path="/experiencehub/:status" element={<ExperienceHubDashboard />} />
            <Route path="/experiencehub/experience/:id" element={<ExperienceHubItem />} />
            <Route path="/sellerbank/:bankStatus" element={<SellerBankDetails />} />
            <Route path="/stripe/:paymentStatus" element={<PaymentStatus />} />
            <Route path="/profile/:profileStatus" element={<UpdateUserProfile />} />
            <Route path="/:generalType" element={<GeneralPages />} />
            <Route path="/seller-account/:accountStatus" element={<AccountStatus />} />
            <Route path="/seller/public-profile/:sellerId" element={<SellerPublicProfile />} />
            <Route path="/host/:republishStatus/:republishId" element={<RepublishContents />} />
            <Route path="/crp/:CRPStatus" element={<CRP />} />
            <Route path="/hapifyr/:commonStatus" element={<CommonSuccess />} />

          </Routes>
        </div>
      </Router>
    </CartProvider>
    </AuthProvider>
  );
}

export default App;
