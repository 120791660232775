import { Container, Row, Card, Image } from 'react-bootstrap';
import baseStyles from './base.module.css';
import { BsChevronRight } from "react-icons/bs";


const UserPurchasesCount = (props) => {

    const count = props.count;

  return (
    
    <Container>
    <Row>

        <Card className={baseStyles.CountBox}>
          <Card.Body className={baseStyles.responsiveCountBody}>
            <Card.Title className={`mb-4 ${baseStyles.TitleCard}`}>
              Total Purchases
              <div className={baseStyles.IconSetup}>
              <Image
                  src='/Content/Images/icons/purchases.png'
                  alt="purchases_icon"
                  className={baseStyles.IconSetImage}   
                />
              </div>
              </Card.Title>
            <Card.Text className={baseStyles.TitleCardCount}>{count}</Card.Text>
                <div className={baseStyles.BaseRightIcon}>
                    <BsChevronRight style={{opacity: '0'}} />
                </div>
          </Card.Body>
        </Card>

    </Row>
  </Container>
      
  );
};

export default UserPurchasesCount;