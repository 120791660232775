import { useEffect } from 'react';
import { Container, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './Republish.module.css';


const RepublishSuccess = () => {
  
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/workshops/manage-workshops');
    }, 2000);
  }, [navigate]);
  

  return (
    <Container fluid className={`${styles.Background}`}>
            <Row className={`mainPad ${styles.viewportSetup}`}>
                <Row className={`text-center ${styles.contentSection}`}>
                    <Image
                      src="/Content/Images/General/user-success-bg.png"
                      className={styles.workshopSuccessIcon}
                      alt="success"
                    />
                    <h2  className={styles.republishSuccessStatus}>Success</h2>
                    <label className={styles.republishSuccessText}> Your workshop has been successfully modified and published. </label>
                </Row>
            </Row>
    </Container>
  );
};

export default RepublishSuccess;
