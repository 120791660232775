import {  useState, useEffect  } from 'react';
import { Row, Col } from 'react-bootstrap';
import UserProfile from '../BaseComponents/UserProfile';
import SellerdbActions from './SellerdbActions';
import UserWorkshopsList from './UserWorkshopsList';
import experienceServices from '../../Services/experienceServices';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import styles from './SellerStyle.module.css';


const ManagingExperiences = () => {

    const [userWorkshops, setUserWorkshops] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
  
      async function fetchUserWorkshops() {
        try {
          setIsLoading(true);
          const response = await experienceServices.fetchAllUserCreatedWorkshops();
          // console.log("fetchUserWorkshops Response:", response);
          if (response !== null && response.statusCode === 200) {
            if (response.data !== null && response.data !== "") {
                // setUserWorkshops(response.data);

              // Custom function to format date as Day Month Year
              const formatDate = (date) => {
                const options = { day: '2-digit', month: 'short', year: '2-digit' };
                return date.toLocaleDateString('en-GB', options);
            };

            const finalResponse = response.data.map(experience => {
              const dobDate = experience.dateCreated ? new Date(experience.dateCreated) : null;
              if (dobDate) {
                  return {
                      ...experience,
                      dateCreated: formatDate(dobDate)
                  };
              } else {
                  return experience;
              }
            });
  
            setUserWorkshops(finalResponse);
            }
          }

        } catch (error) {
          console.error("API Error:", error);
        } finally {
          setIsLoading(false);
        }
      }
      fetchUserWorkshops();
  
    }, []);


  return (

      <main className="MainpartSetup HomeViewportHt">

        <Row className={`mainPad stickySidebar`}>
          <Col xs={12} lg={3} className={`${styles.responsiveDisplay}`}>
            <UserProfile />
            <br />
            <SellerdbActions />
          </Col>

          <Col xs={12} lg={9}>
            {isLoading ? (
              <LoadingOverlay />
            ):(
              <UserWorkshopsList workshopsList={userWorkshops} />
            )}
          </Col>
          
        </Row>
      </main>

  );
}

export default ManagingExperiences;
