import { useState } from 'react';
import Header from './Navbar/Header';
import SendBird from './SendBird';


const ResponsiveMessaging = () => {
  
  const [loginStatus] = useState('loggedIn');

  return (
    <div className="responsiveMessagingSection">
      <Header loginStatus={loginStatus} />

      <main className="MainpartSetup viewportHt mainPad">
        <SendBird />
      </main>
    </div>
  );
}


export default ResponsiveMessaging;

