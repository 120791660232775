import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, InputGroup, Image } from 'react-bootstrap';
import loginStyles from './signin.module.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SmallFooter from '../../BaseComponents/SmallFooter';
import loginApiServices from '../../../Services/loginApiServices';
import AuthContext from '../../Context/AuthContext';


const SigninUser = () => {

  const { setIsLoading } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const [user, setUser] = useState({
    email: "",
    password: "",
    isAgreed: false,
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
    isAgreed: "",
  });

  const handleInputs = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setUser({ ...user, [name]: checked });
      setErrors({ ...errors, [name]: '' });
    } else {
      setUser({ ...user, [name]: value });
      setErrors({ ...errors, [name]: '' });
    }
    setErrorMessage("");
  };

  const validateEmail = (email) => {
    if (email && email.includes("@")) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    } else {
      return /^(?!.*\s)(?!.*[\W_])(?!.*(admin|root|system))(?!.*(?:profanity|offensive))(?!.*(?:\d{10}|\d{4}-\d{2}-\d{2}))(?!.*<script>)(?!.*[;'])(?!.*\b(?:SELECT|UPDATE|DELETE)\b)[A-Za-z\d]{5,20}$/.test(email);
    }
  };

  const userLoginValidation = async (e) => {
    
    e.preventDefault();
    const { email, password, isAgreed } = user;
    const newErrors = { email: "", password: "", isAgreed: ""};
    
    if (!email) {
      newErrors.email = "Please enter Email Id";
      document.querySelector("#formUserId").focus();
      setErrors(newErrors);
      return;
    } else if (!validateEmail(email)) {
      newErrors.email = 'Please enter a valid Email Id';
      setErrors(newErrors);
      return;
    }
    
    if (!password) {
      newErrors.password = "Please enter your password";
      document.querySelector("#formPassword").focus();
      setErrors(newErrors);
      return;
    }
    
    if (newErrors.email || newErrors.password || newErrors.isAgreed) {
      setErrors(newErrors);
      return;
    }
    
    setErrorMessage("");
    setIsLoading(true);
    
    try {
      const response = await loginApiServices.userLogInAuth(email, password, isAgreed);
      
      if (response.statusCode === 200) {
        window.location.href = `/home`;
        } else {
          setErrorMessage(response.message);
        }
      
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  

  
  return (
    <Container fluid className={`d-flex flex-column min-vh-100 ${loginStyles.signinContainers}`}>
      <Row className={`${loginStyles.signBackground} flex-grow-1`}>
          <Row className={`mainPad d-flex align-items-center justify-content-between`}>
                <Col xs={12} lg={5} className={loginStyles.responsiveDisplay}>
                  <Row className='mt-5'>
                    <Image
                      src='/Content/Images/login_Image.png'
                      alt="login_banner"
                      className="loginBanner"
                    />
                  </Row>
                </Col>
                <Col xs={0} lg={2} className={loginStyles.responsiveDisplay}></Col>

                <Col xs={12} lg={5}>
                  <div className={`${loginStyles.signinSection} ${loginStyles.logIntop}`}>
                      <Row className='text-center'>
                          <Col>
                            <Button className={`rounded-pill ${loginStyles.IconLogins}`}>
                              <Image
                                src='/Content/Images/Signin/icon_login.png'
                                alt="login_icon"
                                width={30}
                                height={30}
                              />
                            </Button>
                          </Col>
                        <label className={`styleHeader`}>Login to your Account </label>
                      </Row>
                      <br/>
                      <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
                      
                      <Row>
                        <Form method="POST" onSubmit={userLoginValidation}>
                            <Form.Group controlId="formUserId">
                                <Form.Label className='mb-1'>Email Address *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="email"
                                    value={user.email}
                                    onChange={handleInputs}
                                />
                                <Form.Text className="text-danger">{errors.email}</Form.Text>
                            </Form.Group>

                            <br/>
                            <Form.Group controlId="formPassword">
                                <Form.Label className='mb-1'>Password * </Form.Label>
                                        <InputGroup>
                                            <Form.Control style={{borderRight: 'none'}}
                                                type={passwordVisible ? 'text' : 'password'}
                                                placeholder=""
                                                name="password"
                                                value={user.password}
                                                onChange={handleInputs} />
                                            <Button variant="link" className={loginStyles.eyeIcon} onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                                            </Button>
                                        </InputGroup>
                                        <Form.Text className="text-danger">{errors.password}</Form.Text>

                                          <Row className='float-end mt-1'>
                                            <Link to="/signin/password-reset" className={`link underline-float-end ${loginStyles.forgotPwdBtn}`}>Forgot password</Link>
                                          </Row>
                                
                                </Form.Group>
                                
                            <br/>
                            <br/>
                            
                            <Form.Check
                                type="checkbox"
                                id="isAgreed"
                                label="Remember me"
                                checked={user.isAgreed}
                                name="isAgreed"
                                onChange={handleInputs}
                                style={{display: 'none'}}
                            />
                            <Form.Text className="text-danger">{errors.isAgreed}</Form.Text>
                            <br/>
                            <Row>
                                <Button className={loginStyles.btnloginsetup} type="submit">Sign In</Button>
                            </Row>
                            
                            <Row className='mt-4'>
                                {/* <Col xs={4} className="line"></Col>
                                <Col xs={4} style={{color: '#6366F1'}} className="text-center">
                                Or Sign in with
                                </Col>
                                <Col xs={4} className="line"></Col> */}
                            </Row>
                            <br/> 
                                {/* <Row className='d-flex align-items-center justify-content-center mb-2'>
                                    <Col xs="auto">
                                    <Button className={`rounded-pill ${loginStyles.socialLogins}`}>
                                        <Image
                                            src='/Content/Images/Auth/google.png'
                                            alt="login_google"
                                            className=""
                                            width={30}
                                            height={30}
                                        />
                                        &nbsp;
                                    </Button>
                                    </Col>
                                    <Col xs="auto">
                                    <Button className={`rounded-pill ${loginStyles.socialLogins}`}>
                                    &nbsp;
                                    <Image
                                            src='/Content/Images/Auth/facebook.png'
                                            alt="login_google"
                                            className=""
                                            width={32}
                                            height={32}
                                        />
                                    </Button>
                                    </Col>
                                </Row> */}
                        </Form>
                      </Row>
                  </div>
                </Col>
          </Row>
      </Row>
      <SmallFooter />
    </Container>
  );
};

export default SigninUser;
