import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import payStyles from './cart.module.css';
import SmallFooter from '../../BaseComponents/SmallFooter';


const PaymentFailure = () => {
  
  
  return (
    <Container fluid className={`${payStyles.Background}`}>

            <Row className={`mainPad ${payStyles.viewportSetup}`}>   
                    <Row className={`text-center ${payStyles.contentSection}`}>
                        <label className={`${payStyles.paymentFailureMsg}`}>Transaction Failed!</label>
                    
                            <Col>
                                <Image
                                    src='/Content/Images/icons/icons8-cancel-96.png'
                                    alt="failure_icon"
                                    className={payStyles.AprooveImg}   
                                />
                            </Col>

                            <Link to='/home'>
                                <Button className={payStyles.dashboardBtn}>
                                Go to Dashboard
                                </Button>
                            </Link>

                    </Row>
            </Row>
               
            <Row className={`${payStyles.footerSec}`}>
                <SmallFooter />
            </Row>

        
    </Container>
  );
};

export default PaymentFailure;
