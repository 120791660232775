import React, { useState, useEffect } from 'react';
import { useCart } from '../../Navbar/CartContext';
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap';
import cartStyles from './cart.module.css';
import cartServices from '../../../Services/cartServices';
import { useNavigate } from 'react-router-dom';
import DeleteExperiencePopup from './DeleteExperiencePopup';


const CartPopup = ({ showCartPopup, onClose }) => {
  
  const { dispatch } = useCart();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [isSpinLoading, setIsSpinLoading] = useState(false);
  const [onDeleteshow, setOnDeleteshow] = useState(null);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleDeleteRender = (value) => {
    if (value === true) {
      setIsDeleteSuccess(value);
    } else {
      setIsDeleteSuccess(value);
    }
  };

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        setIsSpinLoading(true);
        const response = await cartServices.viewCartData();
  
        if (response.statusCode === 200) {
          if (response.data !== null && response.data.cartItems && response.data.cartItems.length > 0) {
            setCartData(response.data.cartItems);
            dispatch({ type: 'SET_CART_COUNT', payload: response.data.noOfItems });
          } else {
            setCartData([]);
          }
        } else {
          setCartData([]);
        }
  
      } catch (error) {
        console.error('API Error:', error);
      } finally {
        setIsSpinLoading(false);
        setIsDeleteSuccess(false); // Reset after fetching data
      }
    };

    if (showCartPopup || isDeleteSuccess) {
      fetchCartData();
    }
  }, [showCartPopup, isDeleteSuccess, dispatch]);

 
  const calculateSubtotal = () => {
    const subtotal = cartData.reduce((total, item) => total + parseFloat(item.amount), 0);
    return parseFloat(subtotal.toFixed(2));
  };

  const handleClose = () => {
    onClose();
  };
  
  const gotoViewCartClick = () => {
    navigate('/cart/view-cart');
  };

  const handleDeleteClick = (expFeedId) => {
    setOnDeleteshow(expFeedId);
  };


  return (
    <Modal show={showCartPopup} onHide={handleClose} dialogClassName={cartStyles.CartModalDialog} className={cartStyles.ModalCart}>

    <Modal.Body>
      {isSpinLoading ? (
        <div className={cartStyles.CartLoadContent}>
          <Spinner animation="grow" className='text-center' />
        </div>
      ) : (
        <>
          {cartData.length > 0 ? (
            <>
              {errorMessage && <div style={{ color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '1.0em' }}>{errorMessage}</div>}
              {successMessage && <div style={{ color: 'green', textAlign: 'center', fontWeight: 'bold', fontSize: '1.0em' }}>{successMessage}</div>}

              <Row className='mt-1'>
                <Row className="d-flex align-items-center justify-content-between">
                  <Col xs="auto">
                    <label className={cartStyles.subtotalLabel}>Subtotal</label>
                  </Col>
                  <Col xs="auto">
                    {/* <label className={cartStyles.quickviewbtn}> Quick view here </label> */}
                  </Col>
                </Row>

                <label className={cartStyles.SubtotalCountLabel}> $ {calculateSubtotal()} </label>
              </Row>

              <Row className={cartStyles.ModalbodyOverflow}>
                {cartData.map((item, index) => (
                  <Row key={index} className={`mt-3`}>
                    <Col lg={3} md={3} sm={3} xs={3}>
                      <img className={`roundedCircle ${cartStyles.cartImageview}`}
                        src={item.coverImage || '/Content/Images/SellerExpStudio/noImageBG.jpeg'}
                        alt="Item"
                      />
                    </Col>
                    <Col lg={7} md={7} sm={7} xs={7}>
                      <div className={cartStyles.cartItemDetails}>
                        <h4 className={cartStyles.cartCaptionlabel}>{item.caption}</h4>
                        <label className={cartStyles.subtotalspan}>$ {item.amount}</label>
                      </div>
                    </Col>

                    <Col lg={2} md={2} sm={2} xs={2} className='d-flex align-items-center justify-content-center'>
                      {/* <FaTrashAlt className={cartStyles.CartDeletebtn} onClick={() => handleDeleteClick(item.feedId)} /> */}
                      <Button className={cartStyles.CartDeletebtn} onClick={() => handleDeleteClick(item.feedId)} >
                      <img className={`roundedCircle ${cartStyles.cartDeleteImage}`}
                        src={'/Content/Images/icons/icons-delete.png'}
                        alt="Item"
                      />
                      </Button>
                      <DeleteExperiencePopup
                        onDeleteshow={onDeleteshow}
                        closeDeleteModal={() => setOnDeleteshow(null)}
                        setErrorMessage={setErrorMessage}
                        setSuccessMessage={setSuccessMessage}
                        onDeleteSuccess={handleDeleteRender}
                      />
                    </Col>
                  </Row>
                ))}
              </Row>
              <br />

              <Row>
                <Button className={`w-100 ${cartStyles.SubmitBtnStyle}`} onClick={gotoViewCartClick}>
                  Go to Cart
                </Button>
              </Row>
            </>

          ) : (
            <div>
              <p style={{ textAlign: 'center', fontWeight: '500', color: '#40637D' }}>The cart is empty. Please add workshops to continue...</p>
            </div>
          )}
        </>
      )}
    </Modal.Body>

    </Modal>

  );
};

export default CartPopup;
