import React, { useState } from 'react';
import { CartProvider } from './Navbar/CartContext';
import Header from './Navbar/Header';
import { useParams } from "react-router-dom";
import ViewCart from './Buy/Cart/ViewCart';



const ViewCartDetails = () => {
  
  const [loginStatus] = useState('loggedIn');
  const { cartStatus } = useParams();


  return (
    <CartProvider>

      <div>
        <Header loginStatus={loginStatus} />
        {cartStatus === 'view-cart' && <ViewCart />}
      </div>
      
    </CartProvider>
  );
}


export default ViewCartDetails;
