import React from 'react';
import { Row, Col } from 'react-bootstrap';
import UserProfile from '../BaseComponents/UserProfile';
import SendBird from '../SendBird';
import SellerdbActions from './SellerdbActions';
import UnpublishedWorkshops from './UnpublishedWorkshops';


const UnpublishedContent = () => {


  return (
      <main className="MainpartSetup">
        <Row className={`mainPad`}>
            <Col md={3}>
                <UserProfile />
                <SellerdbActions />
            </Col>
            <Col md={6}>
                <UnpublishedWorkshops  />
            </Col>
            <Col md={3}>
                <SendBird />
            </Col>
        </Row>
      </main>

  );
}

export default UnpublishedContent;
