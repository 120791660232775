import { useState } from 'react';
import Header from './Navbar/Header';
import { useParams } from 'react-router-dom';
import PaymentSuccess from './Buy/Cart/PaymentSuccess';
import PaymentFailure from './Buy/Cart/PaymentFailure';


const PaymentStatus = () => {
  
    const [loginStatus] = useState('loggedIn');
    const { paymentStatus } = useParams();


  return (

    <div className="sellerBankSection">

      <Header loginStatus={loginStatus} />

      {paymentStatus === 'payment_successful' && <PaymentSuccess />}
      {paymentStatus === 'payment_failure' && <PaymentFailure />}

    </div>

  );
}

export default PaymentStatus;

