import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import loginStyles from './signin.module.css';
import loginApiServices from '../../../Services/loginApiServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import SmallFooter from '../../BaseComponents/SmallFooter';


const VerifyOtp = ({ onVerificationStatus }) => {
  
  const navigate = useNavigate();
 
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [errors, setErrors] = useState({
    emailid: "",
  });


  const validatingOtpCode = async (e) => {
    e.preventDefault();
    
    const newErrors = { otpId: '',otpCode: ''};

    const queryParams = new URLSearchParams(window.location.search);
    const otpId = queryParams.get("otpId");
    const emailId = queryParams.get("emailid");

    if (!emailId) {
      newErrors.otpCode = 'Please provide the Email ID first';
      setErrors(newErrors);
      return;
    }
    
    if (!otpId) {
      newErrors.otpCode = 'OTP ID is required to verify the OTP';
      setErrors(newErrors);
      return;
    }

    if (!otpCode) {
      newErrors.otpCode = 'Please enter valid OTP';
      setErrors(newErrors);
      return;
    }

    setErrorMessage("");
    setIsLoading(true); 

    try {
      const response = await loginApiServices.validatingResetPwdOtp(otpCode,otpId,emailId);
  
      if (response.statusCode === 200) {
        if(response.data !== null) {
          setOtpCode(otpCode);

          const queryParams = new URLSearchParams();
          queryParams.append("otpCode", otpCode);
          queryParams.append("otpId", otpId);
          queryParams.append("emailid", emailId);
          
          const urlWithParams = `/signin/set-newpassword?${queryParams.toString()}`;
            // Use backticks (`) to create a template string
            navigate(urlWithParams);
            onVerificationStatus("set-newpassword");
          // window.location.href = `/signin/settingNewPassword?${queryParams.toString()}`;

        }else {
          setErrorMessage(response.message);
        }
      } else {
        setErrorMessage(response.message);
      }

    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };
  

  
  return (
    <Container fluid className={`noPadding`}>
        <Row className={`${loginStyles.signBackground}`}>
            <Row className={`mainPad d-flex align-items-center justify-content-between ${loginStyles.viewportBox}`}>
                <Col xs={12} lg={5} className={loginStyles.responsiveDisplay}>
                  <Row className='mt-5'>
                    <Image
                        src='/Content/Images/login_Image.png'
                        alt="login_banner"
                        className="loginBanner"

                    />
                  </Row>
                </Col>
                <Col xs={0} lg={2} className={loginStyles.responsiveDisplay}></Col>
                <Col xs={12} lg={5}>
                
                <div className={`${loginStyles.signinSection}`}>
                    <Row className='text-center'>
                                <Row>
                                    <Col>
                                    <Button className={`rounded-pill ${loginStyles.IconLogins}`}>
                                        <Image
                                            src='/Content/Images/icons/icons-password.png'
                                            alt="otp_icon"
                                            className=""
                                            width={30}
                                            height={30}
                                        />
                                    </Button>
                                    </Col>
                                </Row>

                    <label className={`styleHeader`}>Verify your OTP</label>
                    </Row>
                    
                    
                <Row>
                    
                    <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
                    <br/>
                    <Form method="POST" onSubmit={validatingOtpCode}>
                    <Form.Group controlId="formVerifyOtp">
                        <Form.Label className='mb-1'>Enter OTP*</Form.Label>
                        <Form.Control 
                        type="password"
                        value={otpCode}
                        onChange={(e) => setOtpCode(e.target.value)}
                        placeholder="" />
                       
                        <Form.Text className="text-danger">{errors.otpCode}</Form.Text>
                    </Form.Group>
                    <br/>
                    
                    <br/>
                        <Row>
                            <Button className={loginStyles.btnloginsetup} type="submit">Verify</Button>
                        </Row>

                    {isLoading && <LoadingOverlay />}
                    <br/>
                    <Row>
                        <Col xs={4} className="line"><hr /></Col>
                        <Col xs={4} className="text-center">
                          {/* Resend OTP */}
                          <hr />
                        </Col>
                        <Col xs={4} className="line"><hr /></Col>
                    </Row>
                    <br/>
                    
                    </Form>
                  </Row>
                </div>

                </Col>
                <br />

            </Row>
        </Row>
        
      <SmallFooter />
    </Container>
  );
};

export default VerifyOtp;
