import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Nav, Tab, Accordion, Button } from 'react-bootstrap';
import genStyles from './general.module.css';


const PrivacyPolicy = () => {

    useEffect(() => {
        const handleTabClick = () => {
            window.scrollTo(0, 0); // Scrolls to the top of the page when a tab is clicked
        };
        document.querySelectorAll('.nav-link').forEach((tab) => {
            tab.addEventListener('click', handleTabClick);
        });
        return () => {
            document.querySelectorAll('.nav-link').forEach((tab) => {
                tab.removeEventListener('click', handleTabClick);
            });
        };
    }, []);

    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1); // Go back to the previous page
    };


  return (

      <main>
        <div className={genStyles.generalBoxContainer}>
                
                <Row className='minPadding'>
                    <Link onClick={handleClose} style={{textDecoration: 'none', textAlign: 'end', cursor: 'pointer'}}>
                        <label className={genStyles.CloseLabel}>Close</label>
                    </Link>
                </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3} className={`minPadding`}>
                    <Nav variant="pills" className={`flex-column ${genStyles.MainBoxSetup}`}>
                        <Button className={genStyles.mainButtonPP}>Privacy Policy</Button>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="first" className={genStyles.leftNavClickSetup} >Introduction </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="second" className={genStyles.leftNavClickSetup} >1. <label> Collection of personal information </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="third" className={genStyles.leftNavClickSetup} >2. <label>  The purposes for which Hapifyr collects, holds, uses and discloses personal information </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="fourth" className={genStyles.leftNavClickSetup} >3. <label>  Who we disclose your personal information to </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="fifth" className={genStyles.leftNavClickSetup} >4. <label> Security of personal information  </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="sixth" className={genStyles.leftNavClickSetup} >5. <label>  Information quality and your rights of access and correction </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="seventh" className={genStyles.leftNavClickSetup} >6. <label> How to make a privacy complaint </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="eighth" className={genStyles.leftNavClickSetup} >7. <label> How to make a complaint in relation to credit reporting  </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="ninth" className={genStyles.leftNavClickSetup} >8. <label> Contact us  </label></Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='mb-2'>
                            <Nav.Link eventKey="tenth" className={genStyles.leftNavClickSetup} >9. <label>  Revisions of this Privacy Policy  </label></Nav.Link>
                        </Nav.Item>

                    </Nav>
                    </Col>

                    <Col sm={9} className={`minPadding`}>
                    <Tab.Content className={`${genStyles.MainBoxSetup}`}>
                        <h2 className={genStyles.mainHeaderTerms}>Hapifyr Privacy Policy</h2>
                        <Tab.Pane eventKey="first">
                            <div>
                                <h4 className={genStyles.ContentHeading}>Introduction</h4>
                                <p>
                                    Protecting your privacy is important to Hapifyr. This Privacy Policy explains how we collect, handle and safeguard
                                    your personal information. Hapifyr is a trading name of MingleIn Pty Ltd (ABN 79 128 714 849) (<span className={genStyles.boldText}>“Hapfiyr"</span>) provides 
                                    users with:
                                    <br />

                                        <br />
                                        (a)&nbsp; the <Link to='https://hapifyr.com/'> hapifyr.com</Link> website (<span className={genStyles.boldText}>“Website”</span>); and
                                        <br />
                                        (b)&nbsp; any of Hapifyr’s services made available on the Website.
                                        <br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (together, the <span className={genStyles.boldText}>“Services”</span>)
                                        <br />
                                        
                                        <br />
                                        This Privacy Policy applies to all the businesses within MingleIn Pty Ltd, including Hapifyr.
                                        <br /><br />

                                        By registering on the Website you acknowledge and agree that you have read and 
                                        understood this Privacy Policy and consent to the use of your personal information as 
                                        set out in this Privacy Policy. If you do not agree with this Privacy Policy, please do not
                                        continue to use the Website.
                                        
                                        <br/><br/>
                                        If you have any questions about this Privacy Policy, you may contact Hapifyr at <Link className={genStyles.LinkTagDirect}>privacy@hapfiyr.com.au</Link>.
                                        <br /><br />

                                        In this Privacy Policy, all terms defined in the Privacy Act 1988 (Cth) (<span className={genStyles.boldText}>"Privacy Act"</span>) have the same meaning when used 
                                        in this Privacy policy.
                                        <br /><br />
                                </p>


                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>1. Collection of personal information</h4>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader} >What sort of personal information do we collect and hold?</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody} >
                                    The kind of personal information we collect and hold about you depends on the 
                                    circumstances of collection and the nature of your dealings with Hapifyr.
                                    <br/><br/>
                                    The personal information we collect and hold about you and other individuals (such 
                                    as a spouse, partner or children) can include: contact details such as names, 
                                    addresses, telephone numbers, email addresses and dates of birth; workshop 
                                    purchases and sales; information contained in call recordings and workshop 
                                    recordings; credit card and bank account details; and information related to your 
                                    usage of Hapifyr products and services and your interactions with Hapifyr online, 
                                    including location data, past and present online activity and your IP Address.

                                    <br/><br/>
                                    If you deal with Hapifyr in some other capacity, we may collect your name and 
                                    contact details and any other information you choose to provide to us. We may also 
                                    collect details of the interactions you have with Hapifyr.
                                    <br/><br/>
                                    In some instances, we may collect images of you or from which you can be 
                                    identified, including if you provide us with photos or where your image is captured.
                                    If you provide Hapifyr with personal information about another person, please 
                                    make sure that you tell them about this Privacy Policy.
                                    <br/><br/>
                                    Hapifyr only collects sensitive information about you with your consent (unless we 
                                    are otherwise required or authorised by or under law to do so). Sensitive 
                                    information includes health information and information about a person’s race, 
                                    ethnic origin, political opinions, membership of political, professional or trade 
                                    associations or a trade union, religious beliefs and criminal history.
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader} >How do we collect personal information?</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody} >
                                    We collect personal information directly from you in various ways, including: over 
                                    the phone, in person, when you visit or communicate with Hapifyr through our 
                                    websites, mobile applications, social media sites and other digital platforms 
                                    (<span className={genStyles.boldText}>“Digital Platforms”</span>) including through cookies, and in writing.
                                    
                                    <br/><br/>
                                    Occasionally other people or entities might give Hapifyr personal information about 
                                    you. For example, we might be given your information by your spouse, partner, 
                                    parent or guardian. We may also collect your personal information from the 
                                    business partners we work with and the contractors who provide you with services 
                                    on our behalf, and from publicly available sources (for example, public registers and 
                                    social media).

                                    <br/><br/>
                                    Payment Information – You may be required to provide additional personal 
                                    information (including proof of identification documents) to Hapifyr’s third party 
                                    payment applications such as Stripe and Paypal. Hapifyr does not store payment 
                                    details directly and you expressly acknowledge and agree that Hapifyr is not liable 
                                    for any loss of personal information resulting from a breach of security of the Stripe 
                                    and Paypal platforms. The storage and processing your personal information by 
                                    Stripe and Paypal will be in accordance with their privacy policies and legal terms 
                                    and conditions. We strongly recommend that you check them on their websites:
                                    
                                    <br/><br/>
                                        <ul style={{padding: '0em 3em'}} className={genStyles.ParaBody}>
                                            <li><Link className={genStyles.LinkTagDirect} to='https://stripe.com/au/legal/ssa' target='_blank' rel='noopener noreferrer'> https://stripe.com/au/legal/ssa </Link></li>
                                            <li><Link className={genStyles.LinkTagDirect} to='https://www.paypal.com/au/legalhub/home?country.x=AU&locale.x=en_AU' target='_blank' rel='noopener noreferrer' > https://www.paypal.com/au/legalhub/home?country.x=AU&locale.x=en_AU </Link></li>
                                        </ul>
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className={genStyles.AccordionHeader} >Log information, cookies or web beacons</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody} >
                                    Hapifyr uses standard web browser features called “cookies” small text files stored 
                                    in a user’s browser or web beacons (electronic images that allow the website to 
                                    count visitors who have accessed a particular page and to access certain cookies) on 
                                    our Digital Platforms to help us improve your experience. We or our service 
                                    providers may also use cookies or web beacons to collect aggregate data.
                                    <br/><br/>
                                    We use the following types of cookie, as will your browser:
                                    <br/>

                                    <ul style={{padding: '0em 3em'}} className={genStyles.ParaBody}>
                                        <li><span className={genStyles.boldText}>Analytics cookies</span> that remember your computer or mobile device when you 
                                        visit our Digital Platforms. They keep track of browsing patterns and help us 
                                        to build up a profile of how you use our Digital Platforms. We use that 
                                        information for customer analytics and to serve advertisements that we think 
                                        might be of particular interest to you on our and other Digital Platforms. 
                                        Your browser may use similar cookies for similar purposes and to serve 
                                        advertisements for others.
                                        </li>
                                        <li><span className={genStyles.boldText}>Service cookies</span> that help us to make our Digital Platforms work as efficiently 
                                        as possible; remember your registration and login details; remember your 
                                        settings preferences; and to detect what device you are using and adapt how 
                                        we present our services according to the screen size of that device.
                                        </li>
                                        <li>Hapifyr also uses <span className={genStyles.boldText}>3rd party vendor remarketing tracking cookies</span> to 
                                        advertise Hapifyr services and products across the Internet. The third-party 
                                        vendors whose services Hapifyr uses, will place cookies on web browsers in 
                                        order to serve ads based on past visits to our Digital Platforms.
                                        </li>

                                    </ul>

                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>
                                
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className={genStyles.AccordionHeader} >Disable Cookies or Opt Out of online advertising</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    You may choose to disable cookies in your browser or use security software to 
                                    prevent the storage of cookies. However, if you disable cookies, we may not be able 
                                    to fulfil your request or provide you with an appropriate level of service in some 
                                    areas.
                                    <br/><br/>
                                    If you do not wish to participate in Google Adwords Remarketing, you can opt out 
                                    by visiting Google Ad’s Preferences Manager <Link className={genStyles.LinkTagDirect} to='https://adssettings.google.com' target='_blank' rel='noopener noreferrer'>https://adssettings.google.com</Link>. You 
                                    can also opt out of any third party vendor’s use of cookies by visiting 
                                    <Link className={genStyles.LinkTagDirect} to='http://optout.networkadvertising.org' target='_blank' rel='noopener noreferrer'>http://optout.networkadvertising.org</Link>.
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className={genStyles.AccordionHeader} >Google reCAPTCHA</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr uses reCAPTCHA which is a free service from Google that helps protect our 
                                    Digital Platforms from spam and abuse by blocking automated software. It does this 
                                    by collecting personal information about users to determine they’re humans and 
                                    not spam bots.

                                    <br/><br/>
                                    This collection of personal information is subject to Google’s privacy policy, for a 
                                    copy please visit: <Link className={genStyles.LinkTagDirect} to='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>https://policies.google.com/privacy</Link>.
                                    By using the reCAPTCHA service, you consent to the processing of data about you by Google in the manner 
                                    and for the purposes set out in Google’s privacy policy.
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                                <br/><br/>


                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>2. The purposes for which Hapifyr collects, holds, uses and discloses personal information </h4>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader}>To provide you with the products and services you want</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We use and disclose your personal information to provide the products and 
                                    services you have requested, to administer your Hapifyr account and provide you 
                                    with benefits that result from being part of the Hapifyr community. This includes 
                                    using your information to perform administrative functions such as receipting, 
                                    billing and handling complaints. In some circumstances we may also need to use 
                                    your information to verify your identity.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader}>To deal with enquiries and complaints</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We may need to collect your personal information and use or disclose it to answer 
                                    an enquiry you make or respond to a complaint made by you in respect of a 
                                    product or service.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className={genStyles.AccordionHeader}>To provide you with information about Hapifyr products and services which we believe may interest you</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may use your personal information to tell you about other products and 
                                    services, discounts, special offers, competitions and invitations to special events. 
                                    This includes administering your participation in any of our competitions or 
                                    promotions that you enter.
                                    <br/><br/>

                                    Where Hapifyr sends you an offer relating to the products and services of Hosts or 
                                    other organisations, we keep control over your information. We do not give, rent or 
                                    sell your information to other organisations so that they can directly market to you.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Declining product offers ('Opt Out')</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We recognise the importance of providing you with choices by giving you an easy 
                                    means to ‘Opt Out’ from receiving marketing offers. Let us know if you do not want 
                                    to receive these offers by managing your preferences at <Link className={genStyles.LinkTagDirect} to='https://ui.hapifyr.com' target='_blank' rel='noopener noreferrer'>www.hapifyr.com</Link>.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Market Research, Product Testing, Surveys and Research and Development</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may contact you from time to time in order to seek your opinion on how we 
                                    do things and how we want to do things in the future. Hapifyr may also use your 
                                    personal information, and information about the way you use our products and 
                                    services and interact with Hapifyr, for research and development purposes.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Data analytics</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may conduct data analytics on the personal information you provide and 
                                    details of your interactions with Hapifyr, to help us improve Hapifyr products and 
                                    services, our marketing and your Hapifyr experience.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Comply with our regulatory requirements</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We may need to use and disclose your personal information to comply with our 
                                    regulatory requirements.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Other Purposes</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may use and disclose your information for other purposes required or 
                                    authorised by or under law (including purposes for which you have provided your consent).
                                    <br/><br/>
                                    Hapifyr may disclose your credit information to a credit reporting body to report 
                                    defaults (in accordance with the Privacy Act).
                                    <br/><br/>
                                    If we are unable to collect personal information from or about you, we may not be 
                                    able to provide our products or services to you, respond to your enquiries, verify 
                                    your identity or engage in other dealings with or for you.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                                <br/><br/>


                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}> 3. Who we disclose your personal information to </h4>
                                <p>
                                    In conducting our business, we may sometimes need to disclose your personal 
                                    information within Hapifyr, to our business partners and contractors and to other 
                                    third parties. Where we do this, we will take reasonable steps to protect the privacy 
                                    of any information that we need to disclose to them
                                </p>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Disclosing your personal information overseas</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may need to disclose your personal information to our partners with 
                                    overseas locations in order to fulfil a workshop purchase or enquiry that you make.
                                    <br/><br/>
                                    In other limited circumstances we may disclose your personal information to third 
                                    parties overseas who provide services to us. The countries in which they are located 
                                    include India, the United States of America, and other countries (including where 
                                    third-party payment service platforms and cloud-based hosting servers are based, 
                                    which may change from time to time).
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Other disclosures of information</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We may also disclose your personal information to other third parties, where we 
                                    are required or authorised by law to do so (including where you have provided your consent).
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Sharing non personal information</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may share aggregated or summary information that does not identify you 
                                    with our business partners or other third parties.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Location based services</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    To provide location-based services through our applications, Hapifyr and its 
                                    partners and licensees may collect, monitor, store, use and share location data, 
                                    including the real-time geographic location of your devices.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                                <br/><br/>

                                
                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>4. Security of personal information </h4>
                                <p className={genStyles.ParaBody} >
                                We take reasonable steps to protect the personal information we hold from 
                                misuse, interference and loss, and from unauthorised access, modification and 
                                disclosure. This includes using security technology, procedures and employee 
                                training to help ensure that your information is protected and secure.
                                </p>
                                <br/>


                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}> 5. Information quality and your rights of access and correction </h4>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Quality and correction of personal information</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We take reasonable steps to ensure that personal information we collect, use and 
                                    disclose is accurate, complete, up to date and relevant. You can help us by letting us 
                                    know about any changes to your personal information, such as your email address and phone number.
                                    <br/><br/>
                                    If you believe that any information, we hold about you is inaccurate, please contact 
                                    us. We will take reasonable steps to correct your information, but if we don’t correct 
                                    your personal information we will give you a written explanation as to why.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Access</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Please contact us if you would like to access the personal information or credit 
                                    eligibility information we hold about you. For security purposes, when you contact 
                                    us to request access to your personal information, you will need to provide us with 
                                    enough information to enable us to verify your identity. Depending on the nature of 
                                    your request, we may ask you to complete a form and in some cases we may charge 
                                    an access fee to cover the cost of retrieving the information and supplying it to you. 
                                    We will inform you of any relevant charges at the time of your request.
                                    <br/><br/>
                                    We will generally provide you with access to your personal information (subject to 
                                    some exceptions permitted by law).
                                    <br/><br/>
                                    Following receipt of your request, we will provide you with an estimate of the access 
                                    fee, where applicable (depending on the complexity, the time required to collate the 
                                    information and the format you need the information to be made available to you 
                                    in). You can then confirm if you want to proceed. Access will be provided once 
                                    payment is received. We can usually deal with your request within 14 to 30 days.
                                    <br/><br/>
                                    To protect the confidentiality and security of your information requested, Hapifyr 
                                    may provide you with options around access to your information. 
                                    In certain circumstances we may not be able to tell you all or any of the information 
                                    we hold about you. In these circumstances we will write to you to let you know why 
                                    your request has been denied or limited.
                                    <br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                                <br/><br/>

                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>6. How to make a privacy complaint </h4>
                                <p className={genStyles.ParaBody} >
                                Please contact us if you have any concerns or complaints about the manner in which Hapifyr has handled your personal information.
                                <br/>
                                We will review your complaint and respond to you in writing. We will let you know if we requireany further 
                                information to respond to your complaint. If you are not satisfied with our response,you can contact us to discuss 
                                your concerns or take the matter to the Office of the AustralianInformation Commissioner (<span className={genStyles.boldText}>OAIC</span>).
                                <br/>
                                
                                <br/>
                                Further information about complaints to the OAIC can be found here:
                                <Link className={genStyles.LinkTagDirect} to='https://www.oaic.gov.au/privacy/privacy' target='_blank' rel='noopener noreferrer'> https://www.oaic.gov.au/privacy/privacy</Link> complaints.
                                </p>
                                <br/><br/>


                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>7. How to make a complaint in relation to credit reporting </h4>
                                <p className={genStyles.ParaBody} >
                                    Please contact us in writing if you have any concerns or complaints about our compliance withour obligations under 
                                    the credit reporting provisions of the Privacy Act or under the Credit Reporting Privacy Code.
                                    <br/>

                                    We will acknowledge your complaint within 2 business days and let you know who is responsible for handling your 
                                    complaint and how we will deal with the complaint.
                                    <br/>

                                    Hapifyr will endeavour to resolve your complaint within 30 days or any such longer period you agree to in writing.
                                    <br/>

                                    and the course of our investigation of your complaint, it may be necessary to consult with othercredit providers or credit 
                                    reporting bodies to ensure we fully investigate your complaint.
                                    <br/>

                                    We will, after investigating the complaint, make a decision about the complaint and give you written notice that:
                                    <br/>
                                </p>

                                    <ul style={{padding: '0em 3em'}} className={genStyles.ParaBody}>
                                        <li> sets out the decision;</li>
                                        <li> states that, if you are not satisfied with the decision, you may escalate the complaint to theAustralian Financial 
                                        Complaints Authority (AFCA) or contact the OAIC.If your complaint relates to how we handled your access and 
                                        correction request, you may takeyour complaint directly to AFCA or the OAIC. You are not required to let us try to 
                                        fix it first. </li>
                                    </ul>

                                <p className={genStyles.ParaBody}>
                                    The contact details for escalating complaints are:
                                    <br/><br/>

                                    <span style={{fontWeight: 'bold'}}>Australian Financial Complaints Authority</span>
                                    <br/><br/>

                                    Mail: GPO Box 3, Melbourne, VIC,
                                    <br/>

                                    3001Phone: 1800 931 P78 (free call)
                                    <br/>

                                   Email: <Link to="mailto:info@afca.org.au" className={genStyles.LinkTagDirect} arget='_blank'>info@afca.org.au</Link>
                                    <br/>

                                    Website: <Link to="https://www.afca.org.au" className={genStyles.LinkTagDirect} arget='_blank'>www.afca.org.au</Link>
                                    
                                   <br/><br/>

                                   <span style={{fontWeight: 'bold'}}>The Office of the Australian Information Commissioner</span>
                                   <br/><br/>

                                   Mail: GPO Box 2999, Canberra, ACT, 2P01
                                   <br/>

                                    Phone: 1300 363 992
                                    <br/>
                                    
                                    Email: <Link to="mailto:enquiries@oaic.gov.au" className={genStyles.LinkTagDirect} arget='_blank'>enquiries@oaic.gov.au</Link>
                                    <br/>

                                    Website: <Link to="https://www.oaic.gov.au" className={genStyles.LinkTagDirect} arget='_blank'>www.oaic.gov.au</Link>
                                    
                                </p>
                                 <br/>

                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>8. Contact us </h4>
                                <p className={genStyles.ParaBody} >
                                    If you would like more information about Hapifyr’s approach to privacy, or if you wish to discuss the information set 
                                    out in this charter, please contact us:
                                    <br/>
                                    Email: <Link to="mailto:privacy@hapifyr.com" className={genStyles.LinkTagDirect} >privacy@hapifyr.com</Link>
                                </p>  
                                <br/>


                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>9. Revisions of this Privacy Policy </h4>
                                <p className={genStyles.ParaBody} >
                                We may change our Privacy Policy from time to time. If we do so, we will place a notice on our website. 
                                Please check for updates and changes.
                                <br/>
                                Updated January 2024</p>

                            </div>
                        </Tab.Pane>


                        <Tab.Pane eventKey="second">
                            <h4 className={`mb-3 ${genStyles.ContentHeading}`}>1. Collect of personal information</h4>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader} >What sort of personal information do we collect and hold?</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody} >
                                    The kind of personal information we collect and hold about you depends on the 
                                    circumstances of collection and the nature of your dealings with Hapifyr.
                                    <br/><br/>
                                    The personal information we collect and hold about you and other individuals (such 
                                    as a spouse, partner or children) can include: contact details such as names, 
                                    addresses, telephone numbers, email addresses and dates of birth; workshop 
                                    purchases and sales; information contained in call recordings and workshop 
                                    recordings; credit card and bank account details; and information related to your 
                                    usage of Hapifyr products and services and your interactions with Hapifyr online, 
                                    including location data, past and present online activity and your IP Address.

                                    <br/><br/>
                                    If you deal with Hapifyr in some other capacity, we may collect your name and 
                                    contact details and any other information you choose to provide to us. We may also 
                                    collect details of the interactions you have with Hapifyr.
                                    <br/><br/>
                                    In some instances, we may collect images of you or from which you can be 
                                    identified, including if you provide us with photos or where your image is captured.
                                    If you provide Hapifyr with personal information about another person, please 
                                    make sure that you tell them about this Privacy Policy.
                                    <br/><br/>
                                    Hapifyr only collects sensitive information about you with your consent (unless we 
                                    are otherwise required or authorised by or under law to do so). Sensitive 
                                    information includes health information and information about a person’s race, 
                                    ethnic origin, political opinions, membership of political, professional or trade 
                                    associations or a trade union, religious beliefs and criminal history.
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader} >How do we collect personal information?</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody} >
                                    We collect personal information directly from you in various ways, including: over 
                                    the phone, in person, when you visit or communicate with Hapifyr through our 
                                    websites, mobile applications, social media sites and other digital platforms 
                                    (<span className={genStyles.boldText}>“Digital Platforms”</span>) including through cookies, and in writing.
                                    
                                    <br/><br/>
                                    Occasionally other people or entities might give Hapifyr personal information about 
                                    you. For example, we might be given your information by your spouse, partner, 
                                    parent or guardian. We may also collect your personal information from the 
                                    business partners we work with and the contractors who provide you with services 
                                    on our behalf, and from publicly available sources (for example, public registers and 
                                    social media).

                                    <br/><br/>
                                    Payment Information – You may be required to provide additional personal 
                                    information (including proof of identification documents) to Hapifyr’s third party 
                                    payment applications such as Stripe and Paypal. Hapifyr does not store payment 
                                    details directly and you expressly acknowledge and agree that Hapifyr is not liable 
                                    for any loss of personal information resulting from a breach of security of the Stripe 
                                    and Paypal platforms. The storage and processing your personal information by 
                                    Stripe and Paypal will be in accordance with their privacy policies and legal terms 
                                    and conditions. We strongly recommend that you check them on their websites:
                                    
                                    <br/><br/>
                                        <ul style={{padding: '0em 3em'}} className={genStyles.ParaBody}>
                                            <li><Link className={genStyles.LinkTagDirect} to='https://stripe.com/au/legal/ssa' target='_blank' rel='noopener noreferrer'> https://stripe.com/au/legal/ssa </Link></li>
                                            <li><Link className={genStyles.LinkTagDirect} to='https://www.paypal.com/au/legalhub/home?country.x=AU&locale.x=en_AU' target='_blank' rel='noopener noreferrer' > https://www.paypal.com/au/legalhub/home?country.x=AU&locale.x=en_AU </Link></li>
                                        </ul>
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className={genStyles.AccordionHeader} >Log information, cookies or web beacons</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody} >
                                    Hapifyr uses standard web browser features called “cookies” small text files stored 
                                    in a user’s browser or web beacons (electronic images that allow the website to 
                                    count visitors who have accessed a particular page and to access certain cookies) on 
                                    our Digital Platforms to help us improve your experience. We or our service 
                                    providers may also use cookies or web beacons to collect aggregate data.
                                    <br/><br/>
                                    We use the following types of cookie, as will your browser:
                                    <br/>

                                    <ul style={{padding: '0em 3em'}} className={genStyles.ParaBody}>
                                        <li><span className={genStyles.boldText}>Analytics cookies</span> that remember your computer or mobile device when you 
                                        visit our Digital Platforms. They keep track of browsing patterns and help us 
                                        to build up a profile of how you use our Digital Platforms. We use that 
                                        information for customer analytics and to serve advertisements that we think 
                                        might be of particular interest to you on our and other Digital Platforms. 
                                        Your browser may use similar cookies for similar purposes and to serve 
                                        advertisements for others.
                                        </li>
                                        <li><span className={genStyles.boldText}>Service cookies</span> that help us to make our Digital Platforms work as efficiently 
                                        as possible; remember your registration and login details; remember your 
                                        settings preferences; and to detect what device you are using and adapt how 
                                        we present our services according to the screen size of that device.
                                        </li>
                                        <li>Hapifyr also uses <span className={genStyles.boldText}>3rd party vendor remarketing tracking cookies</span> to 
                                        advertise Hapifyr services and products across the Internet. The third-party 
                                        vendors whose services Hapifyr uses, will place cookies on web browsers in 
                                        order to serve ads based on past visits to our Digital Platforms.
                                        </li>

                                    </ul>

                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>
                                
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className={genStyles.AccordionHeader} >Disable Cookies or Opt Out of online advertising</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    You may choose to disable cookies in your browser or use security software to 
                                    prevent the storage of cookies. However, if you disable cookies, we may not be able 
                                    to fulfil your request or provide you with an appropriate level of service in some 
                                    areas.
                                    <br/><br/>
                                    If you do not wish to participate in Google Adwords Remarketing, you can opt out 
                                    by visiting Google Ad’s Preferences Manager <Link className={genStyles.LinkTagDirect} to='https://adssettings.google.com' target='_blank' rel='noopener noreferrer'>https://adssettings.google.com</Link>. You 
                                    can also opt out of any third party vendor’s use of cookies by visiting 
                                    <Link className={genStyles.LinkTagDirect} to='http://optout.networkadvertising.org' target='_blank' rel='noopener noreferrer'>http://optout.networkadvertising.org</Link>.
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className={genStyles.AccordionHeader} >Google reCAPTCHA</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr uses reCAPTCHA which is a free service from Google that helps protect our 
                                    Digital Platforms from spam and abuse by blocking automated software. It does this 
                                    by collecting personal information about users to determine they’re humans and 
                                    not spam bots.

                                    <br/><br/>
                                    This collection of personal information is subject to Google’s privacy policy, for a 
                                    copy please visit: <Link className={genStyles.LinkTagDirect} to='https://policies.google.com/privacy' target='_blank' rel='noopener noreferrer'>https://policies.google.com/privacy</Link>.
                                    By using the reCAPTCHA service, you consent to the processing of data about you by Google in the manner 
                                    and for the purposes set out in Google’s privacy policy.
                                    <br/><br/>

                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="third">
                        <h4 className={`mb-3 ${genStyles.ContentHeading}`}>2. The purposes for which Hapifyr collects, holds, uses and discloses personal information </h4>
                        <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader}>To provide you with the products and services you want</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We use and disclose your personal information to provide the products and 
                                    services you have requested, to administer your Hapifyr account and provide you 
                                    with benefits that result from being part of the Hapifyr community. This includes 
                                    using your information to perform administrative functions such as receipting, 
                                    billing and handling complaints. In some circumstances we may also need to use 
                                    your information to verify your identity.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader}>To deal with enquiries and complaints</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We may need to collect your personal information and use or disclose it to answer 
                                    an enquiry you make or respond to a complaint made by you in respect of a 
                                    product or service.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className={genStyles.AccordionHeader}>To provide you with information about Hapifyr products and services which we believe may interest you</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may use your personal information to tell you about other products and 
                                    services, discounts, special offers, competitions and invitations to special events. 
                                    This includes administering your participation in any of our competitions or 
                                    promotions that you enter.
                                    <br/><br/>

                                    Where Hapifyr sends you an offer relating to the products and services of Hosts or 
                                    other organisations, we keep control over your information. We do not give, rent or 
                                    sell your information to other organisations so that they can directly market to you.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Declining product offers ('Opt Out')</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We recognise the importance of providing you with choices by giving you an easy 
                                    means to ‘Opt Out’ from receiving marketing offers. Let us know if you do not want 
                                    to receive these offers by managing your preferences at <Link className={genStyles.LinkTagDirect} to='https://ui.hapifyr.com' target='_blank' rel='noopener noreferrer'>www.hapifyr.com</Link>.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Market Research, Product Testing, Surveys and Research and Development</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may contact you from time to time in order to seek your opinion on how we 
                                    do things and how we want to do things in the future. Hapifyr may also use your 
                                    personal information, and information about the way you use our products and 
                                    services and interact with Hapifyr, for research and development purposes.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Data analytics</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may conduct data analytics on the personal information you provide and 
                                    details of your interactions with Hapifyr, to help us improve Hapifyr products and 
                                    services, our marketing and your Hapifyr experience.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Comply with our regulatory requirements</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We may need to use and disclose your personal information to comply with our 
                                    regulatory requirements.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Other Purposes</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may use and disclose your information for other purposes required or 
                                    authorised by or under law (including purposes for which you have provided your consent).
                                    <br/><br/>
                                    Hapifyr may disclose your credit information to a credit reporting body to report 
                                    defaults (in accordance with the Privacy Act).
                                    <br/><br/>
                                    If we are unable to collect personal information from or about you, we may not be 
                                    able to provide our products or services to you, respond to your enquiries, verify 
                                    your identity or engage in other dealings with or for you.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="fourth">
                        <h4 className={`mb-3 ${genStyles.ContentHeading}`}> 3. Who we disclose your personal information to </h4>
                        <p>
                                    In conducting our business, we may sometimes need to disclose your personal 
                                    information within Hapifyr, to our business partners and contractors and to other 
                                    third parties. Where we do this, we will take reasonable steps to protect the privacy 
                                    of any information that we need to disclose to them
                                </p>
                                <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Disclosing your personal information overseas</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may need to disclose your personal information to our partners with 
                                    overseas locations in order to fulfil a workshop purchase or enquiry that you make.
                                    <br/><br/>
                                    In other limited circumstances we may disclose your personal information to third 
                                    parties overseas who provide services to us. The countries in which they are located 
                                    include India, the United States of America, and other countries (including where 
                                    third-party payment service platforms and cloud-based hosting servers are based, 
                                    which may change from time to time).
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Other disclosures of information</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We may also disclose your personal information to other third parties, where we 
                                    are required or authorised by law to do so (including where you have provided your consent).
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Sharing non personal information</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Hapifyr may share aggregated or summary information that does not identify you 
                                    with our business partners or other third parties.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Location based services</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    To provide location-based services through our applications, Hapifyr and its 
                                    partners and licensees may collect, monitor, store, use and share location data, 
                                    including the real-time geographic location of your devices.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="fifth">
                            <h4 className={`mb-3 ${genStyles.ContentHeading}`}>4. Security of personal information </h4>
                                <p className={genStyles.ParaBody} >We take reasonable steps to protect the personal information we hold from misuse, interference and loss, and from 
                                    unauthorised access, modification and disclosure. This includes using security technology, procedures and employee 
                                    training to help ensure that your information is protectedand secure.
                                </p>
                        </Tab.Pane>

                        <Tab.Pane eventKey="sixth">
                        <h4 className={`mb-3 ${genStyles.ContentHeading}`}> 5. Information quality and your rights of access and correction </h4>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Quality and correction of personal information</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    We take reasonable steps to ensure that personal information we collect, use and 
                                    disclose is accurate, complete, up to date and relevant. You can help us by letting us 
                                    know about any changes to your personal information, such as your email address and phone number.
                                    <br/><br/>
                                    If you believe that any information, we hold about you is inaccurate, please contact 
                                    us. We will take reasonable steps to correct your information, but if we don’t correct 
                                    your personal information we will give you a written explanation as to why.
                                    <br/><br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className={genStyles.AccordionHeader}>Access</Accordion.Header>
                                    <Accordion.Body className={genStyles.AccordionBody}>
                                    Please contact us if you would like to access the personal information or credit 
                                    eligibility information we hold about you. For security purposes, when you contact 
                                    us to request access to your personal information, you will need to provide us with 
                                    enough information to enable us to verify your identity. Depending on the nature of 
                                    your request, we may ask you to complete a form and in some cases we may charge 
                                    an access fee to cover the cost of retrieving the information and supplying it to you. 
                                    We will inform you of any relevant charges at the time of your request.
                                    <br/><br/>
                                    We will generally provide you with access to your personal information (subject to 
                                    some exceptions permitted by law).
                                    <br/><br/>
                                    Following receipt of your request, we will provide you with an estimate of the access 
                                    fee, where applicable (depending on the complexity, the time required to collate the 
                                    information and the format you need the information to be made available to you 
                                    in). You can then confirm if you want to proceed. Access will be provided once 
                                    payment is received. We can usually deal with your request within 14 to 30 days.
                                    <br/><br/>
                                    To protect the confidentiality and security of your information requested, Hapifyr 
                                    may provide you with options around access to your information. 
                                    In certain circumstances we may not be able to tell you all or any of the information 
                                    we hold about you. In these circumstances we will write to you to let you know why 
                                    your request has been denied or limited.
                                    <br/>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Tab.Pane>

                        <Tab.Pane eventKey="seventh">
                        <h4 className={`mb-3 ${genStyles.ContentHeading}`}>6. How to make a privacy complaint </h4>
                            <p className={genStyles.ParaBody} >
                                Please contact us if you have any concerns or complaints about the manner in which Hapifyr has handled your personal information.
                                <br/>
                                We will review your complaint and respond to you in writing. We will let you know if we requireany further 
                                information to respond to your complaint. If you are not satisfied with our response,you can contact us to discuss 
                                your concerns or take the matter to the Office of the AustralianInformation Commissioner (<span className={genStyles.boldText}>OAIC</span>).
                                <br/>
                                <br/>
                                Further information about complaints to the OAIC can be found here:
                                <Link className={genStyles.LinkTagDirect} to='https://www.oaic.gov.au/privacy/privacy' target='_blank' rel='noopener noreferrer'> https://www.oaic.gov.au/privacy/privacy</Link> complaints.
                            </p>
                        </Tab.Pane>

                        <Tab.Pane eventKey="eighth">
                        <h4 className={`mb-3 ${genStyles.ContentHeading}`}>7. How to make a complaint in relation to credit reporting </h4>
                                <p className={genStyles.ParaBody} >
                                    Please contact us in writing if you have any concerns or complaints about our compliance withour obligations under 
                                    the credit reporting provisions of the Privacy Act or under the Credit Reporting Privacy Code.
                                    <br/>

                                    We will acknowledge your complaint within 2 business days and let you know who is responsible for handling your 
                                    complaint and how we will deal with the complaint.
                                    <br/>

                                    Hapifyr will endeavour to resolve your complaint within 30 days or any such longer period you agree to in writing.
                                    <br/>

                                    and the course of our investigation of your complaint, it may be necessary to consult with othercredit providers or credit 
                                    reporting bodies to ensure we fully investigate your complaint.
                                    <br/>

                                    We will, after investigating the complaint, make a decision about the complaint and give you written notice that:
                                    <br/>
                                </p>

                                <ul style={{padding: '0em 3em'}} className={genStyles.ParaBody}>
                                        <li> sets out the decision;</li>
                                        <li> states that, if you are not satisfied with the decision, you may escalate the complaint to theAustralian Financial 
                                        Complaints Authority (AFCA) or contact the OAIC.If your complaint relates to how we handled your access and 
                                        correction request, you may takeyour complaint directly to AFCA or the OAIC. You are not required to let us try to 
                                        fix it first. </li>
                                </ul>

                                <p className={genStyles.ParaBody}>
                                    The contact details for escalating complaints are:
                                    <br/><br/>

                                    <span style={{fontWeight: 'bold'}}>Australian Financial Complaints Authority</span>
                                    <br/><br/>

                                    Mail: GPO Box 3, Melbourne, VIC,
                                    <br/>

                                    3001Phone: 1800 931 P78 (free call)
                                    <br/>

                                    Email: <Link to="mailto:info@afca.org.au" className={genStyles.LinkTagDirect} arget='_blank'>info@afca.org.au</Link>
                                    <br/>

                                   Website: <Link to="https://www.afca.org.au" className={genStyles.LinkTagDirect} arget='_blank'>www.afca.org.au</Link>
                                   <br/><br/>

                                   <span style={{fontWeight: 'bold'}}>The Office of the Australian Information Commissioner</span>
                                   <br/><br/>

                                   Mail: GPO Box 2999, Canberra, ACT, 2P01
                                   <br/>

                                    Phone: 1300 363 992
                                    <br/>

                                    Email: <Link to="mailto:enquiries@oaic.gov.au" className={genStyles.LinkTagDirect} arget='_blank'>enquiries@oaic.gov.au</Link>
                                    <br/>

                                    Website: <Link to="https://www.oaic.gov.au" className={genStyles.LinkTagDirect} arget='_blank'>www.oaic.gov.au</Link>
                                    
                                </p>
                        </Tab.Pane>

                        <Tab.Pane eventKey="ninth">
                        <h4 className={`mb-3 ${genStyles.ContentHeading}`}>8. Contact us </h4>
                                <p className={genStyles.ParaBody} >
                                    If you would like more information about Hapifyr’s approach to privacy, or if you wish to discuss the information set 
                                    out in this charter, please contact us:
                                    <br/>
                                    Email: <Link to="mailto:privacy@hapifyr.com" className={genStyles.LinkTagDirect} >privacy@hapifyr.com</Link>
                                </p>
                        </Tab.Pane>
                        
                        <Tab.Pane eventKey="tenth">
                                <h4 className={`mb-3 ${genStyles.ContentHeading}`}>9. Revisions of this Privacy Policy </h4>
                                <p className={genStyles.ParaBody} >
                                We may change our Privacy Policy from time to time. If we do so, we will place a notice on our website. 
                                Please check for updates and changes.
                                <br/>
                                Updated January 2024</p>
                        </Tab.Pane>


                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>
      </main>


  );
}

export default PrivacyPolicy;
