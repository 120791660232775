// import PropTypes from 'prop-types';
import styles from './loading.module.css';


const LoadingOverlay = () => {
  return (
    <div className={`${styles.loadingOverlay} HomeViewportHt`}>
      <div className={styles.loadingSpinner}>
        
      </div>
    </div>
  );
};

// LoadingOverlay.propTypes = {
//   show: PropTypes.bool.isRequired,
// };

export default LoadingOverlay;
