import CryptoJS from "crypto-js";
const crypoAuthKey = "HapifyrAuthKey";

export const encryptRequest = (request) => {
  const hashKey = CryptoJS.AES.encrypt(JSON.stringify(request), crypoAuthKey).toString();
  return { data: hashKey };
};

export const decryptResponse = (hashedResponse) => {
  const bytes = CryptoJS.AES.decrypt(hashedResponse, crypoAuthKey);
  const response = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(response);
};
