import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import CRPDashboard from './CRP/CRPDashboard';
import Header from './Navbar/Header';


const CRP = () => {
  
    const [ loginStatus ] = useState('loggedIn');
    const { CRPStatus } = useParams();

  return (
    <div className="CRPDiv">

        <Header loginStatus={loginStatus} />

        <CRPDashboard status={CRPStatus} />

    </div>
  );
}

export default CRP;
