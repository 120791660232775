import { useState, useEffect } from 'react';
import signStyles from './signup.module.css';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import loginApiServices from '../../../Services/loginApiServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import SmallFooter from '../../BaseComponents/SmallFooter';
import { FaCheckCircle } from "react-icons/fa";


const SignupInterests = ({ userData }) => {

  // console.log("Interets userData  ",userData);
  const navigate = useNavigate();
  const referralId = userData.referralId;
  const [userStatus, setUserStatus] = useState('signingUp');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');  
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [allInterests, setAllInterests] = useState([]);
  const [interestDataFetched, setInterestDataFetched] = useState(false);


  useEffect(() => {
    
    if (userStatus === 'signingUp' && !interestDataFetched) {
      async function fetchUserInterests() {
        try {
          
          setIsLoading(true); // Start loading
          const response = await loginApiServices.getAllUserInterests();
          //console.log("Signup Response:", response);
  
          if (response.statusCode === 200) {
           // setSelectedInterests(response.data);
           setAllInterests(prevSelectedInterests => response.data);
           setInterestDataFetched(true); // Set dataFetched to true to prevent further calls
            // You may also set userStatus to something else if needed.
          } else {
            setErrorMessage(response.message);
            setUserStatus('signupError'); // Set the userStatus to handle errors
          }
        } catch (error) {
          setUserStatus('signupError');
          console.error("Signup Error:", error);
          // Handle the error, maybe show an error message
        } finally {
          setIsLoading(false); // Stop loading
        }
      }
  
      // Call the function to make the API request
      fetchUserInterests();
    }
  }, [userStatus, interestDataFetched]);


  const handleInterestClick = (interest) => {
    let tempArr = Array.from(selectedInterests);
    let filteredInterests = [];
    // Check if the interest is already selected
    if (selectedInterests.includes(interest)) {
      // If it is, deselect it
      filteredInterests = tempArr.filter((item) => item !== interest);
    } else {
      // If it's not, select it
      filteredInterests= [...tempArr, interest];
    }
    // Update userData with the updated selectedInterests
    setSelectedInterests((prevUserData) => ({
      ...prevUserData,
      selectedInterests: filteredInterests
    }));
    setSelectedInterests(filteredInterests);

  };


  const completeUserSignUp = async (e) => {
    setErrorMessage('');
    e.preventDefault();
    
    if (selectedInterests.length === 0) {
     setErrorMessage("Please select at least one Interest to continue.");
     return;
    }

    setIsLoading(true);

     // Set userData with all the necessary data
    const userDataWithInterests = {
      ...userData,
      methodType: "email",
      interests: selectedInterests,
    };
    // console.log("Final Reqqq::", userDataWithInterests);
    try {
      const response = await loginApiServices.completefinalSignup(userDataWithInterests);
      //console.log("API Response:", response);
      
      if (response.statusCode === 200) {

        //console.log("Data to be passed :", response.data);
       // navigate('/home', { data: response.data }); // Navigate to the home page
       // navigate('/home', { data: response.data });
       navigate('/home', { state: { data: response.data } });
       window.location.href = `/home`;

      } else {
        setUserStatus('signingInterests');
        setErrorMessage(response.message);
      }

    } catch (error) {
      console.error("API Error:", error);
      if(error.message !== null && error.message !== undefined && error.message !== ""){
        setErrorMessage(error.message);
      }else {
        setErrorMessage("Internal Error, Please contact support team.");
      }
    } finally {
      setIsLoading(false); // Stop loading
    }

  };


  return (
    
    <Container fluid className='noPadding'>
        <Row className={`${signStyles.signBackground}`}>
            <Row className={`mainPad d-flex align-items-center justify-content-between ${signStyles.viewportBoxInterests}`}>
                <Col xs={0} lg={5} className={signStyles.responsiveDisplay}>
                  <Row className={signStyles.signleftContent} >
                    <label className={`${signStyles.signWelcomeline}`}>share your Interests</label>
                    <h1 className={signStyles.signBigContentline} >Share what makes you Happy!</h1>
                    <p className={signStyles.signParaContent}>Select your interests, goals and preferences to 
                      help us match you with the perfect Hapifyr Workshops.</p>
                  </Row>  
                </Col>
                <Col xs={0} lg={2} className={signStyles.responsiveDisplay}></Col>
                <Col xs={12} lg={5}>
                
                <div className={`${signStyles.signupSection}`}>

                    <div className={signStyles.refferalDiv}>
                      <label className={`${signStyles.BaseNameTag}`}>Your Interests*</label>
                      {referralId && (
                        <Button className={signStyles.refferalIdShow}>
                          {referralId}
                        </Button>
                      )}
                    </div>

                    <div style={{overflow: 'auto', scrollbarWidth: 'none'}}>
                      <label className="text-danger" align="center">{errorMessage}</label>
                    </div>

                    <Row className={`d-flex align-items-center justify-content-between`}>
                    
                        <Col xs="auto">
                            <Row className={`d-flex justify-content-between  ${signStyles.progressframe}`} >
                            <Col className={signStyles.topline}>
                                <div className={signStyles.rectangle} />
                            </Col>
                            <Col>
                            <FaCheckCircle className={signStyles.circleCompleted} />
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >Basic details</p>
                            </Row>
                        </Col>

                        <Col xs="auto">
                            <Row className={signStyles.progressframe}>
                            <Col className={signStyles.topline}>
                                <div className={signStyles.strline} />
                            </Col>
                            <Col>
                            <FaCheckCircle className={signStyles.circleCompleted} />
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >Profile</p>
                            </Row>
                        </Col>

                        <Col xs="auto">
                            <Row className={signStyles.progressframe}>
                            <Col className={signStyles.topline}>
                                <div className={signStyles.strline} />
                            </Col>
                            <Col>
                            <div className={signStyles.currentRunIcon}>
                                <div className={signStyles.blueBot}></div>
                              </div>
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >Interests</p>
                            </Row>
                        </Col>
                        
                    </Row>

                    <Row> 
                            
                    {isLoading ? (
                        <LoadingOverlay />
                      ) : (
                        <Row className={`mt-3 mb-3 ${signStyles.interestItemOverflow}`}>
                            {allInterests.map((interest, index) => (
                              <div key={index} className={`${signStyles.interestItem}  ${selectedInterests.includes(interest.interestName) ? signStyles.selected : ''}`}
                                onClick={() => handleInterestClick(interest.interestName)}>
                                  {/* <OverlayTrigger
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip id={`tooltip-top`}>
                                          {interest.interestName}
                                        </Tooltip>
                                      }
                                  > */}
                                    <div key={interest.interestId} >
                                      <label className={signStyles.interestItemName} >{interest.interestName}</label>
                                    </div>
                                  {/* </OverlayTrigger> */}
                              </div>
                            ))}
                        </Row>
                      )}

                        <Row className="mt-3">
                            <Col lg={7}></Col>
                            <Col lg={5} className="text-end">
                                <Button className={signStyles.SubmitBtn} type="submit" onClick={completeUserSignUp} >Complete Profile</Button>
                            </Col>
                        </Row>   
                    
                    </Row>
                </div>
                </Col>
            </Row>

            <Row className={signStyles.smfooters}>
              <SmallFooter />
            </Row>
        </Row>
        
    </Container>
    
  );
};

export default SignupInterests;
