import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Image, Toast } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import styles from './Republish.module.css';
import { FaAngleDown } from "react-icons/fa";
import experienceServices from "../../../Services/experienceServices";
import Loader from "../../BaseComponents/Loader";
import AuthContext from "../../Context/AuthContext";

const RepublishWorkshop = ({ type, id, republishForm }) => {
    
    const draftId = id;
    // console.log("republishForm Data::", republishForm);
    const navigate = useNavigate();
    const { isLoading, setIsLoading } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [isBankDataAvailable, setIsBankDataAvailable] = useState(false);
    const [isPublishChecked, setIsPublishChecked] = useState(false);
    const [showDescription, setShowDescription] = useState(true);
    const [showMaterial, setShowMaterial] = useState(true);
    const [experienceForm, setExperienceForm] = useState(null);
    const [userProfileData, setUserProfileData] = useState(null);

    const toggleShowDescription = () => setShowDescription(!showDescription);
    const toggleShowMaterial = () => setShowMaterial(!showMaterial);

    useEffect(() => {
        const fetchWorkshopDetails = async (id) => {
            try {
                setIsLoading(true);
                const response = await experienceServices.fetchUserWorkshop(id);
                if (response && response.statusCode === 200) {
                    if (response.data !== null) {
                        
                        // console.log("response::", response.data);
                        if (republishForm && typeof republishForm === 'object' && Object.keys(republishForm).length > 0) {
                            if(republishForm.caption){
                                response.data.caption = republishForm.caption;
                            }
                            if(republishForm.desc) {
                                response.data.desc = republishForm.desc;
                            } 
                            if(republishForm.addInfo) {
                                response.data.addInfo = republishForm.addInfo;
                            } 
                            if(republishForm.currency) {
                                response.data.currency = republishForm.currency;
                            }
                            if(republishForm.price) {
                                response.data.price = republishForm.price;
                            }
                            if(republishForm.duration) {
                                response.data.duration = republishForm.duration;
                            }
                            if (republishForm.coverImg && republishForm.coverImg instanceof File) {
                                const thumbnail = URL.createObjectURL(republishForm.coverImg);
                                response.data.coverImg = thumbnail;
                            }
                        }
                        setExperienceForm(response.data);
                        validateBankDetails();

                    } else {
                        setErrorMessage(response.message || "Internal Error, Please contact support.");
                    }
                } else {
                    setErrorMessage(response.message || "Internal Error, Please contact support.");
                }
            } catch (error) {
                console.error("API Error:", error);
                setErrorMessage("API Error occurred, Please contact support.");
            } finally {
                setIsLoading(false);
            }
        }

        const validateBankDetails = async () => {
            try {
              setIsLoading(true);
              const response = await experienceServices.validateSellerBankDetails();
              if (response.statusCode === 200) {
                setIsBankDataAvailable(response.data?.[0]?.available || false);
              } else {
                setIsBankDataAvailable(false);
                setErrorMessage(response.message || "Internal Error, Please contact support.");
              }
            } catch (error) {
              console.error("API Error:", error);
              setIsBankDataAvailable(false);
              setErrorMessage("API Error occurred.");
            } finally {
              setIsLoading(false);
            }
          };


        if (draftId) {
            fetchWorkshopDetails(draftId);
        }

        const profileData = localStorage.getItem('userProfileData');
        if (profileData) {
            setUserProfileData(JSON.parse(profileData));
        }
    }, [draftId, republishForm, setIsLoading]);


    const republishWorkshop = async () => {
        setErrorMessage("");
        if (!isPublishChecked) {
            setErrorMessage('Please agree to the Terms & Conditions before you publish.');
            return;
        }
        if (!republishForm || typeof republishForm !== 'object' || Object.keys(republishForm).length === 0) {
            setErrorMessage('An internal error occurred during republishing. Please try again later, or restart the process.');
            return;
        }

        try {
            setIsLoading(true);
            const response = await experienceServices.saveUpdatedWorkshop(republishForm);
                if (response && response.statusCode === 200) {
                    // Handle successful publication
                    navigate(`/host/workshop-success/${draftId}`);
                } else {
                    setErrorMessage(response.message || "Republish failed, Please try after some time.");
                }
        } catch (error) {
            console.error("republishWorkshop Error:", error);
            setErrorMessage('Internal error, workshop creation failed.');
        } finally {
            setIsLoading(false);
        }
    };

    const publishUnpublishedWorkshop = async () => {
        setErrorMessage("");

        if (!draftId) {
            setErrorMessage('Please agree to the Terms & Conditions before you publish.');
            return;
        }
        if (!isPublishChecked) {
            setErrorMessage('Please agree to the Terms & Conditions before you publish.');
            return;
        }
        if (!isBankDataAvailable) {
            setErrorMessage("Sorry, you can't publish the workshop without a Stripe connect. Please complete your Stripe setup in the profile section first.");
            return;
        }

        try {
          setIsLoading(true);
          const response = await experienceServices.publishWorkshop(draftId);
          // console.log("publishUnpublishedWorkshop Response:", response);
  
          if (response.statusCode === 200) {
            if(response.data !== null && response.data[0] !== null && response.data[0].published){
                navigate(`/host/workshop-success/${draftId}`);
            } else {
              setErrorMessage(response.message || "Publish failed, Please try after some time.");
            }
          } else {
            setErrorMessage(response.message || "Publish failed, Please try after some time.");
          }
    
        } catch (error) {
          console.error("publishUnpublishedWorkshop Error:", error);
          setErrorMessage('Internal error, workshop creation failed.');
        } finally {
          setIsLoading(false);
        }
    };


    const backtoPrevious = () => {
        navigate(-1);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <Container fluid className="MainpartSetup HomeViewportHt">
            <Row className="mt-2 mb-2 mainPad">
                <Col lg={8}>
                    <Row className={styles.republishBackdrop}>
                        {errorMessage && <div className={styles.errorMessage} style={{ textAlign: "center" }}>{errorMessage}</div>}

                        {experienceForm ? (
                            <>
                                <Row>
                                    <div className="d-flex align-items-center">
                                        {userProfileData && userProfileData.imageUrl ? (
                                            <>
                                                <Image
                                                    src={userProfileData.imageUrl || '/Content/Images/empty_user.jpg'}
                                                    alt="Profile"
                                                    roundedCircle
                                                    className={`roundedUser ${styles.previewProfileImg}`}
                                                />
                                                <div className={styles.flexStyleforUserdiv}>
                                                    <h3 className={styles.productSellername}>{userProfileData.name}</h3>
                                                    <label className={styles.productSellerdesc}>{userProfileData.bio}</label>
                                                </div>
                                            </>
                                        ) : (
                                            <div className={styles.productSellername}>No user data available</div>
                                        )}
                                    </div>
                                </Row>

                                <Row className="mt-3 mb-1">
                                    <h3 className={styles.productCaption}>{experienceForm.caption}</h3>
                                </Row>

                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className={styles.workshopContainerdiv}>
                                        <img
                                            src={experienceForm.coverImg || "/Content/Images/SellerExpStudio/noImageBG.jpeg"}
                                            className={styles.workshopThumbnailImage}
                                            alt="thumbnail"
                                        />
                                    </div>
                                </Col>

                                <Row className="mt-4">
                                    <Col xs={4}>
                                        <div className={`overlap-group ${styles.productDetailsBox}`}>
                                            <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                                                <label className={styles.ProductBoxLabel}>Duration</label>
                                                <div className={styles.IconSetup}>
                                                    <Image
                                                        src='/Content/Images/icons/icons-sundial.png'
                                                        alt="duration_icon"
                                                        className={styles.IconSetImage}
                                                    />
                                                </div>
                                            </div>
                                            <label className={styles.ProductBoxlabelValue}>{experienceForm.duration} Mins</label>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className={`overlap-group ${styles.productDetailsBox}`}>
                                            <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                                                <label className={styles.ProductBoxLabel}>Price</label>
                                                <div className={styles.IconSetup}>
                                                    <Image
                                                        src='/Content/Images/icons/icons-dollar-coin.png'
                                                        alt="price_icon"
                                                        className={styles.IconSetImage}
                                                    />
                                                </div>
                                            </div>
                                            <label className={styles.ProductBoxlabelValue}>{experienceForm.currency} {experienceForm.price}</label>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <div className={`overlap-group ${styles.productDetailsBox}`}>
                                            <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                                                <label className={styles.ProductBoxLabel}>Rating</label>
                                                <div className={styles.IconSetup}>
                                                    <Image
                                                        src='/Content/Images/icons/icons-rating.png'
                                                        alt="rating_icon"
                                                        className={styles.IconSetImage}
                                                    />
                                                </div>
                                            </div>
                                            <label className={styles.ProductBoxlabelValue}>{experienceForm.ratings}</label>
                                        </div>
                                    </Col>
                                </Row>

                                <br /><br />
                                <Row className="mt-4 mb-4">
                                    <Col>
                                        <div className={styles.toggleDropdowndiv}>
                                            <label className={styles.accordionHeader}>Description</label>
                                            <Button onClick={toggleShowDescription} className={styles.toggleDropdownClick}>
                                                <FaAngleDown />
                                            </Button>
                                        </div>
                                        <Toast show={showDescription} onClose={toggleShowDescription} className={styles.toggleToastStyles}>
                                            <label className={styles.accordionDescription}>{experienceForm.desc}</label>
                                        </Toast>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <div className={styles.toggleDropdowndiv}>
                                            <label className={styles.accordionHeader}>Extra Material</label>
                                            <Button onClick={toggleShowMaterial} className={styles.toggleDropdownClick}>
                                                <FaAngleDown />
                                            </Button>
                                        </div>
                                        <Toast show={showMaterial} onClose={toggleShowMaterial} className={styles.toggleToastStyles}>
                                            <label className={styles.accordionDescription}>{experienceForm.addInfo}</label>
                                        </Toast>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <Row className="mt-2 mb-2">
                                <h3 className={styles.errorMessage} style={{textAlign: 'center'}}>Workshop data is not available.</h3>
                            </Row>
                        )}
                    </Row>
                </Col>
                <Col lg={4}>
                    <div className="mb-2 mt-2">
                        <Col xs="auto">
                            <div className={styles.PaymentTermsdiv}>
                                <label className={styles.PaymentTermsLabel}>
                                    <input
                                        type="checkbox"
                                        name="PublishCheck"
                                        id="inline-checkbox-1"
                                        checked={isPublishChecked}
                                        onChange={(e) => setIsPublishChecked(e.target.checked)}
                                        className={styles.CustomCheckbox}
                                    />
                                    <label>
                                        By publishing my workshop I agree to the
                                        &nbsp; 
                                        <Link className={styles.LinkTagDirect}
                                            to="/terms-and-conditions" target='_blank' rel='noopener noreferrer' >
                                           Terms and Conditions
                                        </Link>
                                        &nbsp;
                                        including payment terms available at Hapifyr.
                                    </label>
                                </label>
                            </div>
                        </Col>
                    </div>
                    <Row className={styles.PaddingSetupRow}>
                        <Row className="mt-2 justify-content-end">
                            <Col className="d-flex justify-content-end g-4">
                                <Button variant="outline-secondary" className={styles.cancelButton} onClick={backtoPrevious}> &nbsp; Edit &nbsp; </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                {type === 'publish-workshop' ? (
                                    <Button variant="primary" className={styles.successProceedButton} onClick={publishUnpublishedWorkshop} disabled={isLoading}>
                                        Publish
                                    </Button>
                                ) : type === 'republish-workshop' ? (
                                    <Button variant="primary" className={styles.successProceedButton} onClick={republishWorkshop} disabled={isLoading}>
                                       Republish
                                    </Button>
                                ) : null}
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>

        <br /><br /><br />
        </Container>
    );
};


export default RepublishWorkshop;
