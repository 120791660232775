import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Navbar/Header';
import ViewEducationPage from './SellerDashboard/ViewEducationPage';


const EducationDetails = () => {

  const [loginStatus] = useState('loggedIn');
  const { educationId } = useParams();


  return (

    <div className="EducationDetailsdiv">
      
      <Header loginStatus={loginStatus} />
      
      <ViewEducationPage educationId={educationId} />
       
    </div>

  );
}

export default EducationDetails;
