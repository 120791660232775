import { useRef, useState } from 'react';
import { Container, Row, Col, Badge, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authStyles from './auth.module.css';
import StarRating from '../BaseComponents/StarRating';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";


const AuthContent = () => {
  
  const feedData = [
    { name: 'Loki Bright',content: '/Content/Images/Auth/mini_banner_1.png', date: 'OCT 11, 23', format: 'Pre-recorded', price: 20, rating: 4 },
    { name: 'Loki Lite',content: '/Content/Images/Auth/mini_banner_2.png', date: ' Date Analyst', format: 'Pre-recorded', price: 30, rating: 5 },
    { name: 'Another Name',content: '/Content/Images/Auth/mini_banner_3.png', date: 'Product Analyst', format: 'Pre-recorded', price: 25, rating: 3 },
    { name: 'Loki Bright',content: '/Content/Images/Auth/mini_banner_1.png', date: 'OCT 11, 23', format: 'Pre-recorded', price: 20, rating: 4 },
    { name: 'Anot ping',content: '/Content/Images/Auth/mini_banner_2.png', date: 'Product Analyst', format: 'Pre-recorded', price: 25, rating: 5 },
    { name: 'Loki Bright',content: '/Content/Images/Auth/mini_banner_3.png', date: 'OCT 11, 23', format: 'Pre-recorded', price: 50, rating: 4 },

  ];

  const items = [
    { name: 'Loki Bright',content: '/Content/Images/Auth/Comm_Image1.png', rating: 4 },
    { name: 'Loki Lite',content: '/Content/Images/Auth/Comm_Image2.png', rating: 5 },
    { name: 'Another Name',content: '/Content/Images/Auth/Comm_Image3.png', rating: 3 },
    { name: 'Loki Lite',content: '/Content/Images/Auth/Comm_Image1.png', rating: 5 },
    { name: 'Another Name',content: '/Content/Images/Auth/Comm_Image2.png', rating: 3 },
    { name: 'Loki Lite',content: '/Content/Images/Auth/Comm_Image3.png', rating: 5 },
    
  ];

  const carouselRef = useRef(null);

  const handleNextButtonClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slideNext();
    }
  };

  const handlePrevButtonClick = () => {
    if (carouselRef.current) {
      carouselRef.current.slidePrev();
    }
  };

  const responsive = {
    0: { items: 1 },
    768: { items: 2 },
    1024: { items: 3 }
  };

  const [thirdIndex, setThirdIndex] = useState(0);
  const handleThirdSelect = (selectedIndex, e) => {
    setThirdIndex(selectedIndex);
  };


  return (
    
    <Container fluid className='noPadding '>
      <Row className={`text-center ${authStyles.latestExperiencesDiv}`}>
              
              <Row className={authStyles.latestExperiencesRow}>
                <div>
                  <h2 className={`Biglabel`}>Our Latest Workshops</h2>
                </div>
              </Row>

            <Row className={`text-start d-flex align-items-center justify-content-between`}>
              <AliceCarousel
                  ref={carouselRef}
                  mouseTracking
                  items={feedData.map((feed, index) => (
                    <div key={index} className={authStyles.MiniBannerDiv}>
                      <Row className="d-flex align-items-center justify-content-between">
                        <Col xs="auto">
                          <Row className="d-flex align-items-center justify-content-center">
                            <Col xs="auto">
                              <img
                                src="/Content/Images/Auth/Link1.png"
                                alt="min_banner1"
                                width={40}
                                height={40}
                              />
                            </Col>
                            <Col xs="auto" className="text-start">
                              <label className={authStyles.MiniNameTag}>{feed.name}</label>
                              <p className={authStyles.MinidescTag}>{feed.date}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs="auto">
                          <Link to="/signup/mandatory-details" style={{ textDecoration: 'none' }} className="d-flex align-items-center justify-content-center">
                            <Button className={authStyles.authfollowBtn}>Follow</Button>
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <img
                          src={feed.content}
                          alt="min_banner1"
                          className={authStyles.bordersetup}
                        />
                      </Row>
                      <Row className="d-flex align-items-center justify-content-between">
                        <Col xs="auto">
                          <div className={`label d-flex align-items-center justify-content-between ${authStyles.PreRecordBg}`}>
                            <div className="name">Format: Pre-recorded</div>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Badge className={authStyles.PriceBg}>${feed.price}</Badge>
                          </div>
                        </Col>
                        <Col xs="auto" className="d-flex align-items-baseline justify-content-end" style={{ pointerEvents: 'none' }}>
                          <StarRating value={feed.rating} />
                        </Col>
                      </Row>
                    </div>
                  ))}
                  
                  responsive={responsive}
                  disableDotsControls
                  buttonsDisabled={false}
                  autoPlay
                  autoPlayInterval={5000} // Set to a more reasonable interval (e.g., 5000ms or 5 seconds)
                  infinite
                  renderPrevButton={() => (
                    <Button
                      className={`btn-prev ${authStyles.customLeftArrow}`}
                      onClick={handlePrevButtonClick}
                    >
                      <FaChevronLeft />
                    </Button>
                  )}
                  renderNextButton={() => (
                    <Button
                      className={`btn-next ${authStyles.customRightArrow}`}
                      onClick={handleNextButtonClick}
                    >
                      <FaChevronRight/>
                    </Button>
                  )}
                />

            </Row>    
      </Row>


      <Row className={`text-center ${authStyles.communitiesRow}`}>
              <Row>
                <div>
                  <h2 className={`Biglabel`}>New creations from our Community</h2>
                  <p className={`ContentLabel`}>
                    At Hapifyr we celebrate our achievements with a 'boast', to help ignite creativity in each other!
                  </p>
                </div>
              </Row>

              <Row className='text-start'>
                <AliceCarousel
                  ref={carouselRef}
                  // mouseDragEnabled
                  mouseTracking
                  items={items.map((feed, index) => (
                    <div key={index} className={`${authStyles.community_BannerSection}`}>
                      <img
                        src={feed.content}
                        alt={`community_BannerImgs${index}`}
                        className={`position-relative ${authStyles.community_imgs}`}
                      />
                    </div>
                  ))}

                  responsive={responsive}
                  disableDotsControls
                  buttonsDisabled={false}
                  autoPlay
                  autoPlayInterval={5000} // Set to a more reasonable interval (e.g., 5000ms or 5 seconds)
                  infinite
                  renderPrevButton={() => (
                    <Button
                      className={`btn-prev ${authStyles.customLeftArrow}`}
                      onClick={handlePrevButtonClick}
                    >
                      <FaChevronLeft />
                    </Button>
                  )}
                  renderNextButton={() => (
                    <Button
                      className={`btn-next ${authStyles.customRightArrow}`}
                      onClick={handleNextButtonClick}
                    >
                      <FaChevronRight/>
                    </Button>
                  )}

                />
              </Row>

              <Row>
                  <Link to="/signup/mandatory-details" className='d-flex align-items-center justify-content-center'>
                      <Button className={authStyles.SellerAuthBtns}>Register to find out more</Button>
                  </Link>        
              </Row>

      </Row>


    <Carousel 
    activeIndex={thirdIndex}
    onSelect={handleThirdSelect}
    interval={5000}
    // controls
    prevIcon={<Button className={authStyles.customLeftArrow}><FaChevronLeft /></Button>}
    nextIcon={<Button className={authStyles.customRightArrow}><FaChevronRight /></Button>}
    >
     
      <Carousel.Item>
        <Row className={`d-flex align-items-center justify-content-start`}>
          <Col lg={6} className={`${authStyles.becomeHostLeft}`}>
            <h2 className={`Biglabel mb-4`}>How to become a Buyer</h2>
            <img src="/Content/Images/Auth/footer_banner.png" alt="experience_user" />
          </Col>

          <Col lg={6} className={`${authStyles.becomeHostRight}`}>
            <Row className="d-flex">
              <Col xs={1}>
                <Badge className={authStyles.BadgeSellerAuth}>1</Badge>
              </Col>
              <Col xs={10}>
                <label className={`${authStyles.miniHeaderstyles}`}>Sign Up </label>
                <p className={`${authStyles.miniParalines}`} >Join the Hapifyr community and start your journey to Ignite Happiness. It's free!</p>
              </Col>
            </Row>
            <br />

            <Row className="d-flex">
              <Col xs={1}>
                <Badge className={authStyles.BadgeSellerAuth}>2</Badge>
              </Col>
              <Col xs={10}>
                <label className={`${authStyles.miniHeaderstyles}`}>Create a Workshop </label>
                <p className={`${authStyles.miniParalines}`}>We support you step-by-step to create your first Hapifyr workshop.</p>
              </Col>
            </Row>
            <br />

            <Row className="d-flex">
              <Col xs={1}>
                <Badge className={authStyles.BadgeSellerAuth}>3</Badge>
              </Col>
              <Col xs={10}>
                <label className={`${authStyles.miniHeaderstyles}`}>Ignite Happiness </label>
                <p className={`${authStyles.miniParalines}`}>Publish your workshop in the Hapifyr marketplace, share it with your network, and Ignite Happiness in the world! </p>
              </Col>
            </Row>

            <Row className="d-flex ResponsiveCenteredAlignedButtons">
              <Col xs="auto">
                <Link to="/signup/mandatory-details">
                  <Button className={authStyles.SellerAuthBtns}>Sign Up Now</Button>
                </Link>
              </Col>
              <Col xs="auto"></Col>
            </Row>
          </Col>
        </Row>
      </Carousel.Item>
      <Carousel.Item>
        <Row className={`d-flex align-items-center justify-content-start`}>
          <Col lg={6} className={`${authStyles.becomeHostLeft}`}>
            <h2 className={`Biglabel mb-4`}>How to become a Host</h2>
            <img src="/Content/Images/Auth/footer_banner.png" alt="experience_user" />
          </Col>

          <Col lg={6} className={`${authStyles.becomeHostRight}`}>
            <Row className="d-flex">
              <Col xs={1}>
                <Badge className={authStyles.BadgeSellerAuth}>1</Badge>
              </Col>
              <Col xs={10}>
                <label className={`${authStyles.miniHeaderstyles}`}>Sign Up </label>
                <p className={`${authStyles.miniParalines}`} >Join the Hapifyr community and start your journey to Ignite Happiness. It's free!</p>
              </Col>
            </Row>
            <br />

            <Row className="d-flex">
              <Col xs={1}>
                <Badge className={authStyles.BadgeSellerAuth}>2</Badge>
              </Col>
              <Col xs={10}>
                <label className={`${authStyles.miniHeaderstyles}`}>Create a Workshop </label>
                <p className={`${authStyles.miniParalines}`}>We support you step-by-step to create your first Hapifyr workshop.</p>
              </Col>
            </Row>
            <br />

            <Row className="d-flex">
              <Col xs={1}>
                <Badge className={authStyles.BadgeSellerAuth}>3</Badge>
              </Col>
              <Col xs={10}>
                <label className={`${authStyles.miniHeaderstyles}`}>Ignite Happiness </label>
                <p className={`${authStyles.miniParalines}`}>Publish your workshop in the Hapifyr marketplace, share it with your network, and Ignite Happiness in the world! </p>
              </Col>
            </Row>

            <Row className="d-flex ResponsiveCenteredAlignedButtons">
              <Col xs="auto">
                <Link to="/signup/mandatory-details">
                  <Button className={authStyles.SellerAuthBtns}>Sign Up Now</Button>
                </Link>
              </Col>
              <Col xs="auto"></Col>
            </Row>
          </Col>
        </Row>
      </Carousel.Item>

    </Carousel>

    </Container>
    
  );
};


export default AuthContent;
