import { useState, useEffect } from 'react';
import signStyles from './signup.module.css';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, InputGroup, Overlay, Tooltip } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import loginApiServices from '../../../Services/loginApiServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import SmallFooter from '../../BaseComponents/SmallFooter';
import OtpVerification from './OtpVerification';
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { debounce } from 'lodash';
// import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears } from "date-fns";



const MandatoryDetails = ({ referralId, updateUserData }) => {

    const navigate = useNavigate();
    // console.log("Idd:: ", referralId);

    // Calculate the maximum and minimum selectable dates
    const currentDate = new Date();
    const minDate = new Date(currentDate.getFullYear() - 100, 0, 1); // For example, minDate is 100 years ago
    const maxDate = new Date(currentDate.getFullYear() - 13, currentDate.getMonth(), currentDate.getDate()); // 13 years ago from today

    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordVisible((prev) => !prev);
    };
    
    const [flag, setFlag] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [otpId, setOtpId] = useState("");
    const [password, setPassword] = useState("");
    const [dob, setDob] = useState(new Date(2000, 0, 1)); //// Set the default date (1/1/2000)
    const [sessionId, setSessionId] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formErrors, setFormErrors] = useState({
        formName: "",
        formUsername: "",
        formEmailId: "",
        formPassword: "",
        formDob: "",
    });

    const updateSessionId = (newSessionId) => {
        if(newSessionId !== "" && newSessionId.length > 0){
        setSessionId(newSessionId);
        setIsEmailValid(false);
        setOtpId(null);
        }
      };

    const handleInputs = (e) => {
        const { name, value } = e.target;
        if (value.trim() !== '') {
          setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        }
        setErrorMessage("");
      };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const validateUsername = (username) => {
    return /^(?!.*\s)(?!.*[\W_])(?!.*(admin|root|system))(?!.*(?:profanity|offensive))(?!.*(?:\d{10}|\d{4}-\d{2}-\d{2}))(?!.*<script>)(?!.*[;'])(?!.*\b(?:SELECT|UPDATE|DELETE)\b)[A-Za-z\d]{5,20}$/.test(username);
  };
  const validatePassword = (password) => {
    return /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/.test(password);
  };

  const sendOtp = (email) => {
    let isValidEmail = false;
    if (validateEmail(email)) {
          isValidEmail = true;
      }

    if (isValidEmail) {
        setIsEmailValid(true);
    } else {
        setIsEmailValid(false);
    }

    };
  
  const validateForm = (name, username, email, password, dob, flag) => {
    let isValid = true;
    const errors = {
        formName: "",
        formUsername: "",
        formEmailId: "",
        formPassword: "",
        formDob: "",
    };

    if (!name) {
        isValid = false;
        errors.formName = "Please enter your name.";
        setFormErrors(errors);
        return isValid;
    }

    if (!username) {
        isValid = false;
        errors.formUsername = "Please enter a valid username.";
        setFormErrors(errors);
        return isValid;
    } else {
      errors.formUsername = "";
      setFormErrors(errors);
    }

    if (!isUsernameAvailable) {
        isValid = false;
        errors.formUsername = "Please verify the username first.";
        setFormErrors(errors);
        return isValid;
    }

    if (!validateEmail(email)) {
      isValid = false;
      errors.formEmailId = "Invalid email Id.";
      setFormErrors(errors);
      return isValid;
    } else {
      errors.formEmailId = "";
      setFormErrors(errors);
    }

    if (!sessionId) {
      isValid = false;
      errors.formEmailId = "Please verify the email Id.";
      setFormErrors(errors);
      return isValid;
    } else {
      errors.formEmailId = "";
      setFormErrors(errors);
    }
  
    if (!password) {
      isValid = false;
      errors.formPassword = "Please enter the password.";
      setFormErrors(errors);
      return isValid;
    }else if (!validatePassword(password)) {
      isValid = false;
      errors.formPassword = `Please enter a valid Password. A strong Password should contain at least one digit, one uppercase letter, one lowercase letter, one special character, no spaces, and a minimum length of 8 characters;`;
      setFormErrors(errors);
      return isValid;
    } else {
      errors.formPassword = "";
      setFormErrors(errors);
    }
  
    const today = new Date();
    const thirteenYearsAgo = subYears(today, 13);
    
    if (!dob) {
        isValid = false;
        errors.formDob = "Please select a date of birth.";
        setFormErrors(errors);
        return isValid;
    } else if (new Date(dob) > thirteenYearsAgo) {
        isValid = false;
        errors.formDob = "You must be at least 13 years old.";
        setFormErrors(errors);
        return isValid;
    } else {
        errors.formDob = "";
        setFormErrors(errors);
    }

    if (!flag) {
      isValid = false;
      errors.formIsAgreed = "Please accept the Terms & Conditions.";
      setFormErrors(errors);
      return isValid;
    }
  
    return isValid;
  };

  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [asyncRequestResult, setAsyncRequestResult] = useState(null);

  useEffect(() => {
    const validateAndCheckUsername = async (username) => {
      let errors = {};
      if (!validateUsername(username)) {
        setIsUsernameAvailable(false);
        errors.formUsername = `Sorry, the username you've chosen is not valid.`;
        setFormErrors(errors);
        return;
      } else {
        errors.formUsername = "";
        setFormErrors(errors);
      }

      if (username) {
        const checkUsernameAvailability = debounce(async (username) => {
          try {
            setIsLoading(true);
            setIsUsernameAvailable(false);
            const response = await loginApiServices.checkUsernameAvailability(username);
            setAsyncRequestResult(response);
            if (response.statusCode === 200) {
              if (response.data[0].available !== null || response.data[0].available) {
                setIsUsernameAvailable(true);
              } else {
                setIsUsernameAvailable(false);
                errors.formUsername = `Sorry, the username is already taken.`;
                setFormErrors(errors);
              }
            } else {
              setIsUsernameAvailable(false);
            }
          } catch (error) {
            console.log("API Error", error);
            setIsUsernameAvailable(false);
          } finally {
            setIsLoading(false);
          }
        }, 500); // 500ms debounce time

        checkUsernameAvailability(username);
        // Cleanup function to cancel the debounce on unmount or on username change
        return () => {
          checkUsernameAvailability.cancel();
        };
      }
    };

    if (username && username.length >= 5) {
      validateAndCheckUsername(username);
    } else {
      // Clear errors if the username is too short or empty
      setFormErrors({});
      setIsUsernameAvailable(false);
    }
  }, [username]);


  const verifyEmailId = async (e) => {
        setErrorMessage('');
        e.preventDefault();
        setOtpId("");
        const errors = {
            formEmailId: "",
        };
        setFormErrors(errors);

        if (!validateEmail(email)) {
          errors.formEmailId = "Invalid email format.";
          setFormErrors(errors);
          return;
        }
    
        try {
          
          setIsLoading(true);
          const sendOtp = true;
          const response = await loginApiServices.validateEmailId(email, sendOtp);
          
          if (response.statusCode === 200 || response.statusCode === 0) {
            if(response.data !== null && response.data.length > 0 && response.data[0].available){
                setOtpId(response.data[0].otpId);
            }else {
                setOtpId("");
                errors.formEmailId = "This email address is already registered. Please try to log in or use forgotten password to reset your password.";
                setFormErrors(errors);
            }
          } else {
            setOtpId("");
            if(response.message !== null && response.message !== "" && typeof response.message !== 'object') {
              errors.formEmailId = response.message;
            } else {
              errors.formEmailId = "Unable to send OTP, Please contact for support.";
            }
            setFormErrors(errors);
          }
    
        } catch (error) {
          console.error("Signup Error:", error);
          errors.formEmailId = error.message;
          setFormErrors(errors);
        } finally {
          setIsLoading(false);
        }
  };

  
  const validateSignUpUser = async (e) => {
    setErrorMessage('');
    e.preventDefault();
    
    if (!validateForm(name,username, email, password, dob, flag)) {
      return;
    }

    let formattedDate = "";
    if(dob !== "" && dob !== null){
        const year = dob.getFullYear();
        const month = String(dob.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(dob.getDate()).padStart(2, '0');

        // Create the formatted date string
        formattedDate = `${year}/${month}/${day}`;

    }

    updateUserData({
      name,
      username,
      email,
      password,
      formattedDate,
      flag,
      sessionId,
      referralId,
    });

    navigate('/signup/profile_details');

  };  // end of validateSignUpUser


  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };


  return (
    
    <Container fluid className='noPadding'>
        <Row className={`${signStyles.signBackground}`}>
            <Row className={`mainPad d-flex align-items-center justify-content-between ${signStyles.viewportBox}`}>
                <Col xs={0} lg={5} className={signStyles.responsiveDisplay}>
                  <Row className={signStyles.signleftContent} >
                    <h1 className={signStyles.signBigContentline} >Welcome to Hapifyr!</h1>
                    <p className={signStyles.signParaContent}>To join our Community we just need some basic information.</p>
                  </Row>
                  
                </Col>
                <Col xs={0} lg={2} className={signStyles.responsiveDisplay}></Col>
                <Col xs={12} lg={5}>
                
                <div className={`${signStyles.signupSection}`}>
                    <div className={signStyles.refferalDiv}>
                      <label className={`${signStyles.BaseNameTag}`}>Mandatory Details*</label>
                      {referralId && (
                        <Button className={signStyles.refferalIdShow}>
                          {referralId}
                        </Button>
                      )}
                    </div>
                                    
                    <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>

                    <Row className={`d-flex align-items-center justify-content-between`}>
                    
                        <Col xs="auto">
                            <Row className={`d-flex justify-content-between  ${signStyles.progressframe}`} >
                            <Col className={signStyles.topline}>
                                <div className={signStyles.rectangle} />
                            </Col>

                            <Col>
                              <div className={signStyles.currentRunIcon}>
                                <div className={signStyles.blueBot}></div>
                              </div>
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >Basic details</p>
                            </Row>
                        </Col>

                        <Col xs="auto">
                            <Row className={signStyles.progressframe}>
                            <Col className={signStyles.topline}>
                                <div className={signStyles.strline} />
                            </Col>
                            <Col>
                            <div className={signStyles.indicator2} />
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >&nbsp; Profile &nbsp;</p>
                            </Row>
                        </Col>

                        <Col xs="auto">
                            <Row className={signStyles.progressframe}>
                            <Col className={signStyles.topline}>
                                <div className={signStyles.strline} />
                            </Col>
                            <Col>
                            <div className={signStyles.indicator2} />
                            </Col>
                            <Col className={signStyles.bottomline}>
                                <div className={signStyles.strline} />
                            </Col>
                            </Row>
                            <Row>
                                <p className={signStyles.NameTag} >Interests</p>
                            </Row>
                        </Col>
                        
                    </Row>
                    
                    <Row>
                        <Form onSubmit={validateSignUpUser}>
                                <Row>
                                <Col lg={9}>
                                <Form.Group controlId="formname" className='mb-2'>
                                <Form.Label className='m-0'>Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={name}
                                    // onChange={(e) => { setName(e.target.value); handleInputs(e); }}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Regex to allow only alphabetic characters
                                      const regex = /^[a-zA-Z\s]*$/;
                                      if (regex.test(value)) {
                                          setName(value);
                                          handleInputs(e);
                                      }
                                  }}
                                />
                                <Form.Text className="text-danger">{formErrors.formName}</Form.Text>
                                </Form.Group>
                                </Col>
                                <Col lg={3}></Col>
                                </Row>

                                <Row>   
                                <Form.Group className="mb-2" controlId="formUsername">
                                    <Form.Label className='m-0'>Username*</Form.Label>
                                    <Row>
                                        <Col lg={9}>
                                            <Form.Control type="text"
                                             value={username}
                                            onChange={(e) => setUsername(e.target.value)} 
                                            autoFocus={false}
                                            />
                                        </Col>
                                        <Col lg={3}>
                                            {username.length >= 5 && asyncRequestResult !== null && (
                                                <div>
                                                    <p className={`m-0 ${isUsernameAvailable ? 'text-success' : 'text-danger'}`} >
                                                    &nbsp; {isUsernameAvailable ? <GiCheckMark className={signStyles.bsCheck} /> : <RxCross2 className={signStyles.crossCheck} /> }
                                                    </p>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <Form.Text className="text-danger">{formErrors.formUsername}</Form.Text>
                                        {username.length >= 5 && asyncRequestResult !== null && (
                                          <div>
                                                <p className={`m-0 ${isUsernameAvailable ? 'text-success' : 'text-danger'}`} >
                                                 {isUsernameAvailable ? 'Username is available!' : 'Username is not available. Please choose a different one.'}
                                                </p>
                                            </div>
                                        )}
                                </Form.Group>
                                
                                </Row>
                                
                                <Row>
                                <Form.Group controlId="formEmailId" className='mb-2'>
                                <Form.Label className='m-0'>Email*</Form.Label>
                                <Row className='d-flex align-items-center justify-content-center'>
                                    <Col lg={9}>
                                        <Form.Control
                                        type="email"
                                        name="formEmailId"
                                        value={email}
                                        onChange={(e) => {setEmail(e.target.value); sendOtp(e.target.value);}} />
                                    </Col>
                                    <Col lg={3}>
                                        {isEmailValid && (
                                            <div className={signStyles.sendOtpdiv}>
                                               <Button onClick={verifyEmailId} className={signStyles.sendOtp}>Verify Email</Button>
                                            </div>
                                        )}
                                        {sessionId && (
                                            <>
                                            &nbsp; <GiCheckMark className={signStyles.bsCheck} />
                                            </>
                                        )}
                                    </Col>
                                </Row>
                                <Form.Text className="text-danger">{formErrors.formEmailId}</Form.Text>
                                </Form.Group>
                                </Row>
                                
                                {!sessionId && otpId && (
                                <Row>
                                    <Col lg={9}>
                                    <Form.Group className='mb-2 mt-3'>
                                        <OtpVerification email={email} otpId={otpId} updateSessionId={updateSessionId} />
                                    </Form.Group>
                                    </Col>
                                    <Col lg={3}></Col>
                                </Row>
                                )}

                                <Row>
                                <Col lg={9}>
                                <Form.Group controlId="formPassword" className='mb-2'>
                                    <Form.Label className='m-0'>Password*</Form.Label>
                                    
                                    <InputGroup>
                                        <Form.Control style={{borderRight: 'none'}}
                                            type={passwordVisible ? 'text' : 'password'}
                                            name="formPassword"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                        <Button variant="link" className={signStyles.eyeIcon} onClick={togglePasswordVisibility}>
                                        {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                                        </Button>
                                    </InputGroup>

                                    <Form.Text className="text-danger">{formErrors.formPassword}</Form.Text>
                                </Form.Group>
                                </Col>
                                <Col lg={3}></Col>
                                </Row>
                                
                                <Row>
                                <Col lg={9}>
                                <Form.Group controlId="formDob" className='mb-2'>
                                  <Form.Label className='m-0'>DOB*</Form.Label><br/>
                                  <div className="calendar-container">
                                    <DatePicker
                                      selected={dob}
                                      onChange={(date) => setDob(date)}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="DD/MM/YYYY"
                                      yearDropdownItemNumber={50}
                                      scrollableYearDropdown
                                      showYearDropdown
                                      dropdownMode="scroll"
                                      className="form-control"
                                      isClearable // Allow clearing the date
                                      maxDate={maxDate} // Prevent future dates
                                      minDate={minDate} // Set minimum age to 13 years
                                    />
                                  </div>
                                  <Form.Text className="text-danger">{formErrors.formDob}</Form.Text>
                                </Form.Group>
                                </Col>
                                <Col lg={3}></Col>
                                </Row>

                                <Row className={`mt-2 ${signStyles.formRow} `}>
                                <Col lg={10}>

                                <Form.Check
                                  className={signStyles.Checkboxlabel}
                                  type="checkbox"
                                  id="default-checkbox"
                                  label={
                                    <div>
                                        <Form.Label className={signStyles.floatingLabel}>
                                          By creating an account, I confirm that I have read and agree with the
                                          <button type="button" onClick={handleClick} className={signStyles.termsbutton}>
                                            Terms & Conditions
                                          </button>
                                            and
                                          <button type="button" onClick={handleClick} className={signStyles.termsbutton}>
                                            Privacy Policy
                                          </button>
                                        </Form.Label>

                                        <Overlay
                                          target={target}
                                          show={show}
                                          placement="bottom"
                                          trigger={['hover', 'focus']}
                                          >
                                          {(props) => (
                                            <Tooltip id="overlay-example" {...props}
                                            onMouseLeave={handleMouseLeave}
                                          >
                                          <p className={signStyles.TooltipTextbox}>This means I agree to hear from Hapifyr and related companies about special offers, products and services
                                            (unless you've already told us that you don't want to). You can opt out at any time.</p>
                                            </Tooltip>
                                          )}
                                        </Overlay>
                                      </div>
                                  }
                                  checked={flag}
                                  onChange={() => setFlag(!flag)}
                                />

                                <Form.Text className="text-danger">{formErrors.formIsAgreed}</Form.Text>
                                </Col>
                                <Col lg={2}></Col>
                                </Row>

                                <Row className='d-flex align-items-baseline'>
                                <Col lg={8}></Col>
                                <Col lg={4} className='text-end'>
                                    <Button className={signStyles.SubmitBtn} type="submit" >Join Hapifyr</Button>
                                </Col>
                                </Row>

                                {isLoading && <LoadingOverlay />}     
                                <br/>
                        
                        </Form>
                    
                    </Row>
                </div>

                </Col>

            </Row>

            <Row className={signStyles.smfooters}>
              <SmallFooter />
            </Row>

        </Row>
        
    </Container>
    
  );
};

export default MandatoryDetails;
