import { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './SellerStyle.module.css';
import SellActions from '../BaseComponents/SellActions';
import experienceServices from '../../Services/experienceServices';


const SellerdbActions = ({ type }) => {

  const [userWorkshopsCount, setUserWorkshopsCount] = useState(0);

  useEffect(() => {
    async function fetchUserWorkshopsCount() {
      try {
        const response = await experienceServices.fetchAllUserCreatedWorkshops();
        // console.log("fetchUserWorkshops Response:", response);
        if (response !== null && response.statusCode === 200) {
          if (response.data !== null && response.data !== "") {
            setUserWorkshopsCount(response.data.length);
          }
        }

      } catch (error) {
        console.error("API Error:", error);
      }
    }
    fetchUserWorkshopsCount();

  }, []);


  return (
    <Container className='noPadding stickySidebarOverview'>
      
          <Link to="/seller-experiences/create-experience">
            <Row className={styles.CreateExperienceBtnRow}>
              <button className={`${styles.CreateExperienceBtn}`} >
                Create Workshop
              </button>
            </Row>
          </Link>
      <br/>
      <SellActions userWorkshopsCount={userWorkshopsCount} actionType={type} />

    </Container>
  );
};

export default SellerdbActions;
