import { useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './ExperienceStudio.module.css';
import { Link } from 'react-router-dom';
import { SlArrowLeft } from "react-icons/sl";


const CreateExperience = ({ onStatusChange, appendtoMediaLibrary }) => {
  
  const fileInputRef = useRef(null);
  
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const images = Array.from(files);
    // console.log(" selectedImages :: ", selectedMedia);
    appendtoMediaLibrary(images);
    onStatusChange('select-media');
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };


  return (
    
    <Container fluid className="mainPad">
      <Row className={styles.ESWhiteBackdrop}>
        <Row className="d-flex justify-content-between align-items-center">
          <Col xs="auto">
            <div className={`d-flex align-items-center ${styles.HoverBackOptiondiv}`}>
              <Link to="/seller/dashboard">
                <SlArrowLeft className={styles.HoverBackOption} /> &nbsp;&nbsp;&nbsp;&nbsp;
              </Link>
              <p className={styles.labelname}>Back to Dashboard</p>
            </div>
          </Col>
        </Row>
        <br /><br />

        <Row>
          <div className={styles.labelContent}> Please select one or more videos to prepare your workshop.</div>
        </Row>
        <br /><br />

        <Row>
        <Col md={1} xs={3}>
        <Row className={`d-flex justify-content-between ${styles.progressframe}`} >
          <Col className={styles.topline}>
            <div className={styles.rectangle} />
          </Col>
          <Col>
            <div className={styles.currentRunIcon}>
               <div className={styles.blueBot}></div>
            </div>
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Start</p>
          </Row>
        </Col>

        
        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
          <p className={styles.NameTag} >Select Media </p>
        </Row>
        </Col>
        

        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Product Details </p>
          </Row>
        </Col>
        
        
        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Publish </p>
          </Row>
        </Col>
        </Row>


        <Row className="mb-5 mt-5 d-flex justify-content-center align-items-center">
          <Col className="d-flex flex-column align-items-center">
            <input
              type="file"
              accept="image/*,video/*"
              multiple
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              ref={fileInputRef}
              id="fileInput"
            />
            <div className={`${styles.uploadSectiondiv}`} onClick={openFileInput}>
              <div className={`${styles.uploadIcondiv}`}>
                <img
                  src="/Content/Images/General/document-upload.png"
                  className={`${styles.uploadbtnImage}`}
                  alt="upload"
                />
              </div>
              <label htmlFor="fileInput" className={`${styles.uploadNameTag}`}>
                Create a Workshop
              </label>
            </div>
          </Col>
        </Row>
        
      </Row>
      <br/><br/><br/>
    </Container>
  );
};

export default CreateExperience;
