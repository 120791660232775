import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from './Navbar/Header';
import MainPart from './Main/MainPart';
import FollowersPart from './Followers/FollowersPart';
import { CartProvider } from './Navbar/CartContext';


const Home = () => {

  const [loginStatus] = useState('loggedIn');
  const { homestatus, substatus } = useParams(); // Retrieve both homestatus and subStatus as route parameters

  return (
    <CartProvider>
    <div className="Homediv">
        <Header loginStatus={loginStatus} />

        {homestatus === 'home' && <MainPart />}
        {homestatus === 'followers' && <FollowersPart type={homestatus} subType={substatus} />}

    </div>
    </CartProvider>
  );
}

export default Home;
