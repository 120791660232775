import { Row } from 'react-bootstrap';
// import Feeditems from './Feeditems';
import axiosAPI from '../../../Services/axiosAPI';
import userServices from '../../../Services/userServices';
import cartServices from '../../../Services/cartServices';
import { useCart } from '../../Navbar/CartContext';
import ProductComponent from './ProductComponent';
import BoastComponent from './BoastComponent';


const Feed = ({ feeds, setFeeds }) => {

  // console.log("Feeds ::",feeds);
  const { dispatch } = useCart();

  const LikeOptimisticHandler = async(fId, bId, type) => {
    
    const accessToken = localStorage.getItem('accessToken');
    const token = `Bearer ${accessToken}`;
    const URL = type ? `feedback/blike/${fId}/${bId}` : `feedback/blike/${fId}/${bId}?revoke=true`
    await axiosAPI.axiosAuth.get(URL, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
  }
  
  const MutateBoastLikeStatus = (bId) => {
    
    let feedsArr = [...feeds]
    let foundBoast = feedsArr.find(i => i.id === bId)
    let currentLikeStatus = foundBoast.isLikedByMember
    foundBoast.isLikedByMember = !currentLikeStatus
    let feedId = foundBoast.fId
    
    if(currentLikeStatus === false){
      foundBoast.totalLikes=foundBoast.totalLikes+1
      LikeOptimisticHandler(feedId, bId, true)
    }else{
      foundBoast.totalLikes=foundBoast.totalLikes-1
      LikeOptimisticHandler(feedId, bId, false)
    }
    setFeeds(feedsArr)
  }


  const FollowFeedUser = (fId) => {
    
    let feedsArr = [...feeds];
    let foundFeed = feedsArr.find(i => i.fId === fId);
    let userId = foundFeed.postedByUserId;
    let currentFollowStatus = foundFeed.isFollowing;

    let feedsByUserId = feedsArr.filter(feed => feed.postedByUserId === userId);
    feedsByUserId.forEach(feed => {
      if (feed.hasOwnProperty('isFollowing')) {
          feed.isFollowing = !feed.isFollowing;
      }
    });
    setFeeds(feedsArr);

    if(currentFollowStatus === false){
      FollowOptimisticHandler(userId, true);
    }else {
      FollowOptimisticHandler(userId, false);
    }
  }

  const FollowBoastUser = (bId) => {
    
    let feedsArr = [...feeds];
    let foundBoast = feedsArr.find(i => i.id === bId);
    let userId = foundBoast.postedByUserId;
    let currentFollowStatus = foundBoast.isFollowing;

    let feedsByUserId = feedsArr.filter(feed => feed.postedByUserId === userId);
    feedsByUserId.forEach(feed => {
      if (feed.hasOwnProperty('isFollowing')) {
          feed.isFollowing = !feed.isFollowing;
      }
    });
    setFeeds(feedsArr);

    if(currentFollowStatus === false){
      FollowOptimisticHandler(userId, true);
    }else {
      FollowOptimisticHandler(userId, false);
    }
  }


  const FollowOptimisticHandler = async(userId, type) => {

    if(userId === "" || userId === null){
      alert("Please provide the UserId first.");
      return;
    }

    if(type){
      try {
        const response = await userServices.followUser(userId);
        console.log("Rss:", response);
      } catch (error) {
        console.error("API Error:", error);
      }

      } else {
        try {
          const response = await userServices.unfollowUser(userId, true);
          console.log("Rss:", response);
        } catch (error) {
          console.error("API Error:", error);
        }
      }
  }

  const AddItemtoCart = async (feedId) => {
    try {
      const response = await cartServices.validateCartandCount(feedId);
      // console.log("API Response:", response);
      if (response.statusCode === 200) {
        if(response.data[0].isAdded){
          let feedsArr = [...feeds];
          let foundFeed = feedsArr.find(i => i.fId === feedId);
          let currentCartStatus = foundFeed.isAddedToCart;
          foundFeed.isAddedToCart = !currentCartStatus;
          setFeeds(feedsArr);
          dispatch({ type: 'SET_CART_COUNT', payload: response.data[0].totalCartItems });
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };


  return (
    <div className="">
      <Row className="feedBodyBorder">
      {/* {feeds.map((feed, index) => (
        <Feeditems key={index} feed={feed} MutateFeedLikeStatus={MutateFeedLikeStatus} FollowFeedUser={FollowFeedUser} AddItemtoCart={AddItemtoCart} />
      ))} */}

      {feeds.map((feed, index) =>
        feed.feedType === "Boast" ? (
          <BoastComponent key={index} feed={feed} FollowBoastUser={FollowBoastUser} MutateBoastLikeStatus={MutateBoastLikeStatus} />
        ) : feed.feedType === "Product" ? (
          <ProductComponent key={index} feed={feed} FollowFeedUser={FollowFeedUser} AddItemtoCart={AddItemtoCart} />
        ) : null
      )}

      </Row>
    </div>
  );
};

export default Feed;