import { useParams } from "react-router-dom";
import AboutUs from './General/AboutUs';
import PrivacyPolicy from "./General/PrivacyPolicy";
import TermsandConditions from "./General/TermsandConditions";
import HostFAQs from "./General/HostFAQs";
import BuyerFAQs from "./General/BuyerFAQs";


const GeneralPages = () => {
  
  const { generalType } = useParams();

  return (
    <main>

      {generalType === 'about-us' && <AboutUs />}
      {generalType === 'privacy-policy' && <PrivacyPolicy />}
      {generalType === 'terms-and-conditions' && <TermsandConditions />}
      {generalType === 'host-faqs' && <HostFAQs />}
      {generalType === 'buyer-faqs' && <BuyerFAQs />}

    </main>

  );
}

export default GeneralPages;
