import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './ExperienceStudio.module.css';
import { SlArrowLeft } from "react-icons/sl";
import { FaCheckCircle } from "react-icons/fa";


const SelectMedia = ({onStatusChange, selectedMedia, selectedMediatoSequence}) => {
  
  const [allMediaItems, setAllMediaItems] = useState(selectedMedia);
  const [selectedMediaLength, setSelectedMediaLength] = useState(selectedMedia.length);
  const [selectedMediaItems, setSelectedMediaItems] = useState([]);
  const [selectedMediaData, setSelectedMediaData] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

   useEffect(() => {
     // console.log('selectedMedia:', selectedMedia);
     const selectedMediaArray = Array.from(selectedMedia);
    sessionStorage.setItem('selectedMedia', selectedMediaArray);
    // console.log(sessionStorage.getItem('selectedMedia'));
  }, [selectedMedia]);


  const fileInputRef = useRef(null);
  const openFileInput = () => {
    fileInputRef.current.click();
  };

  const handleMoreUpload = (event) => {
    const files = event.target.files;
    const newMediaItems = Array.from(files);
  
    const uniqueNewMediaItems = newMediaItems.filter((newItem) => {
      return !allMediaItems.some((existingItem) => existingItem.name === newItem.name);
    });
  
    setAllMediaItems((prevMediaItems) => [...prevMediaItems, ...uniqueNewMediaItems]);
    setSelectedMediaLength(prevLength => prevLength + uniqueNewMediaItems.length);
  };
  

  const appendSelectedMediatoSequence = () => {
    if (selectedMediaData.length === 0) {
      setErrorMsg('Please select at least one video/image to continue.');
      return;
    }else {
      setErrorMsg("");
    }
    onStatusChange('setting-sequence');
    selectedMediatoSequence(selectedMediaData);
  };


  const clicktoSelectMediaItems = (media) => {
    setSelectedMediaItems((prevSelectedItems) => {
      const updatedItems = { ...prevSelectedItems };
      const mediaIndex = allMediaItems.findIndex((item) => item === media);
      if (mediaIndex !== -1) {
        updatedItems[mediaIndex] = !updatedItems[mediaIndex];
      }
      const selectedMediaData = allMediaItems.filter((_, index) => updatedItems[index]);
      setSelectedMediaData(selectedMediaData);
      return updatedItems;
    });
  };

  const backtoPrevious = () => {
    onStatusChange('create-experience');
  };

  return (
    <Container fluid className="mainPad">
      <Row className={styles.ESWhiteBackdrop}>

      <Row className="d-flex justify-content-between align-items-center">
        <Col xs="auto">
          <div className="d-flex align-items-center">
            <Link onClick={backtoPrevious}>
              <SlArrowLeft className={styles.HoverBackOption} /> &nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
            <p className={styles.labelname}>Media Library </p>
          </div>
        </Col>
        <Col xs="auto">
          <label htmlFor="fileInput">
            <Button onClick={openFileInput}>
              &nbsp; Add More &nbsp;
            </Button>
          </label>
          <input id="fileInput" type="file"
            accept="image/*, video/*" multiple
            onChange={handleMoreUpload}
            style={{ display: 'none' }} ref={fileInputRef}
          />
        </Col>
        <div className={`mb-3 ${styles.labelContent}`}> Please choose one or more videos from the media library </div>
      </Row>
        <Row>
        </Row>

        <Row className='mt-3'>
        <Col md={1} xs={3}>
        <Row className={`d-flex justify-content-between  ${styles.progressframe}`} >
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <FaCheckCircle className={styles.circleCompleted} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
          <p className={styles.NameTag} >Start</p>
        </Row>
        </Col>

        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.rectangle} />
          </Col>
          <Col>
            <div className={styles.currentRunIcon}>
              <div className={styles.blueBot}></div>
            </div>
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Select Media </p>
          </Row>
        </Col>

        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Product Details </p>
          </Row>
        </Col>
        
        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Publish </p>
          </Row>
        </Col>
        </Row>

        <Row className={`d-flex justify-content-between align-items-center mb-4 ${styles.RectBox}`} style={{ pointerEvents: 'none' }} >
        {/* <Col xs="auto" >
            <Form.Group controlId="validationCustom01" className={`mb-2 ${styles.dropdownMediatype}`}>
                <Form.Select aria-label="Floating label select example" className={styles.dropdownMediatype}>
                    <option value="AllMedia">All Media Files</option>
                    <option value="images">Images</option>
                    <option value="Videos">Videos</option>
                </Form.Select>
            </Form.Group>
        </Col>
        <Col xs="auto">
            <input className="form-control bg-light" type="search" placeholder="Search..." />
        </Col> */}
        </Row>
        <br /><br />
        <Row>
            <Col xs="auto" className={styles.filesBtndiv}>
                <Button className={styles.filesBtn}>{selectedMediaLength} Files found</Button>
            </Col>
        </Row>
        <br /><br />
        {errorMsg && <div className={styles.errorMessage} style={{ textAlign: "center" }}>{errorMsg}</div>}

        <Row className={`d-flex justify-content-start mb-5`}>
          {allMediaItems.map((media, index) => (
            <Col key={index} md={1} className={`${styles.ColImageWidth}`}>
                <div
                  className={`${styles.itemContainer} ${selectedMediaItems[index] ? styles.selected : ''}`}
                  onClick={() => clicktoSelectMediaItems(media)}
                >
                {media.type.startsWith('image') ? (
                  <img
                    className={styles.ImagesSize}
                    alt={media.name}
                    src={URL.createObjectURL(media)}
                  />
                ) : media.type.startsWith('video') ? (
                  <video
                    className={styles.ImagesSize}
                    alt={media.name}
                  >
                  <source src={URL.createObjectURL(media)} type={media.type} />
                  </video>
                ) : null}
                {selectedMediaItems[index] && (
                  <div className={styles.BadgeSelection}>
                    <img alt="selectionIcon" 
                      src="/Content/Images/icons/selectedBadge.png"
                      width='25%' />
                  </div>
                )}
              </div>
            </Col>
          ))}
        </Row>

        <br /><br />
        <br /><br />
        <br /><br />

        <Row className="justify-content-end">
        <Col xs="auto">
          <Link onClick={backtoPrevious}>
            <Button variant="outline-primary">&nbsp;&nbsp; Back &nbsp;&nbsp;</Button>&nbsp;&nbsp;
          </Link>
        </Col>
        <br/><br/>
        <Col xs="auto">
        <Button variant="primary" onClick={appendSelectedMediatoSequence} >&nbsp;&nbsp; Next &nbsp;&nbsp;</Button>
        </Col>
        </Row>

        
        <br /><br />

      </Row>
    <br/><br/><br/>
    </Container>
  );
};

export default SelectMedia;