import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import loginStyles from './signin.module.css';
import loginApiServices from '../../../Services/loginApiServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import SmallFooter from '../../BaseComponents/SmallFooter';


const ResetPassword = ({ onVerificationStatus }) => {
  
  const navigate = useNavigate();
 
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailid, setEmailid] = useState('');
  const [errors, setErrors] = useState({
    emailidError: "",
  });

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };


  const validateForgotPassword = async (e) => {
    e.preventDefault();
    const newErrors = { emailidError: ""};
    setErrorMessage("");
    
    if (emailid === null || emailid === "" || emailid === undefined) {
      newErrors.emailidError = 'Please enter username or email';
      setErrors(newErrors);
      return;
    }else if (!validateEmail(emailid)) {
      newErrors.emailidError = 'Please enter valid Email ID';
      setErrors(newErrors);
      return;
    } else {
      setErrors(newErrors);
    }

    setIsLoading(true); 

    try {
      const response = await loginApiServices.validateForgetPassword(emailid);
      //console.log("API Response:", response);
  
      if (response.statusCode === 200) {
        if(response.data !== null && response.data[0] !== null) {
        
        const queryParams = new URLSearchParams();
        queryParams.append("otpId", response.data[0].otpId);
        queryParams.append("emailid", emailid);

        const urlWithParams = `/signin/otp-verification?${queryParams.toString()}`;
        // Use backticks (`) to create a template string
        navigate(urlWithParams);
        onVerificationStatus("otp-verification");
        //window.location.href = `/signin/OtpVerification?${queryParams.toString()}`;

        }else {
          setErrorMessage(response.message);
        }
      } else {
        setErrorMessage(response.message);
      }

    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  
  return (
    <Container fluid className={`noPadding`}>
        <Row className={`${loginStyles.signBackground}`}>
            <Row className={`mainPad d-flex align-items-center justify-content-between ${loginStyles.viewportBox}`}>
                <Col xs={12} lg={5} className={loginStyles.responsiveDisplay}>
                    <Row className='mt-5'>
                    <Image
                        src='/Content/Images/login_Image.png'
                        alt="login_banner"
                        className="loginBanner"
                    />
                    </Row>
                </Col>
                <Col xs={0} lg={2} className={loginStyles.responsiveDisplay}></Col>
                <Col xs={12} lg={5}>
                  <div className={`${loginStyles.signinSection}`}>
                      <Row className='text-center'>
                                  <Row>
                                      <Col>
                                      <Button className={`rounded-pill ${loginStyles.IconLogins}`}>
                                          <Image
                                              src='/Content/Images/Signin/reset_password.png'
                                              alt="login_icon"
                                              width={30}
                                              height={30}
                                          />
                                      </Button>
                                      </Col>
                                  </Row>

                      <label className={`styleHeader`}>Reset Password</label>
                      </Row>
                      
                      
                  <Row>
    
                      <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
                      <br/>
                      <Form method="POST" onSubmit={validateForgotPassword}>
                      <Form.Group>
                      <Form.Label className='mb-1'>Email Address *</Form.Label>
                      <Form.Control
                          type="text"
                          value={emailid}
                          onChange={(e) => setEmailid(e.target.value)}
                          placeholder=""
                      />
                      <Form.Text className="text-danger">{errors.emailidError}</Form.Text>
                      </Form.Group>
                      <br/>
                      
                      <br/>
                      <Row>
                          <Button className={loginStyles.btnloginsetup} type="submit">Send OTP</Button>
                      </Row>

                      {isLoading && <LoadingOverlay />}
                      <br/>
                      <Row>
                          <Col xs={3} className="line"><hr /></Col>
                          <Col xs={6} className="text-center">
                            Please check the Mail
                          </Col>
                          <Col xs={3} className="line"><hr /></Col>
                      </Row>
                      <br/>
                      
                      </Form>
            
          
              
                      </Row>
                  </div>
                </Col>
                <br />

            </Row>
        </Row>
        
      <SmallFooter />
    </Container>
  );
};

export default ResetPassword;
