import { useState, useEffect, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import searchStyles from './header.module.css';
import { Container, Image, Button, Spinner } from "react-bootstrap";
import userServices from "../../Services/userServices";
import { Link } from "react-router-dom";


const SearchBar = () => {
  const [input, setInput] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const popupRef = useRef(null);

  const searchResults = async (type, query) => {
    if (query.length <= 2){
      setResults([]);
      return;
    }
    if (!query) {
      setResults([]);
      return;
    }
    if (type !== null && type !== undefined && type !== "") {
      setFilterType(type);
    }

    try {
      setIsLoading(true);
      const searchResponse = await userServices.headerSearchOptimizer(type, query, 1);
      if (searchResponse !== null && searchResponse.statusCode === 200 && searchResponse.data !== null && searchResponse.data !== "") {
        setResults(searchResponse.data);
      } else {
        setResults([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Search API Error:", error);
      setIsLoading(false);
      setResults([]);
    }
  };

  const handleChange = (type, value) => {
    setInput(value);
    setFilterType(type);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      searchResults(type, value);
    }, 500);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  return (
    <Container fluid className={searchStyles.searchContainer}>
      <div className={searchStyles.inputWrapper}>
        <FaSearch id="search-icon" className={searchStyles.searchIcon} />
        <input
          placeholder="Search... (for Users and Workshops)"
          value={input}
          onChange={(e) => handleChange('all', e.target.value)}
        />
        {isLoading && 
        <div className={searchStyles.popup} ref={popupRef}>
          <div className={searchStyles.SpinnerPopDiv}>
            <Spinner animation="grow" className='text-center' />
          </div>
        </div>
        }

        {results && results.length > 0 ? (
          <div className={searchStyles.popup} ref={popupRef}>
            <div className="mt-2 mb-2 text-end">
              <Button className={`${searchStyles.searchFilterButtons} ${filterType === 'workshops' ? searchStyles.searchButtonsActive : ''}`}
               onClick={() => searchResults('workshops', input)} >Workshops</Button>
               {' '}
              <Button className={`${searchStyles.searchFilterButtons} ${filterType === 'users' ? searchStyles.searchButtonsActive : ''}`}
               onClick={() => searchResults('users', input)} >Users</Button>
            </div>

            {filterType !== 'users' && (
              <div className={searchStyles.searchRecentdiv}>
                <h4 className={searchStyles.headerlineStyle}>Workshops</h4>
                <div className={searchStyles.workshopsItemsdiv}>
                  {results && results[0].workshops && results[0].workshops.length > 0 ? (
                    results[0].workshops.map((workshop) => (
                      <Link to={`/product_details/${workshop._id}`} key={workshop._id}>
                        <div className={searchStyles.workshopItemdiv}>
                          {workshop.cover ? (
                            <Image src={workshop.cover} roundedCircle className={searchStyles.profilePic} alt="Profile" />
                          ) : (
                            <Image src="/Content/Images/empty_user.jpg" roundedCircle className={searchStyles.profilePic} alt="Profile" />
                          )}
                          <div className={searchStyles.ItemsDataTagsdiv}>
                            <h4 className={searchStyles.profileNameTag}>{workshop.caption}</h4>
                            <label className={searchStyles.profileDescTag}>{workshop.description}</label>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <label className={searchStyles.profileDescTag} >No workshops found.</label>
                  )}
                </div>
                <div className={searchStyles.seeAlldiv}>
                  <button className={searchStyles.seeAllButton}>
                    See All
                  </button>
                </div>
              </div>
            )}
            <br />

            {filterType !== 'workshops' && (
              <div className={searchStyles.searchRecentdiv}>
                <h4 className={searchStyles.headerlineStyle}>Users</h4>
                <div className={searchStyles.workshopsItemsdiv}>
                  {results && results[0].profiles.length > 0 ? (
                    results[0].profiles.map((user) => (
                      <Link to={`/seller/public-profile/${user._id}`} key={user._id}>
                        <div className={searchStyles.workshopItemdiv}>
                          {user.imageUrl ? (
                            <Image src={user.image_url} roundedCircle className={searchStyles.profilePic} alt="Profile" />
                          ) : (
                            <Image src="/Content/Images/empty_user.jpg" roundedCircle className={searchStyles.profilePic} alt="Profile" />
                          )}
                          <div className={searchStyles.ItemsDataTagsdiv}>
                            <h4 className={searchStyles.profileNameTag}>{user.name}</h4>
                            <label className={searchStyles.profileDescTag}>{user.bio}</label>
                          </div>
                        </div>
                      </Link>
                    ))
                  ) : (
                    <label className={searchStyles.profileDescTag} >No Users found.</label>
                  )}
                </div>
                <div className={searchStyles.seeAlldiv}>
                  <button className={searchStyles.seeAllButton}>
                    See All
                  </button>
                </div>
              </div>
            )}
            <br />
          </div>
        ) : (
          !isLoading && input && results && results.length > 0 && results[0].profiles.length > 0 && results[0].workshops.length > 0 && (
            <div className={searchStyles.popup} ref={popupRef}>
              <label className={searchStyles.profileDescTag} >No data found with the given search.</label>
            </div>
          )
        )}
      </div>
    </Container>
  );
};

export default SearchBar;
