import React, { useState } from 'react';
import Header from './Navbar/Header';
import ManagingExperiences from './SellerDashboard/ManagingExperiences';


const ManageExperiences = () => {

  const [loginStatus] = useState('loggedIn');


  return (
    <div className="SellarDashboardDiv">
      <Header loginStatus={loginStatus} />
      <ManagingExperiences />
    </div>

  );
}

export default ManageExperiences;
