import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authStyles from './auth.module.css';
import AuthContent from './AuthContent';


const AuthBody = () => {
  

  return (
    <Container fluid style={{ paddingRight: '0px', paddingLeft: '0px' }}>
      <Row className={`${authStyles.AuthBannerSetup}`}>
        <Row className={`${authStyles.authFirstRow}`}>
          <Col xs={12} md={6} className="mainPad">
            <Row className={authStyles.ContentRow}>
              <h2 className="Biglabel">Find your Happy Place with Hapifyr!</h2>
              <p className="ContentLabel">
                At Hapifyr, our interactive workshops bring joy and connection, two essential ingredients to happiness. We're more than just a platform;
                we're a dynamic community of diverse individuals who come together to explore, share and celebrate the activities that make them happiest, from anywhere, anytime.
                <br />
                Join us on our mission to Ignite Happiness in ourselves and those around us!
              </p>
            </Row>
            <Row className={`d-flex ResponsiveCenteredAlignedButtons`}>
              <Col xs="auto">
                <Link to="/signup/mandatory-details">
                  <Button className={authStyles.SellerAuthBtns}>Join Hapifyr</Button>
                </Link>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
              <img src="/Content/Images/Auth/Banner_2.png" alt="Banner" className={authStyles.mainBannerSetup} />
          </Col>
        </Row>
      </Row>

      <AuthContent />
    </Container>
    
  );
};

export default AuthBody;
