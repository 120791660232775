import { useState, useEffect } from 'react';
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import profileStyles from './Profile.module.css';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import userServices from '../../Services/userServices';


const Bio = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [user, setUser] = useState({
    bio: "",
  });

  const [errors, setErrors] = useState({
    bio: "",
  });

  const handleInputs = (value, name) => {
    if (name) {
      setUser({ ...user, [name]: value });
      setErrors({ ...errors, [name]: '' });
    }
    setErrorMessage("");
  };

  useEffect(() => {
    const profileData = localStorage.getItem('userProfileData');
    if (profileData) {
      const parsedData = JSON.parse(profileData);
      // console.log("Data::", parsedData)
      setUser({
        bio: parsedData.bio || '',
      });
    }
  }, []);


  const updateBasicDetails = async (e) => {
    
    e.preventDefault();
    const { bio } = user;
    const newErrors = { bio: "" };
    
    if (!bio) {
      newErrors.bio = "Please enter Bio.";
      setErrors(newErrors);
      return;
    }else if (bio.length >= 250) {
      newErrors.bio = "Bio can't exceed more than 250 Chars.";
      setErrors(newErrors);
      return;
    }
    
    if (newErrors.bio) {
      setErrors(newErrors);
      return;
    }
    
    setErrorMessage("");
    setIsLoading(true);
    setSuccessMessage("Data Updated Sucessfully.");
    
    try {
      const response = await userServices.updateUserProfileBio(bio);
      
      if (response.statusCode === 200) {
        const profileData = localStorage.getItem('userProfileData');
        if (profileData) {
          const parsedData = JSON.parse(profileData);
          parsedData.bio = bio;
          localStorage.setItem('userProfileData', JSON.stringify(parsedData));
        }
        setSuccessMessage("Bio updated successfully.");

        } else {
          setErrorMessage(response.message);
        }
      
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while updating user bio.");
    } finally {
      setIsLoading(false);
    }
  };


  return (
    
    <Container className='RightPadding'>
    <Row className={`d-flex flex-column align-items-center boxBody ${profileStyles.profileSectionVP}`}>

        <h5 className={`mb-3 mt-2 mainHeaderline`}>Bio </h5>
        
        <Row className='d-flex align-items-center justify-content-center'>
        
          {isLoading ? (
            <LoadingOverlay />
          ):(
            
            <Row className='minPadding'>
            
            <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
            <Form.Text className="text-success" align="center">{successMessage}</Form.Text>
            
            <Form method="POST" onSubmit={updateBasicDetails}>
            <Row>
            <Col md={6}> 

            <Form.Group controlId="formBio" className='mb-2'>
                <Form.Label className={profileStyles.profileFormLabel}>Bio</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Enter details upto 100 (words)"
                    name="bio"
                    className={profileStyles.profileFormControl}
                    value={user.bio}
                    onChange={(e) => handleInputs(e.target.value, e.target.name)}
                    rows={5}
                />
                <Form.Text className="text-danger">{errors.bio}</Form.Text>
            </Form.Group>
   
            </Col>
            <Col md={6}></Col>
            </Row>
            
            <Row>
              <br /><br />
            </Row>

            <Row className='d-flex align-items-center justify-content-end'>
              <Col xs='auto'>
                <Button className='CancelButtonSetup'>Cancel</Button>
              </Col>
                &nbsp;&nbsp;
              <Col xs='auto'>
                <Button className='SubmitButtonSetup' type="submit" >Submit</Button>
              </Col>
            </Row>

            </Form>
            
            </Row>
        
          )}

        </Row>
        
    </Row>
    </Container>
  );
};

export default Bio;
