import { useState, useEffect } from 'react';
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import profileStyles from './Profile.module.css';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import loginApiServices from '../../Services/loginApiServices';
import userServices from '../../Services/userServices';


const Interests = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  // const [selectedInterests, setSelectedInterests] = useState([]);
  const [allInterests, setAllInterests] = useState([]);
  const [interestDataFetched, setInterestDataFetched] = useState(false);


useEffect(() => {
    if (!interestDataFetched) {
      async function fetchUserInterests() {
        try {
          
          setIsLoading(true);
          const response = await loginApiServices.getAllUserInterests();
          //console.log("fetchUserInterests Response:", response);
          if (response.statusCode === 200 && response.data !== null && response.data.length > 0 ) {
           // setSelectedInterests(response.data);
           setAllInterests(prevSelectedInterests => response.data);
           setInterestDataFetched(true);
          } else {
            setErrorMessage(response.message);
          }
        } catch (error) {
          console.error("Signup Error:", error);
        } finally {
          setIsLoading(false);
        }
      }
      fetchUserInterests();
    }
  }, [interestDataFetched]);


  const handleInterestClick = (interest) => {
    
    const newErrors = { interestsError: "" };
    setErrors(newErrors);

    let tempArr = Array.from(user.interests);
    let filteredInterests = [];
  
    if (tempArr.includes(interest)) {
      filteredInterests = tempArr.filter((item) => item !== interest);
    } else {
      filteredInterests = [...tempArr, interest];
    }

    setUser((prevUserData) => ({
      ...prevUserData,
      interests: filteredInterests,
    }));
    /// setSelectedInterests(filteredInterests);
  };

  const [user, setUser] = useState({
    interests: [],
  });

  const [errors, setErrors] = useState({
    interestsError: "",
  });

  const handleInputs = (value, name) => {
    if (name) {
      setUser({ ...user, [name]: value });
      setErrors({ ...errors, [name]: '' });
    }
    setErrorMessage("");
  };

  useEffect(() => {
    const profileData = localStorage.getItem('userProfileData');
    
    if (allInterests !== null && allInterests.length > 0 && profileData) {
      const parsedData = JSON.parse(profileData);
      console.log("Data::", parsedData)
      setUser({
        interests: parsedData.interests || '',
      });
    }
    
  }, [allInterests]);


  const updateProfileInterests = async (e) => {
    e.preventDefault();
    const { interests } = user;
    const newErrors = { interestsError: "" };
    
    if (interests.length === 0) {
      newErrors.interestsError = "Please select atleast one Interest.";
      setErrors(newErrors);
      return;
    }
    
    setErrorMessage("");
    setIsLoading(true);

    // Convert list to comma-separated string
    const interestsString = interests.join(',');
    console.log(interestsString);
    
    try {

      const response = await userServices.updateUserProfileInterests(interestsString);
      
      if (response.statusCode === 200) {
          const profileData = localStorage.getItem('userProfileData');
          if (profileData) {
              const parsedData = JSON.parse(profileData);
              parsedData.interests = interests;
            localStorage.setItem('userProfileData', JSON.stringify(parsedData));
          }

          setSuccessMessage("User Interests updated successfully.");
        
        } else {
          setErrorMessage(response.message);
        }
    
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while update profile interests.");
    } finally {
      setIsLoading(false);
    }
  };


  return (
    
    <Container className='RightPadding'>
    <Row className={`d-flex flex-column align-items-center boxBody ${profileStyles.profileSectionVP}`}>

        <h5 className={`mb-3 mt-2 mainHeaderline`}>Interests </h5>
        
        <Row className='d-flex align-items-center justify-content-center'>
        
          {isLoading ? (
            <LoadingOverlay />
          ):(
            
            <Row>
            
            <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
            <Form.Text className="text-success" align="center">{successMessage}</Form.Text>
            
            <Form method="POST" onSubmit={updateProfileInterests}>
            <Row>
            <Col md={10}> 
            {/* Interests showing */}
                                
                    {isLoading ? (
                      <LoadingOverlay />
                      ) : (
                        <Row className='minPadding'>

                          <Row className={`mt-2 mb-2 ${profileStyles.interestItemOverflow}`}>
                              {allInterests.map((interest, index) => (
                                <Col xs={3} key={index}
                                  className={`${profileStyles.interestItem} ${user.interests.includes(interest.interestName) ? profileStyles.selected : ''}`}
                                  onClick={() => {
                                    handleInterestClick(interest.interestName);
                                    handleInputs();
                                  }} >
                                    <div key={interest.interestId} >
                                      <label className={profileStyles.interestItemName} >{interest.interestName}</label>
                                    </div>
                                </Col>
                              ))}
                            <Form.Text className="text-danger">{errors.interestsError}</Form.Text>
                          </Row>

                        </Row>
                      )}
        
            </Col>
            <Col md={2}>
            </Col>
            </Row>

            <Row>
              <br /><br />
            </Row>

            <Row className='d-flex align-items-center justify-content-end'>
              <Col xs='auto'>
                <Button className='CancelButtonSetup'>Cancel</Button>
              </Col>
                &nbsp;&nbsp;
              <Col xs='auto'>
                <Button className='SubmitButtonSetup' type="submit" >Submit</Button>
              </Col>
            </Row>

            </Form>
            </Row>
        
          )}

        </Row>
        
    </Row>
    </Container>
  );
};

export default Interests;
