import { Link } from 'react-router-dom';
import { Row, Image } from 'react-bootstrap';
import hubStyles from './hub.module.css'; 



const PurchasedExperiences = ({ experiences }) => {
  

    return (

    <Row className={`boxBody ${hubStyles.PurchasedExperiencesTable} RightPadding`}>

    <table>
      <thead>
        <tr className='text-center'>
          <th className='text-start'>Workshop name</th>
          <th>Date</th>
          <th>Boasts</th>
        </tr>
      </thead>
      <tbody>

      {experiences.length > 0 ? (
        experiences.map((experience, index) => (
          <tr key={index} className={`${hubStyles.PrExpsTr}`} id={experience.id}>
            <td>
              <Link to={`/experiencehub/experience/${experience.id}`} >
                <div className='mb-2 mt-2 d-flex align-items-center'>
            
                  {experience.icon ? (
                    <Image src={experience.icon} 
                    alt="Thumbnail"
                    className={hubStyles.PRImage}
                     />
                  ) : (
                    <Image src="/Content/Images/SellerExpStudio/noImageBG.jpeg"
                    alt="Thumbnail"
                    className={hubStyles.PRImage}
                     />
                  )}

                  <div className={hubStyles.hubWorkshopLinkDiv}>
                    <h5 className={hubStyles.PECaption}>{experience.caption}</h5>
                    <p className={hubStyles.PEDesc}>{experience.desc}</p>
                  </div>
                  <br />
                </div>
              </Link>
            </td>
            <td className={hubStyles.PEDateandCount}>{experience.datePurchased}</td>
            <td className={hubStyles.PEDateandCount}>{experience.reviewedCount}</td>
          </tr>
          
        ))
      ) : (
        <tr>
          <td colSpan="3" style={{ textAlign: 'center',padding: '1em 0em', fontSize: '1.1em',fontWeight: 'bold',background: '#E0E0FC' }}>
            You have not purchased any workshops.
          </td>
        </tr>
      )}

      </tbody>
    </table>

    </Row>
  );
};

export default PurchasedExperiences;
