import UserProfile from '../BaseComponents/UserProfile';
import HomeOverview from '../BaseComponents/HomeOverview';
// import Filters from './LeftComponents/Filters';
// import ProductsforSale from './LeftComponents/ProductsforSale';
// import PurchasedProducts from './LeftComponents/PurchasedProducts';
// import Fallowers from './LeftComponents/Fallowers';


const Leftsidebar = ({ type }) => {


  return (
    <div className="stickySidebar">
    
      
      <UserProfile />
      {/* <Filters/> */}
     
      <br />
      
      <HomeOverview type={type} />
      <br />
      

    </div>
    
  );
};

export default Leftsidebar;