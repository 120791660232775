import React, { useState } from 'react';
import { useCart } from '../../Navbar/CartContext';
import { Modal, Button, Row } from 'react-bootstrap';
import cartStyles from './cart.module.css';
import { FaFistRaised } from "react-icons/fa";
import cartServices from '../../../Services/cartServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';


const DeleteExperiencePopup = ({ onDeleteshow, closeDeleteModal, setErrorMessage, setSuccessMessage, onDeleteSuccess }) => {
  
  const { dispatch } = useCart();
  const [isLoading, setIsLoading] = useState(null);

  const confirmDelete = async () => {
    if (onDeleteshow !== null && onDeleteshow !== "") {
      try {
        closeDeleteModal();
        setIsLoading(true);
        const response = await cartServices.deleteExperiencefromCart(onDeleteshow);
        
        if (response.statusCode === 200) {
          if (response.data !== null && response.data[0] != null && response.data[0].isRemoved) {
              setSuccessMessage(response.message);
              dispatch({ type: 'SET_CART_COUNT', payload: response.data[0].totalCartItems });
              onDeleteSuccess(true);
          } else {
            setErrorMessage(response.message);
          }
        } else {
          setErrorMessage(response.message);
        }
      } catch (error) {
        console.error('API Error:', error);
        setErrorMessage('An error occurred.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClose = () => {
    closeDeleteModal();
  };


  return (
    
    <React.Fragment>
    {isLoading ? (
      <LoadingOverlay />
      ) : (
    <Modal show={onDeleteshow !== null} onHide={handleClose} className={cartStyles.DeleteExperienceModal}>
      
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <Row className="d-flex justify-content-center align-items-center">
            <FaFistRaised className={cartStyles.fafaDelete} />
        </Row>
        <br/>
        <Row>
            <br/>
              <h4 className={`mb-5 ${cartStyles.DeleteHeader}`}>Are you sure you want to remove the selected workshop..?</h4>
            <br/>
            
        </Row>
        <Row>
            <Button  onClick={confirmDelete} className={`mb-2 w-100 ${cartStyles.SubmitBtnStyle}`}>
              Yes - Remove
            </Button>
            <Button onClick={handleClose} className={`mb-2 w-100 ${cartStyles.NoItemBtn}`}>
            No
            </Button>
        </Row>
      </Modal.Body>
    </Modal>

    )}
    </React.Fragment>
  );
};


export default DeleteExperiencePopup;
