// ExperienceContext.js
import React, { createContext, useState, useEffect } from 'react';

const ExperienceContext = createContext();

const ExperienceProvider = ({ children }) => {
  const [experienceData, setExperienceData] = useState(null);

  useEffect(() => {
    // Save experience data to localStorage whenever it changes
    if (experienceData !== null) {
      localStorage.setItem('experienceData', JSON.stringify(experienceData));
    }
  }, [experienceData]);


  return (
    <ExperienceContext.Provider value={{ experienceData, setExperienceData }}>
      {children}
    </ExperienceContext.Provider>
  );
};

export { ExperienceContext, ExperienceProvider };
