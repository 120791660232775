import { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import { Container, Row, Button, Modal, Col, Table, Image } from 'react-bootstrap';
import styles from './crp.module.css';
import CopyUrlButton from '../BaseComponents/CopyUrlButton';
import { FaCopy } from 'react-icons/fa';
import { FcInvite, FcCurrencyExchange } from "react-icons/fc";
import cartServices from '../../Services/cartServices';


const ActivityDashboard = () => {

    let refferalCode = null;
    // let isStripe = false;
    const storedRefferalCode = localStorage.getItem('userRefferalCode');
    if (storedRefferalCode && storedRefferalCode !== 'undefined') {
      refferalCode = storedRefferalCode;
    }
    // if(localStorage.getItem('stripeAccountExists')) {
    //   isStripe = localStorage.getItem('stripeAccountExists');
    // }

    const [showModal, setShowModal] = useState(false);
    // Functions to handle modal visibility
    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);
    const [isLoading, setIsLoading] = useState(false);
    const [totalEarnings, setTotalEarnings] = useState('');
    const [totalInvites, setTotalInvites] = useState('');
    const [referralTrackingDetails, setReferralTrackingDetails] = useState('');

    useEffect(() => {
        const fetchUserReferralActivity = async () => {
          try {
            setIsLoading(true);
            
            const response = await cartServices.fetchReferralTrackingDetails();
        
            // console.log('Referral Tracking Details:', response.data);
            if (response !== null && response.statusCode === 200 && response.data !== null && response.data.length > 0) {
                setTotalEarnings(response.data[0].totalEarning);
                setTotalInvites(response.data[0].totalInvitesAccepted);
                setReferralTrackingDetails(response.data[0].trackedInvitations);
            }
          } catch (error) {
            console.error('API Error:', error);
          } finally {
            setIsLoading(false);
          }
        };
      
        fetchUserReferralActivity();
      }, []);



  return (
    <Container fluid className='minPadding'>
        <Row>

            <div className={`boxBody ${styles.crprogramDiv}`}>
                <Row>
                    <div className={styles.ShareOptionDiv}>
                        <div className={styles.SocialMediaIconsDiv}>
                            <h2 className={styles.headerTextTemplate}>Your Activities</h2>
                            <label className={styles.ActivityLabelTextStyle}>Allow users to monitor their referral earnings and activities.</label>
                        </div>
                        <div className={styles.SocialMediaIconsDiv}>
                            <Button className={styles.invitewithMailBtn} onClick={handleModalOpen} > Invite </Button>

                                <Modal show={showModal} onHide={handleModalClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title className={styles.shareCopyText}>Copy your invitation link to share</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="d-flex flex-column align-items-center justify-content-center text-center">
                                        <button
                                            type="button"
                                            className={`${styles.URLlinkshow} btn btn-link`}
                                            onClick={() => {
                                                navigator.clipboard.writeText(`${window.location.origin}/signup/mandatory-details?ref=${refferalCode}`);
                                                alert('URL copied to clipboard!');
                                            }}
                                            >
                                            {`${window.location.origin}/signup/mandatory-details?ref=${refferalCode}`}
                                        </button>
                                        <button className={styles.ClickCopyBtn}>
                                            <FaCopy className="ml-2" />
                                            <CopyUrlButton name="Copy" url={`${window.location.origin}/signup/mandatory-details?ref=${refferalCode}`} />
                                        </button>
                                    </Modal.Body>
                                </Modal>

                        </div>
                    </div>
                </Row>

                {isLoading ? (
                    <div className='BeatLoaderContainerBox'>
                        <BeatLoader color="#2E637D" loading={true} height={10} width={200} />
                        <label>Loading...</label>
                    </div>
                ) : (
                    <Row>
                        <Row className='mt-2 mb-2'>
                            <Col lg={4} xs={4} className={styles.tabsPadding}>
                                <div className={styles.ItemDataBox}>
                                    <div className={styles.TitleIcondiv}>
                                        <h2 className={styles.itemlabelText}>Total Earning</h2>
                                        <div className={styles.IconfaStyles}>
                                            <FcCurrencyExchange />
                                        </div>
                                    </div>
                                    <div className={styles.ItemCountdiv}>
                                    <label className={styles.ItemDataCount}>{totalEarnings || 0}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} xs={4} className={styles.tabsPadding}>
                                <div className={styles.ItemDataBox}>
                                    <div className={styles.TitleIcondiv}>
                                        <h2 className={styles.itemlabelText}>Total Invites Accepted</h2>
                                        <div className={styles.IconfaStyles}>
                                            <FcInvite />
                                        </div>
                                    </div>
                                    <div className={styles.ItemCountdiv}>
                                        <label className={styles.ItemDataCount}>{totalInvites || 0}</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-2 mb-2'>
                            <h2 className={styles.headerTextTemplate}>Referral Tracking</h2>
                            <label className={styles.ActivityLabelTextStyle}>List of Hosts & users with their status.</label>
                            
                            <Col lg={12} xs={12} className={`mt-2 mb-2 ${styles.ActivityTableColumn}`}>

                            {referralTrackingDetails?.length > 0 ? (
                                <Table striped>
                                    <thead>
                                        <tr className={styles.tablethLabel}>
                                            <th>Invite </th>
                                            <th>User Action </th>
                                            <th>Date of Signup/Buy </th>
                                            <th>Details </th>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.tablebodySection}>
                                        {referralTrackingDetails.map((invitation, index) => (
                                            <tr key={invitation.userId}>
                                                <td>
                                                    <div className={styles.userInviteDiv}>
                                                        <Image src={invitation.image_url ? invitation.image_url : "/Content/Images/empty_user.jpg"} className={styles.inviteUserImg} roundedCircle alt="Profile" />
                                                        <div className={styles.userinviteData}>
                                                            <h3 className={styles.inviteusername}>{invitation.name}</h3>
                                                            <label className={styles.inviteuserdesc}>{invitation.bio}</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><label className={styles.tdLabel}>{invitation.referralType}</label></td>
                                                <td><label className={styles.tdLabel}>{new Date(invitation.dateCreated).toLocaleDateString()}</label></td>
                                                <td>
                                                    <label className={styles.viewLabelClick}>
                                                       View
                                                    </label>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <div className={`${styles.sharewithSocialText}`}>No tracked invitations found.</div>
                            )}
                            </Col>

                        </Row>
                    </Row>
                )} 
            </div>

        </Row>
    </Container>            
  );
};

export default ActivityDashboard;
