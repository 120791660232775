import { Container, Image, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './SellerStyle.module.css';

const EducationPage = () => {

    const educationData = [
        { educationId: 'education_1', title: 'How to design an engaging online workshop', content: '/Content/Images/Education/ed-img-1.jpg',
         desc: 'There is a lot to consider when you are planning your workshop to be delivered through the Hapifyr platform. The best workshops are targeted at a specific audience, focused on a single learning outcome, and centered around creating something unique that the user will want to boast about to their friends and colleagues.',
        descObj:{head:'Section 1: The Basics of Video Equipment', desc:'Before diving into specific products, its important to understand the basic equipment needed for video production. This typically includes a camera, microphone, lighting, and a tripod. Each piece plays a crucial role in ensuring the quality of your videos.'} },
        { educationId: 'education_2', title: 'How to master the art of storytelling in your videos', content: '/Content/Images/Education/ed-img-2.jpg' },
        { educationId: 'education_3', title: 'Budget-friendly video equipment', content: '/Content/Images/Education/ed-img-3.jpg' },
        { educationId: 'education_4', title: 'What`s your niche?', content: '/Content/Images/Education/ed-img-4.jpg' },
        { educationId: 'education_5', title: 'Video-editing:  basic skills', subTitle: 'and features you`ll find on hapifyr to give you a hand!', content: '/Content/Images/Education/ed-img-5.jpg' },
        { educationId: 'education_6', title: 'Tips for shooting high-quality videos with your smartphone', content: '/Content/Images/Education/ed-img-6.jpg' },
        { educationId: 'education_7', title: 'Tips for shooting high-quality videos with your smartphone', content: '/Content/Images/Education/ed-img-7.jpg' },
        { educationId: 'education_8', title: 'Tips for shooting high-quality videos with your smartphone', content: '/Content/Images/Education/ed-img-8.jpg' },
    ];

    return (
        <Container>
                {educationData.map(item => (
                    <Row className={`mb-4 ${styles.EducationImageSection}`} key={item.educationId}>
                        <Link to={`/education-details/${encodeURIComponent(JSON.stringify(item))}`}>
                            <Image src={item.content} alt={`education Theme`}
                                width='100%' height='100%' style={{ borderRadius: '6px' }} />
                        </Link>
                    </Row>
                ))}
        </Container>

    );
};

export default EducationPage;
