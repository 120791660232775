import React, { useState, useEffect } from 'react';
import { Card, Container, Image, Row, Badge } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { BeatLoader } from 'react-spinners';
import styles from './SellerStyle.module.css';
import Tooltip from '../BaseComponents/Tooltip';
import experienceServices from '../../Services/experienceServices';

const UnpublishedWorkshops = () => {

    const [userProfileData, setUserProfileData] = useState(null);
    const [unpublishedWorkshops, setUnpublishedWorkshops] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchUnpublishedWorkshops() {
            try {
                setIsLoading(true);
                const response = await experienceServices.fetchUnpublishedWorkshops();
                if (response && response.statusCode === 200 && response.data) {
                    setUnpublishedWorkshops(response.data);
                }
            } catch (error) {
                console.error("API Error:", error);
            } finally {
                setIsLoading(false);
            }
        }

        const profileData = localStorage.getItem('userProfileData');
        if (profileData) {
          setUserProfileData(JSON.parse(profileData));
        }

        fetchUnpublishedWorkshops();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <Container fluid className='minPadding'>
            {isLoading ? (
                <div className={styles.feedsContainerBox}>
                    <BeatLoader color="#2E637D" loading={true} height={10} width={200} />
                    <label> Loading... </label>
                </div>
            ) : (
                unpublishedWorkshops && unpublishedWorkshops.length > 0 ? (
                    <>
                        <Row>
                            <h2 className={styles.headerText}>
                                Unpublished Workshops
                                <Badge pill bg="warning" text="dark" style={{ marginLeft: '15px' }}>
                                    {unpublishedWorkshops.length}
                                </Badge>
                            </h2>
                        </Row>
                        <Row className={`mb-2 ${styles.userWorkshopsListTable} RightPadding`}>
                            {unpublishedWorkshops.map((content) => (
                                <div key={content.id} className='text-end'>
                                <Card className={`${styles.FeedBody}`} >
                                    <Container className='noPadding'>
                                        <div className={`${styles.feedContainerdiv}`} key={content.id}>
                                            <Link to={`/experiencehub/experience/${content.id}`}>
                                                <Image 
                                                    src={content.icon || '/Content/Images/General/no_thumbnail.png'} 
                                                    alt="feed_thumbnail" 
                                                    fluid
                                                    roundedCircle 
                                                    className={styles.feedThumbnail} 
                                                />
                                            </Link>
                                            <div className={styles.feedContent}>
                                                <Image src="/Content/Images/icons/document-text.png" className={styles.feedDescTextImgSize} />
                                                &nbsp;
                                                <p className={styles.feedContentTextColorFont}>{content.desc}</p>
                                            </div>
                                            <div className={styles.feedContentSide}>
                                                <div className={styles.feedContentdiv}>
                                                    <div className='followUsersetupDiv mb-2'>
                                                        {userProfileData && (
                                                        <Link to={`/host/republish-workshop/${content.id}`}>
                                                            <div className={styles.feedIconwithCountsdiv}>
                                                                <div className={styles.feedIconFollowAdddiv}>
                                                                    <Image
                                                                        src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'} 
                                                                        className={styles.feedIconFollowImg} 
                                                                    />
                                                                </div>
                                                            </div>
                                                         </Link>
                                                        )}
                                                    </div>
                                                    <div className={styles.feedIconwithCountsdiv}>
                                                        <Tooltip content="Rating">
                                                            <Image src="/Content/Images/icons/icons-rating.png" className={styles.feedIconImg} />
                                                        </Tooltip>
                                                        <label className={styles.feedUserContentFont}>{content.ratings ? (content.ratings).toFixed(0) : 0}</label>
                                                    </div>
                                                    <div className={styles.feedIconwithCountsdiv}>
                                                        <Tooltip content="Sales">
                                                            <Image src="/Content/Images/icons/totalsalesCount.png" className={styles.feedIconImg} />
                                                        </Tooltip>
                                                        <label className={styles.feedUserContentFont}>{content.purchasedCount ?? 0}</label>
                                                    </div>
                                                    <div className={styles.feedIconwithCountsdiv}>
                                                        <Tooltip content="Price">
                                                            <Image src="/Content/Images/icons/icons-dollar-coin.png" className={styles.feedIconImg} />
                                                        </Tooltip>
                                                        <label className={styles.feedUserContentFont}>{content.currency ?? '$'}&nbsp;{content.price ?? 0}</label>
                                                        <label className={styles.feedUserContentFont}>&nbsp;</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Container>
                                </Card>
                                <Link to={`/host/unpublished-workshop/${content.id}`} className='text-end'>
                                <Badge pill bg="primary" className={styles.editBadgeClick}>
                                    Edit / Publish
                                </Badge>
                                </Link>
                                </div>
                            ))}
                        </Row>
                    </>
                ) : (
                    <div className={styles.feedsContainerBox}>
                        You have not created any unpublished workshops.
                    </div>
                )
            )}
        </Container>
    );
};

export default UnpublishedWorkshops;
