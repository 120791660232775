import { Row, Col, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import genStyles from './general.module.css';


const AboutUs = () => {

    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1); // Go back to the previous page
    };

  return (
      <main>
        <Row className={genStyles.aboutUsbox}>
            <Row className='mb-4'>
                <Link onClick={handleClose} style={{textDecoration: 'none', textAlign: 'end'}}>
                    <label className={genStyles.CloseLabel}>Close</label>
                </Link>
                <h2 className={genStyles.AboutHeader}>About Us</h2>
            </Row>
            <Row>
            <Col md={5}>
                <p className={genStyles.AboutUsText}> <span className={genStyles.hapifyrText}> &nbsp;At Hapifyr </span> &nbsp;  we are creating an online, experience platform, where anyone in our community can host an online workshop, share their passion with like-minded people, and earn an income.
                    <br />
                    We will create a social movement, connecting people with each other and building communities with shared interests, hobbies and passions.
                    <br />
                    Everyone can be creative, and has interests they can enjoy with others. At Hapifyr we will create a place where happiness is ignited when people enjoy their interests together.
                    <br />
                </p>
            </Col>
            <Col md={2}></Col>
            <Col md={4}>
                <div className={genStyles.Aboutlogodiv}>
                <Link to="/" style={{textDecoration: 'none'}}>
                    <Image src='/Content/Images/General/About_logo.png'
                    className={`${genStyles.logoImage}`}
                    alt="logo"
                    width={500}
                    height={500} />
                </Link>
                </div>
            </Col>
            <Col md={1}></Col>
            </Row>
        </Row>
      </main>
  );
}

export default AboutUs;
