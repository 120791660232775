import starStyles from './base.module.css';



const Star = ({ marked, starId }) => {
    return (
      <span data-star-id={starId} className={starStyles.star} role="button">
        {marked ? '\u2605' : '\u2606'}
      </span>
    );
  };

export default Star;