import { useContext, useState } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import styles from './followers.module.css';
import AuthContext from '../Context/AuthContext';

const AddNewConnections = ({ type }) => {
  const { setIsLoading } = useContext(AuthContext);

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);

  const allSuggestions = ['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry', 'Fig', 'Grape'];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Filter suggestions based on the input value
    const filteredSuggestions = allSuggestions.filter(
      (suggestion) =>
        suggestion.toLowerCase().includes(value.toLowerCase()) &&
        !selectedSuggestions.includes(suggestion) // Exclude already selected suggestions
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    if (!selectedSuggestions.includes(suggestion)) {
      setSelectedSuggestions([...selectedSuggestions, suggestion]);
    }
    setInputValue(''); // Clear input value after adding suggestion
    setSuggestions([]); // Clear suggestions after selection
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && inputValue.trim() && !selectedSuggestions.includes(inputValue.trim())) {
      setSelectedSuggestions([...selectedSuggestions, inputValue.trim()]);
      setInputValue('');
    }
  };

  const removeSelectedSuggestion = (index) => {
    setSelectedSuggestions(selectedSuggestions.filter((_, i) => i !== index));
  };

  return (
    <Container fluid>
      <Row className={styles.addConnectionsSection}>
        <Row>
          <h2 className="mainHeaderStyle">Add New</h2>
          <label className="mainHeaderLabelStyle">You can add new connections here.</label>
        </Row>

        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="mt-4 mb-2">
          <Form>
              <Form.Group className="mb-3" controlId="Username">
                <Form.Label className={styles.formSearchLabel}>Enter Username</Form.Label>
                <div className={styles.inputContainer}>
                  {selectedSuggestions.map((suggestion, index) => (
                    <span
                      key={index}
                      className={styles.suggestionTag}
                      onClick={() => removeSelectedSuggestion(index)}
                    >
                      {suggestion} ✕
                    </span>
                  ))}
                  <Form.Control
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Enter email id/username ..."
                    className={styles.input}
                  />
                </div>
              </Form.Group>
            </Form>
          </Col>

          <Col lg={12} md={12} sm={12} xs={12}>
            <Form.Label className={styles.formSearchLabel}>Suggestions</Form.Label>
            <Row className={styles.suggestionsdiv}>
              {setIsLoading ? (
                suggestions.length > 0 ? (
                  <div className={styles.suggestionsContainer}>
                    {suggestions.map((suggestion, index) => (
                      <span
                        key={index}
                        className={styles.suggestionItem}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </span>
                    ))}
                  </div>
                ) : (
                  <p className={styles.noSuggestions}>No suggestions found.</p>
                )
              ) : (
                <p className={styles.noSuggestions}>Loading suggestions...</p>
              )}
            </Row>
            <Row className={styles.addConnectionBtnRow}>
              <Col xs="auto">
                <Button className={styles.addConnectionBtn}>Add</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default AddNewConnections;
