import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from '../../Navbar/Header';
import EditUnpublishedWorkshop from './EditUnpublishedWorkshop';
import RepublishWorkshop from './RepublishWorkshop';
import ScrollToTop from '../../BaseComponents/ScrollToTop';
import RepublishSuccess from './RepublishSuccess';


const RepublishContents = () => {

    const [loginStatus] = useState('loggedIn');
   // Using useParams to get the route parameters
   const { republishStatus, republishId } = useParams();
   const [republishData, setRepublishData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [republishStatus]);

  const previewUpdatedWorkshop = (data) => {
    setRepublishData(data);
  };

  return (
    <Container fluid className="republishContainerdiv noPadding">
      <ScrollToTop />

      <Header loginStatus={loginStatus} />
      
      {(republishStatus === 'unpublished-workshop' || republishStatus === 'update-workshop') && <EditUnpublishedWorkshop type={republishStatus} id={republishId} previewUpdatedWorkshop={previewUpdatedWorkshop} />}

      {(republishStatus === 'publish-workshop' || republishStatus === 'republish-workshop') && (
          <RepublishWorkshop type={republishStatus} id={republishId} republishForm={republishData} />
      )}

      {republishStatus === 'workshop-success' && <RepublishSuccess />}


    </Container>
  );
}

export default RepublishContents;
