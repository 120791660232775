import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button, Image, InputGroup } from 'react-bootstrap';
import loginStyles from './signin.module.css';
import loginApiServices from '../../../Services/loginApiServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SmallFooter from '../../BaseComponents/SmallFooter';


const SetNewPassword = ({ onVerificationStatus }) => {
  
  const navigate = useNavigate();
 
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  // const [rememberMe, setRememberMe] = useState('');
  const [errors, setErrors] = useState({
    emailid: "",
  });

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const validateResetPassword = async (e) => {
    e.preventDefault();
    
    const newErrors = { newPassword: '', reEnterNewPassword: ''};

    const queryParams = new URLSearchParams(window.location.search);
    const otpCode = queryParams.get("otpCode");
    const otpId = queryParams.get("otpId");
    const emailid = queryParams.get("emailid");

    if (!emailid) {
      newErrors.newPassword = 'Please verify your Email ID first';
      setErrors(newErrors);
      return;
    }
    if (!otpCode) {
      newErrors.newPassword = 'Please provide me OTP code';
      setErrors(newErrors);
      return;
    }
    if (!otpId) {
      newErrors.newPassword = 'Please provide me OTP Id';
      setErrors(newErrors);
      return;
    }

    if (!newPassword) {
      newErrors.newPassword = 'Please enter new password';
      setErrors(newErrors);
      return;
    } else if (newPassword.length <= 6){
      newErrors.newPassword = "Please enter valid Password, Password should be at least 6 characters long.";
      setErrors(newErrors);
      return;
    }

    if(!verifyPassword) {
      newErrors.reEnterNewPassword = 'Please re enter your password';
      setErrors(newErrors);
      return;
    }  else if (verifyPassword.length <= 6){
      newErrors.reEnterNewPassword = "Please enter valid Password, Password should be at least 6 characters long.";
      setErrors(newErrors);
      return;
    }

    if( newPassword !== verifyPassword ){
      newErrors.reEnterNewPassword = "Passwords doesn't match, please enter correctly";
      setErrors(newErrors);
      return;
    }

    // if (!rememberMe) {
    //     newErrors.rememberMeError = "Please check to continue.";
    //     setErrors(newErrors);
    //     return;
    // }

    // Clear error message on successful login attempt
    setErrorMessage("");
    setIsLoading(true);
    
    try {
      const response = await loginApiServices.validateResetPassword(otpId,otpCode,emailid,newPassword);
      //console.log("API Response:", response);
  
      if (response.statusCode === 200) {
       navigate('/signin/password-success');
       onVerificationStatus("password-success");
        
      } else {
        setErrorMessage(response.message);
      }

    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }

  };
  

  
  return (
    <Container fluid className={`noPadding`}>
        <Row className={`${loginStyles.signBackground}`}>
            <Row className={`mainPad d-flex align-items-center justify-content-between ${loginStyles.viewportBox}`}>
                <Col xs={12} lg={5} className={loginStyles.responsiveDisplay}>
                    <Row className='mt-5'>
                    <Image
                        src='/Content/Images/login_Image.png'
                        alt="login_banner"
                        className="loginBanner"

                    />
                    </Row>
                </Col>
                <Col xs={12} lg={2} className={loginStyles.responsiveDisplay}></Col>
                <Col xs={12} lg={5}>
                
                <div className={`${loginStyles.signinSection} ${loginStyles.signAlltop}`}>
                    <Row className='text-center'>
                                <Row>
                                    <Col>
                                    <Button className={`rounded-pill ${loginStyles.IconLogins}`}>
                                        <Image
                                            src='/Content/Images/Signin/icons_password.png'
                                            alt="login_icon"
                                            className=""
                                            width={30}
                                            height={30}
                                        />
                                    </Button>
                                    </Col>
                                </Row>

                    <label className={`styleHeader`}>Create New Password</label>
                    </Row>
                    
                    
                <Row>
                    
                    <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
                    <br/>
                    <Form method="POST" onSubmit={validateResetPassword}>
                    
                    <Form.Group controlId="formNewPassword">
                    <Form.Label className='mb-1'>New Password*</Form.Label>
                                    <InputGroup>
                                        <Form.Control style={{borderRight: 'none'}}
                                            type={passwordVisible ? 'text' : 'password'}
                                            placeholder=""
                                            name="password"
                                            id="newPassword"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            />
                                        <Button variant="link" className={loginStyles.eyeIcon} onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                                        </Button>
                                    </InputGroup>
                        <Form.Text className="text-danger">{errors.newPassword}</Form.Text>
                        </Form.Group>
                        <br />
                        <Form.Group controlId="formReenterNewPassword">
                        <Form.Label className='mb-1'>Re-enter Password*</Form.Label>
                                    <InputGroup>
                                        <Form.Control style={{borderRight: 'none'}}
                                            type={passwordVisible ? 'text' : 'password'}
                                            placeholder=""
                                            name="password"
                                            value={verifyPassword}
                                            onChange={(e) => setVerifyPassword(e.target.value)}
                                            />
                                        <Button variant="link" className={loginStyles.eyeIcon} onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <FaEye /> : <FaEyeSlash /> }
                                        </Button>
                                    </InputGroup>
                        <Form.Text className="text-danger">{errors.reEnterNewPassword}</Form.Text>
                    </Form.Group>
                    <br />
                    {/* <Form.Group controlId="formCheckbox">
                      <Form.Check
                          type="checkbox"
                          id="rememberMe"
                          label=" Remember Me"
                          checked={rememberMe}
                          name="rememberMe"
                          onChange={(e) => setRememberMe(e.target.value)}
                      />
                      <Form.Text className="text-danger">{errors.rememberMeError}</Form.Text>
                    </Form.Group> */}

                    <br/>
                    
                    <br/>
                    <Row>
                        <Button className={loginStyles.btnloginsetup} type="submit">Save</Button>
                    </Row>

                    {isLoading && <LoadingOverlay />}
                    
                    <br/>
                    
                    </Form>
                  </Row>
                </div>

                </Col>
                <br />

            </Row>
        </Row>
        
      <SmallFooter />
    </Container>
  );
};

export default SetNewPassword;
