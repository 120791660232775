import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SendBird from '../SendBird';
import EducationDetailsPage from './EducationDetailsPage';


const ViewEducationPage = ({ educationId }) => {


  return (

      <main className="MainpartSetup">
        <Row className={`mainPad`}>

          <Col md={9} className='minPadding'>
            <EducationDetailsPage educationId={educationId}  />
          </Col>
          
          <Col md={3}>
            <SendBird />
          </Col>
        </Row>
      </main>

  );
}

export default ViewEducationPage;
