import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './base.module.css';


const SmallFooter = () => {
  
    
    return (
        <Container fluid>
            <Row className={`mainPad ${styles.footerSec}`}>
                <Col xs={12} lg="auto">
                    <Link to="/terms-and-conditions" className={styles.LinkClicks}>Terms & Conditions</Link> 
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="/privacy-policy" className={styles.LinkClicks}>Privacy Policy</Link>
                </Col>
                <Col xs={12} lg="auto">
                    <p className={styles.LinkClicks} >©2024 Hapifyr Pty Ltd. All rights reserved.</p>
                </Col>
            </Row>
        </Container>
  );
};

export default SmallFooter;
