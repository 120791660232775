import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Header from './Navbar/Header';
import ProductOverview from './Buy/ProductOverview';
import { CartProvider } from './Navbar/CartContext';


const ProductDetails = () => {

  const [loginStatus] = useState('loggedIn');
  const { feedId } = useParams();
  // Get query parameters
  const [searchParams] = useSearchParams();
  // Extract the optional 'ref' query parameter
  const ref = searchParams.get('ref') || null;

  // const [cartCount, setCartCount] = useState(""); // State to store the cart count
  // const handleUpdateCartCount = (count) => {
  //   setCartCount(count); // Update the cart count in ProductDetails component
  // };

  return (
    <CartProvider>

      <div className="ProductDetailsDiv stickySidebar">
        <Header loginStatus={loginStatus} />
        <ProductOverview feedId={feedId} refferalId={ref} />
      </div>
    
    </CartProvider>
  );
}

export default ProductDetails;
