import { useState } from 'react';
import Header from './Navbar/Header';
import { useParams } from 'react-router-dom';
import AccountSuccess from './SellerDashboard/ExperienceStudio/AccountSuccess';
import AccountFailure from './SellerDashboard/ExperienceStudio/AccountFailure';


const AccountStatus = () => {
  
    const [loginStatus] = useState('loggedIn');
    const { accountStatus } = useParams();

  return (
    <div className="sellerBankSection">
      <Header loginStatus={loginStatus} />

      {accountStatus === 'account-success' && <AccountSuccess />}
      {accountStatus === 'account-failed' && <AccountFailure />}

    </div>

  );
}

export default AccountStatus;

