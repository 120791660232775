import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Badge, Tab, Tabs, Button } from 'react-bootstrap';
import styles from './followers.module.css';
import FollowersList from './FollowersList';
import FollowingsList from './FollowingsList';
import BlockedUsersList from './BlockedUsersList';
import AuthContext from '../Context/AuthContext';


const MyFollowers = ({ type }) => {
    
    // console.log("type::: ", type);
    const [activeTab, setActiveTab] = useState(type);
    const { setIsLoading } = useContext(AuthContext);
    const [followersList, setFollowersList] = useState([]);
    const [followingsList, setFollowingsList] = useState([]);
    const [blockedList, setBlockedList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                let followersResponse = null;
                let followingsResponse = null;
                let blockedResponse = null;
                // Fetch seller details
                // const sellerDetailsResponse = await experienceServices.fetchSellerDetails(sellerId);
                if (followersResponse !== null && followersResponse.statusCode === 200 && followersResponse.data !== null && followersResponse.data !== "") {
                    setFollowersList(followersResponse.data[0]);
                }
    
                // Fetch seller workshops
                // const sellerWorkshopsResponse = await experienceServices.fetchSellerWorkshops(sellerId, 'workshops', 1);
                if (followingsResponse !== null && followingsResponse.statusCode === 200 && followingsResponse.data !== null && followingsResponse.data !== "") {
                    setFollowingsList(followingsResponse.data);
                }
    
                // Fetch seller boasts
                // const sellerBoastsResponse = await experienceServices.fetchSellerBoasts(sellerId, 'boasts', 1);
                if (blockedResponse !== null && blockedResponse.statusCode === 200 && blockedResponse.data !== null && blockedResponse.data !== "") {
                    setBlockedList(blockedResponse.data.boasts);
                }
    
            } catch (error) {
                console.error("API Error:", error);
            } finally {
                setIsLoading(false);
            }
        }
    
        fetchData();
        
    }, [setIsLoading]);


    return (
        <Container fluid>

            <Row>
                <h2 className={`mainHeaderStyle`}>My Followers
                    <Badge pill bg="warning" text="dark" className="mainHeaderBadgeStyle">0</Badge>
                </h2>
            </Row>

            <Row className={`minPadding ${styles.follwersSection}`}>

                <Col lg={12} md={12} sm={12} xs={12} className={`${styles.follwersBlocks}`}>
                    <div>
                        <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className={styles.customTabHeaders}
                        >
                            <Tab eventKey="followers"
                                title={<span className={`${styles.customTabHeader} 
                                ${activeTab === 'followers' ? styles.customTabClickActive : ''}`}> Followers </span> } 
                            />
                            <Tab eventKey="followings"
                                title={<span className={`${styles.customTabHeader}
                                ${activeTab === 'followings' ? styles.customTabClickActive : ''}`}> Followings </span> } 
                            />
                            <Tab eventKey="blockedUsers"
                                title={<span className={`${styles.customTabHeader} 
                                ${activeTab === 'blockedUsers' ? styles.customTabClickActive : ''}`}> Blocked </span> } 
                            />
                        </Tabs>
                    </div>
                    <div>
                        <Link to="/followers/add-connections">
                            <Button>Add New</Button>
                        </Link>
                    </div>
                </Col>
            
                <Col lg={12} md={12} sm={12} xs={12} className="mt-3">
                   
                    {activeTab === 'followers' && <FollowersList followers={followersList} />}
                    {activeTab === 'followings' && <FollowingsList followings={followingsList} />}
                    {activeTab === 'blockedUsers' && <BlockedUsersList blockedUsers={blockedList} />}

                </Col>

            </Row>

        </Container>
    );
};

export default MyFollowers;
