import React, { useState } from 'react';
import Header from './Navbar/Header';
import HubDashboardDetails from './Engage/HubDashboardDetails';


const ExperienceHubDashboard = () => {

  const [loginStatus] = useState('loggedIn');

  return (

    <div className="ExperienceHubDashboardDiv">
      
      <Header loginStatus={loginStatus} />
      
      <HubDashboardDetails />
       
      <footer/>
    </div>

  );
}

export default ExperienceHubDashboard;
