import { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import followStyles from './base.module.css'; 
import userServices from '../../Services/userServices';



const FollowButton = ({ followUser, followStatus, contentOwner, followOptimizeHandler }) => {

  
  const [isFollowing, setIsFollowing] = useState(followStatus);
  const [isContentOwner, setIsContentOwner] = useState(contentOwner);

  useEffect(() => {
    setIsFollowing(followStatus);
  }, [followStatus]);

  useEffect(() => {
    setIsContentOwner(contentOwner);
  }, [contentOwner]);

    
  const FollowHandler = async(userId,isFollowing) => {
        
        if(userId === null ||userId === undefined || userId === ""){
          console.log("Please provide the UserId first.");
          return;
        }
    
        if(isFollowing){
          try {
            const response = await userServices.followUser(userId);
            setIsFollowing(isFollowing);
            // console.log("FollowHandler:", response);
            if(response.statusCode === 200){
              followOptimizeHandler(isFollowing);
            }

          } catch (error) {
            console.error("API Error:", error);
          }
      
          } else {
            try {
              const response = await userServices.unfollowUser(userId,true);
              setIsFollowing(isFollowing);
              // console.log("FollowHandler:", response)
              if(response.statusCode === 200){
                followOptimizeHandler(isFollowing);
              }
              
            } catch (error) {
              console.error("API Error:", error);
            }
          }
  };


  return (
    <Container>
      {!isContentOwner && (
        isFollowing !== undefined && (
          <Button className={followStyles.followButtonStyles}
            onClick={() => FollowHandler(followUser, !followStatus)}
          >
            {isFollowing ? 'Following' : 'Follow'}
          </Button>
        )
      )}
    </Container>
    );
};

export default FollowButton;
