import { Row, Container } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import { BsFillHddRackFill  } from "react-icons/bs";
import styles from './base.module.css';


const EngageActions = () => {


  return (
    <Container className='noPadding stickySidebarOverview'>
    <Row className={`d-flex flex-column align-items-center ${styles.EngageActionsBox} boxBody`}>
       <br/>
       <h5 className={`mb-3 ${styles.ActionHeader}`}>Actions</h5>
       
        <Row>
            <Row className={`mb-2 ${styles.ActionClickRows} ${styles.activeAction}`}>
                <button className={`btn ${styles.ActionClickBtns} ${styles.activeAction}`}>
                <BsFillHddRackFill  />&nbsp;&nbsp;Dashboard
                </button>
            </Row>
            <br/>
            {/* <Row className={`mb-2 ${styles.ActionClickRows}`}>
                <button className={`btn disabled ${styles.ActionClickBtns}`}>
                <BsBook  />&nbsp;&nbsp;Completed Experience
                </button>
            </Row> */}
            
        </Row>
            <br/>


    </Row>
    </Container>
  );
};

export default EngageActions;
