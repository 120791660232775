import { useState, useEffect } from 'react';
import { Row, Col, Button, Container, Form } from 'react-bootstrap';
import profileStyles from './Profile.module.css';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import userServices from '../../Services/userServices';
import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { allCountries } from 'country-telephone-data';


const ContactDetails = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [user, setUser] = useState({
    country: "AU",
    phoneNumber: "",
  });

  const [errors, setErrors] = useState({
    countryError: "",
    phoneNumberError: "",
  });

  const handleInputs = (value, name) => {
    if (name) {
      setUser({ ...user, [name]: value });
      setErrors({ ...errors, [name]: '' });
    }
    setErrorMessage("");
  };

  const getPhoneCodeForCountry = (countryValue) => {
    const country = allCountries.find((c) => c.iso2 === countryValue);
    return country ? country.dialCode : '';
  };

  const [selectedCountryData, setSelectedCountryData] = useState({
    flag: 'au',
    phoneCode: getPhoneCodeForCountry('AU'),
  });

  // for Select Countries
  
  // Have to register the languages you want to use
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);

  // Returns an object not a list
  const countryObj = countries.getNames("en", { select: "official" });
  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
      alpha2: key.toLowerCase(),
    };
  });

  const filterCountries = (input) => {
    return countryArr.filter(({ label }) =>
      label.toLowerCase().startsWith(input.toLowerCase())
    );
  };

  const handleKeyDown = (e) => {
    if (e.key.length === 1) {
      const filteredCountries = filterCountries(user.country + e.key);
      if (filteredCountries.length === 1) {
        selectCountryHandler(filteredCountries[0].value); // Update to call selectCountryHandler
        e.preventDefault();
      }
    }
  };

  const selectCountryHandler = (selectedCountry) => {
    const countryData = countryArr.find(country => country.value === selectedCountry);
    setUser({
      ...user,
      country: selectedCountry,
    });
    setSelectedCountryData({
      flag: countryData.alpha2,
      phoneCode: getPhoneCodeForCountry(selectedCountry),
    });
  };

  useEffect(() => {
    const profileData = localStorage.getItem('userProfileData');
    if (profileData) {
      const parsedData = JSON.parse(profileData);
      setUser({
        country: parsedData.country || '',
        phoneNumber: parsedData.phone || '',
      });
    }
  }, []);


  const updateBasicDetails = async (e) => {
    
    e.preventDefault();
    const { country, phoneNumber } = user;
    const newErrors = { countryError: "", phoneNumberErrorNumberError: "" };
    
    if (!country) {
      newErrors.countryError = "Please select Country.";
      setErrors(newErrors);
      return;
    }
    if (!phoneNumber) {
      newErrors.phoneNumberError = "Please enter Mobile number.";
      setErrors(newErrors);
      return;
    } else if (phoneNumber.length <= 9){
      newErrors.phoneNumberError = "Mobile number should be at least 9 digits and above.";
      setErrors(newErrors);
      return;
    }
    
    if (newErrors.countryError || newErrors.phoneNumberError) {
      setErrors(newErrors);
      return;
    }else {
      setErrors(newErrors);
    }
    
    setErrorMessage("");
    setIsLoading(true);
    
    try {

      const response = await userServices.updateUserProfileContact(country, phoneNumber);
      if (response.statusCode === 200) {
        const profileData = localStorage.getItem('userProfileData');
        if (profileData) {
            const parsedData = JSON.parse(profileData);
            parsedData.country = country;
            parsedData.phone = phoneNumber;
          localStorage.setItem('userProfileData', JSON.stringify(parsedData));
        }
        setSuccessMessage("Contact details updated successfully.");

        } else {
          setErrorMessage(response.message);
        }
      
    } catch (error) {
      console.error("API Error:", error);
      setErrorMessage("An error occurred while uploading the profile picture.");
    } finally {
      setIsLoading(false);
    }
  };


  return (
    
    <Container className='RightPadding'>
    <Row className={`d-flex flex-column align-items-center boxBody ${profileStyles.profileSectionVP}`}>

        <h5 className={`mb-3 mt-2 mainHeaderline`}>Contact Details </h5>
        
        <Row className='d-flex align-items-center justify-content-center'>
        
          {isLoading ? (
            <LoadingOverlay />
          ):(
            
            <Row className='minPadding'>
            
            <Form.Text className="text-danger" align="center">{errorMessage}</Form.Text>
            <Form.Text className="text-success" align="center">{successMessage}</Form.Text>
            
            <Form method="POST" onSubmit={updateBasicDetails}>
            <Row>
            <Col md={6}>

              <Form.Group className="mb-2" controlId="formCountry">
                    <Form.Label className={profileStyles.profileFormLabel}>Country*</Form.Label>
                    <Form.Select
                      value={user.country} // Use value instead of defaultValue
                      onChange={(e) => selectCountryHandler(e.target.value)}
                      onKeyDown={handleKeyDown}
                    >
                      {!!countryArr?.length && countryArr.map(({ label, value }) => (
                        <option key={value} value={value}>{label}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>

              <Form.Group className="mb-2" controlId="formPhone">
                <Form.Label className={profileStyles.profileFormLabel}>Phone Number*</Form.Label>
 <PhoneInput
      country={selectedCountryData.flag}
      value={user.phoneNumber}
      onChange={(value, e) => {
        handleInputs(value, 'phoneNumber');
      }}
      inputStyle={{ width: '100%' }}
      initialValue={selectedCountryData.phoneCode}
      onBlur={(e) => handleInputs(e.target.value, 'phoneNumber')}
    />
                <Form.Text className="text-danger">{errors.phoneNumberError}</Form.Text>
              </Form.Group>
   
            </Col>
            <Col md={6}></Col>
            </Row>
            <Row>
              <br /><br />
            </Row>

            <Row className='d-flex align-items-center justify-content-end'>
              <Col xs='auto'>
                <Button className='CancelButtonSetup'>Cancel</Button>
              </Col>
                &nbsp;&nbsp;
              <Col xs='auto'>
                <Button className='SubmitButtonSetup' type="submit" >Submit</Button>
              </Col>
            </Row>

            </Form>
            
            </Row>
        
          )}

        </Row>
        
    </Row>
    </Container>
  );
};

export default ContactDetails;
