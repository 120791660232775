import React from 'react';
import { Row, Col } from 'react-bootstrap';
import UserProfile from '../../BaseComponents/UserProfile';
import SendBird from '../../SendBird';
import SellerdbActions from '../SellerdbActions';
import SellersProfiePage from './SellersProfiePage';
import styles from './publicProfile.module.css';


const SellerPublicProfilePage = ({ sellerId }) => {

  
  return (
      <main className="MainpartSetup HomeViewportHt">
        <Row className={`mainPad stickySidebar`}>
          <Col md={3} xs={0} lg={3} className={`${styles.responsiveDisplay}`} >
            <UserProfile />
            <br />
            <SellerdbActions />
          </Col>
          
          <Col md={6} xs={12} lg={6} >
            <SellersProfiePage sellerId={sellerId} />
          </Col>
          
          <Col md={3} xs={0} lg={3} className={`${styles.responsiveDisplay}`}>
            <SendBird />
          </Col>
        </Row>
      </main>

  );
}

export default SellerPublicProfilePage;
