import { useEffect, useRef, useState } from 'react';
import { Row, Image } from 'react-bootstrap';
import styles from './followers.module.css'; // Adjust the path according to your project


const FollowingsList = ({ followings }) => {
  
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null); // Reference to detect outside clicks

    // Toggle menu visibility
    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Close the menu if clicked outside
    const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        }
    };

    // Add event listener for outside clicks
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <Row className={styles.followerCard}>
            {followings.length > 0 ? (
                followings.map((follower, index) => (
                <div key={followings.id} className={styles.followerInfodiv}>
                    
                    <div className={styles.userDataContainer}>
                        <Image
                        src={followings.image_url || '/Content/Images/empty_user.jpg'}
                        className={styles.inviteUserImg}
                        roundedCircle
                        alt={`Profile ${followings + 1}`}
                        />
                        <div className={styles.followerDataDiv}>
                            <h3 className={styles.inviteusername}>Name</h3>
                            <label className={styles.inviteuserdesc}>Description</label>
                        </div>
                    </div>
                    
                    <div className={styles.menuContainer} ref={menuRef}>
                        <button className={styles.threeDotButton} onClick={handleMenuToggle}>
                            &#x22EE;
                        </button>

                        {/* Dropdown menu */}
                        {isMenuOpen && (
                            <ul className={styles.menuDropdown}>
                                <li onClick={() => alert('Remove clicked')}>Remove</li>
                                <li onClick={() => alert('Block clicked')}>Block</li>
                            </ul>
                        )}
                    </div>

                </div>
                ))

            ) : (
                <p className={styles.noUsersFoundMsg}>No followings data found.</p>
            )}
        </Row>
  );
};

export default FollowingsList;
