import { useEffect } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loginStyles from './signin.module.css';
import SmallFooter from '../../BaseComponents/SmallFooter';
import { useNavigate } from 'react-router-dom';


const ResetPasswordSuccess = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/signin/user-login');
    }, 3000);
  }, [navigate]);


  return (
    <Container fluid className={`${loginStyles.signBackground}`}>

            <Row className={`mainPad d-flex align-items-center justify-content-center ${loginStyles.viewportBox} `}>
                
                    <Row className={`text-center`}>
                        <label className={`styleHeader`}>Your Password has been updated..!</label>
                            <Col xs={12} lg={12} className='mb-4 mt-1'>  
                                <Image
                                    src='/Content/Images/Signin/icons_update_done.png'
                                    alt="login_icon"
                                    className=""
                                    width={80}
                                    height={80}
                                    />
                            </Col>

                            <Link to='/signin/user-login'>
                              <Button size='lg' className={loginStyles.dashboardBtn}>
                                 Go to Login page
                              </Button>
                            </Link>

                    </Row>
            </Row>
        
      <SmallFooter />
    </Container>
  );
};

export default ResetPasswordSuccess;
