import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Image, Toast } from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './ExperienceStudio.module.css';
import { FaAngleDown } from "react-icons/fa";


const SuccessPublishPreview = ({ onPublishSuccess, previewForm }) => {
    
    const [showDescription, setShowDescription] = useState(true);
    const [showMaterial, setShowMaterial] = useState(true);
    const [experienceForm, setExperienceForm] = useState(previewForm);
    const [userProfileData, setUserProfileData] = useState(null);
  
    const toggleShowDescription = () => setShowDescription(!showDescription);
    const toggleShowMaterial = () => setShowMaterial(!showMaterial);
    
    useEffect(() => {
        // Retrieve user profile data from local storage
        const profileData = localStorage.getItem('userProfileData');
        if (profileData) {
            setUserProfileData(JSON.parse(profileData));
        }

        // Append data to experienceForm when previewExperienceForm changes
        setExperienceForm(prevForm => ({
            ...prevForm,
            ...previewForm,
        }));
    }, [previewForm]);


  return (
    <Container fluid className="mainPad">

    <Row className="mt-2 mb-2">
    <Col md={1} xs={0}></Col> 
    <Col md={10} xs={12}>
      <Row className={styles.ESWhiteBackdrop}>

        {onPublishSuccess && 
            <div className={`mb-3 mt-2 ${styles.successMessage}`} style={{ textAlign: "center", fontWeight: '600' }}>
                Your Workshop has been Published! &nbsp;&nbsp;
                <Link to="/seller/dashboard" className={styles.ExperienceDashBtn}> Go to Dashboard &nbsp; </Link>
            </div>
        }

              <Row>
                <div className="d-flex align-items-center">
                {userProfileData && userProfileData.imageUrl ? (
                    <>
                    <Image
                        src={userProfileData.imageUrl ? userProfileData.imageUrl : '/Content/Images/empty_user.jpg'}
                        alt="Profile"
                        roundedCircle
                        className={`roundedUser ${styles.previewProfileImg}`}
                    />
                    <div className={`${styles.flexStyleforUserdiv}`}>
                        <h3 className={styles.productSellername}>{userProfileData.name}</h3>
                        <label className={styles.productSellerdesc}>{userProfileData.bio}</label>
                    </div>
                    </>
                ) : (
                    <div className={styles.productSellername} >No user data available</div> // Optional: Add a fallback in case userProfileData or imageUrl is not available
                )}
                </div>
              </Row>
                
              {experienceForm && Object.keys(experienceForm).length > 0 ? (
                <>
                <Row className="mt-3 mb-1">
                    <h3 className={styles.productCaption}>{experienceForm.caption}</h3>
                </Row>

                <Col lg={12} md={12} sm={12} xs={12}>
                  <div className={styles.workshopContainerdiv}>
                  {experienceForm.icon ? (
                    <img src={experienceForm.icon ? experienceForm.icon : "/Content/Images/SellerExpStudio/noImageBG.jpeg"}
                        className={styles.workshopThumbnailImage}
                        alt="thumbnail"
                    />
                    ) : (
                    <img
                        className={`d-block w-100 ${styles.workshopThumbnailImage}`}
                        src="/Content/Images/SellerExpStudio/noImageBG.jpeg"
                        alt="thumbnail"
                    />
                  )}
                  </div>
                </Col>
          
                <Row className={`mt-4`}>
                  <Col xs={4} sm={4} md={4} lg={4}>
                    <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Duration</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-sundial.png'
                                alt="duration_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>{experienceForm.duration}&nbsp;Mins</label>
                    </div>
                  </Col>
        
                  <Col xs={4} sm={4} md={4} lg={4}>
                  <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Price</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-dollar-coin.png'
                                alt="price_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>{experienceForm.currency}&nbsp;{experienceForm.price}</label>
                  </div>
                  </Col>
        
                  <Col xs={4} sm={4} md={4} lg={4}>
                      <div className={`overlap-group ${styles.productDetailsBox}`}>
                      <div className={`mb-3 ${styles.ProductDetailsNameTagSetup}`}>
                        <label className={styles.ProductBoxLabel}>Rating</label>
                          <div className={styles.IconSetup}>
                            <Image
                                src='/Content/Images/icons/icons-rating.png'
                                alt="rating_icon"
                                className={styles.IconSetImage}   
                              />
                          </div>
                      </div>
                      <label className={styles.ProductBoxlabelValue}>N/A</label>
                  </div>
                  </Col>

                </Row>
          
                <br /><br />
                <Row className={`mt-4 mb-4`}>
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Description</label>
                      <Button onClick={toggleShowDescription} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showDescription} onClose={toggleShowDescription} className={styles.toggleToastStyles}>
                      <label className={styles.accordionDescription}>{experienceForm.desc} &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </Toast>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <div className={styles.toggleDropdowndiv}>
                      <label className={styles.accordionHeader}>Extra Material</label>
                      <Button onClick={toggleShowMaterial} className={`${styles.toggleDropdownClick}`}>
                        <FaAngleDown />
                      </Button>
                    </div>
                    <Toast show={showMaterial} onClose={toggleShowMaterial} className={styles.toggleToastStyles}>
                      <label className={styles.accordionDescription}>{experienceForm.addInfo} &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </Toast>
                  </Col>
                </Row>

                </>
              ) : (
                <Row className="mt-2 mb-2">
                  <h3 className={styles.errorMessage} style={{textAlign: 'center'}}> Workshop data is not available.</h3>
                </Row>
              )}
          
              <Row className="text-center">
                {onPublishSuccess && (
                  <Link to='/seller/dashboard'>
                    <Button variant="light" className={styles.previewCloseStyleBtn} >&nbsp;&nbsp; Close &nbsp;&nbsp;</Button>
                  </Link>
                )}
              </Row>

      </Row>
    </Col>
    <Col md={1} xs={0}></Col> 
    </Row>

    <br/><br/><br/>
    </Container>
  );
};

export default SuccessPublishPreview;
