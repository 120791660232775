import { useRef, useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Republish.module.css';
import { SlArrowLeft } from "react-icons/sl";
import experienceServices from '../../../Services/experienceServices';
import Loader from '../../BaseComponents/Loader';
import AuthContext from '../../Context/AuthContext';


const EditUnpublishedWorkshop = ({ type, id, previewUpdatedWorkshop }) => {
    
  const republishId = id;
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useContext(AuthContext);
  const failureErrorRef = useRef(null);

  const [initialValues, setInitialValues] = useState({});
  const [changedFields, setChangedFields] = useState({});
  const [failureError, setFailureError] = useState('');
  const [categoriesFetched, setCategoriesFetched] = useState(false);
  const [allCategoryInterests, setAllCategoryInterests] = useState([]);
  const [workshopId, setWorkshopId] = useState('');
  const [workshopTitle, setWorkshopTitle] = useState('');
  const [description, setDescription] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [noOfContents, setNoOfContents] = useState('');
  const [workshopDuration, setWorkshopDuration] = useState('');
  const [workshopPrice, setWorkshopPrice] = useState('');
  const [workshopCurrency, setWorkshopCurrency] = useState('$');
  const [hashtags, setHashtags] = useState('');
  const [ratings, setRatings] = useState('');
  // const [contentsArray, setContentsArray] = useState(null);
  // const [contentsUpdated, setContentsUpdated] = useState(false);
  const [isNewCover, setIsNewCover] = useState(false);
  const [workshopThumbnail, setWorkshopThumbnail] = useState('');
  const [newWorkshopThumbnail, setNewWorkshopThumbnail] = useState('');
  const [previousWorkshopThumbnail, setPreviousWorkshopThumbnail] = useState('');
  const [selectedInterests, setSelectedInterests] = useState('');

  const [errors, setErrors] = useState({});
  const fieldRefs = {
    workshopTitle: useRef(null),
    description: useRef(null),
    additionalInfo: useRef(null),
    selectedInterests: useRef(null),
    noOfContents: useRef(null),
    workshopDuration: useRef(null),
    workshopPrice: useRef(null),
    workshopThumbnail: useRef(null),
  };

  useEffect(() => {
    if (!categoriesFetched) {
      async function fetchAllCategoryInterests() {
        try {
          const response = await experienceServices.getAllCategoryInterests();
          if (response !== null && response.statusCode === 200) {
            setAllCategoryInterests(response.data);
            setCategoriesFetched(true);
          }
        } catch (error) {
          console.error("Signup Error:", error);
        }
      }
      fetchAllCategoryInterests();
    }
  }, [categoriesFetched]);

  useEffect(() => {
    const fetchWorkshopDetails = async (id) => {
      setFailureError("");
      try {
        setIsLoading(true);
        const response = await experienceServices.fetchUserWorkshop(id);

        // console.log("Response:: ", response.data);
        if (response && response.statusCode === 200) {
          if (response.data && response.data !== null) {
            const workshopData = response.data;
            setWorkshopId(workshopData.id);
            setWorkshopTitle(workshopData.caption);
            setDescription(workshopData.desc);
            setAdditionalInfo(workshopData.addInfo);
            setSelectedInterests(workshopData.interests[0] || '');
            setWorkshopCurrency(workshopData.currency);
            setWorkshopPrice(workshopData.price);
            setWorkshopDuration(workshopData.duration);
            setRatings(workshopData.ratings);
            setWorkshopThumbnail(workshopData.coverImg);
            setPreviousWorkshopThumbnail(workshopData.coverImg);
            // setContentsArray(workshopData.contents.content);
            setNoOfContents(workshopData.postCount);
            // Hashtags Handling
            const tagsArray = workshopData.tags.filter(tag => tag); // Filter out any empty strings
            const formattedTags = tagsArray.length > 0 ? tagsArray.map(tag => `#${tag}`).join(', ') : '';
            setHashtags(formattedTags);
            // setHashtags(workshopData.tags[0] || '');

            setInitialValues({
              workshopTitle: workshopData.caption,
              description: workshopData.desc,
              additionalInfo: workshopData.addInfo,
              selectedInterests: workshopData.interests[0],
              workshopCurrency: workshopData.currency,
              workshopPrice: workshopData.price,
              workshopDuration: workshopData.duration,
              workshopThumbnail: workshopData.coverImg,
              hashtags: formattedTags,
            });
  
          } else {
            setFailureError(response.message || "Workshop not found, Please try after some time.");
          }
        } else {
          setFailureError(response.message || "Workshop not found, Please try after some time.");
        }
      } catch (error) {
        console.error("API Error:", error);
        setFailureError("API Error occurred, Please contact support.");
      } finally {
        setIsLoading(false);
      }
    };

    if (republishId) {
      fetchWorkshopDetails(republishId);
    }
  }, [republishId, setIsLoading]);


  useEffect(() => {
    if (failureError && failureErrorRef.current) {
      failureErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      failureErrorRef.current.style.backgroundColor = '#ffdddd';
    }
  }, [failureError]);

  const uploadNewThumbnail = (fieldName, event) => {
    setChangedFields(prevState => ({ ...prevState, [fieldName]: true }));
    if (fieldName === 'workshopThumbnail') {
      const files = event.target.files;
      if (files && files.length > 0) {
        const thumbnail = URL.createObjectURL(files[0]);
        setWorkshopThumbnail(thumbnail);
        setNewWorkshopThumbnail(files[0]);
        setIsNewCover(true);
      }
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setChangedFields(prevState => ({ ...prevState, [fieldName]: true }));
    switch (fieldName) {
      case 'workshopTitle':
        setWorkshopTitle(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'additionalInfo':
        setAdditionalInfo(value);
        break;
      case 'selectedInterests':
        setSelectedInterests(value);
        break;
      case 'noOfContents':
        setNoOfContents(value);
        break;
      case 'workshopDuration':
        setWorkshopDuration(value);
        break;
      case 'workshopPrice':
        setWorkshopPrice(value);
        break;
      case 'workshopThumbnail':
        setWorkshopThumbnail(value);
        break;
      case 'hashtags':
        setHashtags(value);
        break;
      default:
        break;
    }
  };

  
  function processHashtags(hashtags) {
    if (typeof hashtags !== 'string') {
      return "";
    }
    if (hashtags && hashtags !== "") {
      const wordsWithHashtags = hashtags.match(/#\w+/g);
      if (wordsWithHashtags) {
        const processedWords = wordsWithHashtags.map(word => word.substring(1)).join(', ');
        return processedWords;
      } else {
        return "";
      }
    }
    return "";
  }

  const validateForm = () => {
    const newErrors = {};

    if (changedFields.workshopTitle && (!workshopTitle || workshopTitle.trim() === "")) {
      newErrors.workshopTitle = 'Please enter Workshop title';
      fieldRefs.workshopTitle.current.focus();
    }

    if (changedFields.description && (!description || description.trim() === "")) {
      newErrors.description = 'Please enter a description';
      fieldRefs.description.current.focus();
    }

    if (changedFields.additionalInfo && (!additionalInfo || additionalInfo.trim() === "")) {
      newErrors.additionalInfo = 'Please enter some required materials';
      fieldRefs.additionalInfo.current.focus();
    }

    if (changedFields.selectedInterests && (!selectedInterests || selectedInterests.length === 0)) {
      newErrors.selectedInterests = 'Please select a category';
      fieldRefs.selectedInterests.current.focus();
    }

    if (changedFields.noOfContents && (noOfContents === '' || parseInt(noOfContents) <= 0)) {
      newErrors.noOfContents = 'Please enter a valid number of contents';
      fieldRefs.noOfContents.current.focus();
    }

    if (changedFields.workshopDuration && (workshopDuration === '' || parseInt(workshopDuration) <= 0)) {
      newErrors.workshopDuration = 'Please enter a valid duration';
      fieldRefs.workshopDuration.current.focus();
    }

    if (changedFields.workshopPrice && (workshopPrice === '' || parseFloat(workshopPrice) <= 0)) {
      newErrors.workshopPrice = 'Please enter a valid price';
      fieldRefs.workshopPrice.current.focus();
    }

    if (changedFields.workshopThumbnail && workshopThumbnail === '') {
      newErrors.workshopThumbnail = 'Thumbnail is required';
      fieldRefs.workshopThumbnail.current.focus();
    }

    if (
      workshopTitle === initialValues.workshopTitle &&
      description === initialValues.description &&
      additionalInfo === initialValues.additionalInfo &&
      selectedInterests === initialValues.selectedInterests &&
      workshopDuration === initialValues.workshopDuration &&
      workshopPrice === initialValues.workshopPrice &&
      hashtags === initialValues.hashtags &&
      workshopThumbnail === initialValues.workshopThumbnail &&
      !isNewCover
    ) {
      setFailureError("No changes were made. please modify at least one field.");
      return false;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    }

    setErrors({});
    return true;
  };

  const calculateProgress = () => {
    const totalFields = 8;
    const filledFields = [
      workshopTitle && workshopTitle.trim().length >= 10,
      description && description.trim().length >= 10,
      additionalInfo && additionalInfo.trim().length >= 10,
      selectedInterests !== '',
      // noOfContents !== '' && parseInt(noOfContents) > 0,
      workshopDuration !== '' && parseInt(workshopDuration) > 0,
      workshopPrice !== '' && parseFloat(workshopPrice) > 0,
      workshopThumbnail !== '',
      hashtags !== '',
    ].filter(Boolean).length;

    return (filledFields / totalFields) * 100;
  };


  const saveUpdatedWorkshop = async () => {
    setIsLoading(true);
    setFailureError("");
  
    if (!validateForm()) {
      setIsLoading(false);
      return;
    }
  
    try {
      let formData = new FormData();
      const resultTags = processHashtags(hashtags);
      formData.append('id', workshopId);
  
      if (changedFields.workshopTitle) {
        formData.append('caption', workshopTitle);
      }
      if (changedFields.description) {
        formData.append('desc', description);
      }
      if (changedFields.additionalInfo) {
        formData.append('addInfo', additionalInfo);
      }
      if (changedFields.workshopCurrency) {
        formData.append('currency', workshopCurrency);
      }
      if (changedFields.workshopPrice) {
        formData.append('price', workshopPrice);
      }
      if (changedFields.workshopDuration) {
        formData.append('duration', workshopDuration);
      }
      if (changedFields.ratings) {
        formData.append('ratings', ratings);
      }
      if (changedFields.noOfContents) {
        formData.append('feedSize', noOfContents);
      }
      if (changedFields.selectedInterests) {
        formData.append('interests', selectedInterests);
      }
      if (changedFields.hashtags) {
        formData.append('tags', resultTags);
      }
      if (changedFields.workshopThumbnail && isNewCover) {
        formData.append('previousCoverImg', previousWorkshopThumbnail);
        formData.append('coverImg', newWorkshopThumbnail);
        formData.append('isNewCover', isNewCover);
      } else {
        formData.append('isNewCover', isNewCover);
      }
      
      let formDataObject = Object.fromEntries(formData.entries());
      // console.log("Request form:: ", formDataObject);
  debugger
      if (type === 'unpublished-workshop') {
        const response = await experienceServices.saveUpdatedWorkshop(formDataObject);
        if (response && response.statusCode === 200) {
          previewUpdatedWorkshop(formDataObject);
          navigate(`/host/publish-workshop/${republishId}`);
        } else {
          setFailureError(response.message || "Internal error, Please try again later.");
        }
        
      } else if (type === 'update-workshop') {
        previewUpdatedWorkshop(formDataObject);
        navigate(`/host/republish-workshop/${republishId}`);

      } else {
        setFailureError("Internal error, please try again later.");
      }
    } catch (error) {
      console.error("saveUpdatedWorkshop Error:", error);
      setFailureError('Internal error, workshop update failed.');
    } finally {
      setIsLoading(false);
    }
  };
  
  
  const backtoPrevious = () => {
    navigate(-1);
  };

  return (
    <Container fluid className="MainpartSetup mainPad">
      <div className={styles.editUnpublishedWorkshopdiv}>
        <Row className="d-flex justify-content-between align-items-center">
          <div className="mt-3 d-flex align-items-center">
            <Link onClick={backtoPrevious}>
              <SlArrowLeft className={styles.HoverBackOption} /> &nbsp;
            </Link>
            <p className={styles.labelname}> Edit workshop details </p>
          </div>
          <div className={`mb-3 mt-2 ${styles.labelContent}`}> Add details to your Product to attract buyers </div>
          {failureError && <div ref={failureErrorRef} key={failureError} className={styles.errorMessage} style={{ textAlign: 'center' }}> {failureError} </div>}
        </Row>

        <Row className="mt-3">
       
          <Col lg={5}>
            <Row className={styles.progressBardiv}>
              <div className='d-flex align-items-center justify-content-between'>
                <label className={styles.progressBarlabel}>Add workshop details</label>
                <label className={styles.progressBarlabel}>{`${Math.round(calculateProgress())}%`}</label>
              </div>
              <div className={styles.progressBar}>
                <div className={styles.progressBarInner} style={{ width: `${calculateProgress()}%` }} />
              </div>
            </Row>
            <br/>

            <Form.Group className="mb-3" controlId="workshopTitle">
              <Form.Label className={styles.inputlabel}> Workshop title <span style={{ color: '#ff0000' }}> * </span> </Form.Label>
              <Form.Control ref={fieldRefs.workshopTitle} autoFocus className={styles.inputfield} type="text" value={workshopTitle} onChange={(e) => handleFieldChange('workshopTitle', e.target.value)} />
              {errors.workshopTitle && <div className={styles.errorMessage}> {errors.workshopTitle} </div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label className={styles.inputlabel}> Description <span style={{ color: '#ff0000' }}> * </span> </Form.Label>
              <Form.Control ref={fieldRefs.description} className={styles.inputfield} as="textarea" rows={5} value={description} onChange={(e) => handleFieldChange('description', e.target.value)} />
              {errors.description && <div className={styles.errorMessage}> {errors.description} </div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="additionalInfo">
              <Form.Label className={styles.inputlabel}> Materials required for Workshop <span style={{ color: '#ff0000' }}> * </span> </Form.Label>
              <Form.Control ref={fieldRefs.additionalInfo} className={styles.inputfield} as="textarea" rows={4} value={additionalInfo} onChange={(e) => handleFieldChange('additionalInfo', e.target.value)} />
              {errors.additionalInfo && <div className={styles.errorMessage}> {errors.additionalInfo} </div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="categories">
              <Form.Label className={styles.inputlabel}> Select Category <span style={{ color: '#ff0000' }}> * </span> </Form.Label>
              <Form.Control ref={fieldRefs.selectedInterests} className={styles.formSelect} as="select" value={selectedInterests} onChange={(e) => handleFieldChange('selectedInterests', e.target.value)} >
                <option value="" disabled>Please select your category</option>
                {allCategoryInterests.flatMap((category) => category.interests).map((interest) => (
                  <option key={interest} value={interest}>
                    {interest}
                  </option>
                ))}
                <option value="others">Others</option>
              </Form.Control>
              {errors.selectedInterests && <div className={styles.errorMessage}> {errors.selectedInterests} </div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="noOfContents">
              <Form.Label className={styles.inputlabel}> No. of Videos <span style={{ color: '#ff0000' }}> * </span> </Form.Label>
              <Form.Control ref={fieldRefs.noOfContents} disabled className={styles.inputfield} type="number" value={noOfContents} onChange={(e) => handleFieldChange('noOfContents', e.target.value)} />
              {errors.noOfContents && <div className={styles.errorMessage}> {errors.noOfContents} </div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="workshopDuration">
              <Form.Label className={styles.inputlabel}> Duration <span style={{ color: '#ff0000' }}> * </span> (minutes) </Form.Label>
              <Form.Control ref={fieldRefs.workshopDuration} className={styles.inputfield} type="number" value={workshopDuration} onChange={(e) => handleFieldChange('workshopDuration', e.target.value)} />
              {errors.workshopDuration && <div className={styles.errorMessage}> {errors.workshopDuration} </div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="workshopPrice">
                <Form.Label className={styles.inputlabel}> Price <span style={{ color: '#ff0000' }}> * </span> </Form.Label>
                <InputGroup>
                  <InputGroup.Text className={styles.formControl}> $ </InputGroup.Text>
                  <Form.Control ref={fieldRefs.workshopPrice} className={styles.inputfield}  type="number"
                    value={workshopPrice} onChange={(e) => handleFieldChange('workshopPrice', e.target.value)} />
                </InputGroup>
                {errors.workshopPrice && <div className={styles.errorMessage}> {errors.workshopPrice} </div>}
            </Form.Group>

            <Form.Group className="mb-3" controlId="hashtags">
              <Form.Label className={styles.inputlabel}> Hashtags (upto 10) </Form.Label>
              <Form.Control className={styles.inputfield} as="textarea" rows={4} value={hashtags} onChange={(e) => handleFieldChange('hashtags', e.target.value)} />
            </Form.Group>
          </Col>
          <Col lg={1}></Col>
          <Col md={6}>
            <Row className={`mb-3`}>
              {workshopThumbnail ? (
                <img className={styles.workshopThumbnailImage}
                  alt="thumbnail" src={workshopThumbnail} 
                  />
              ) : (
                <img className={styles.workshopThumbnailImage}
                  alt="thumbnail" src="/Content/Images/General/no-thumbnail-bg.png"
                  />
              )}
              {errors.workshopThumbnail && <div className={styles.errorMessage}> {errors.workshopThumbnail} </div>}
            </Row>
            <Row className="mt-5 d-flex justify-content-center align-items-center">
              <Col className="d-flex justify-content-start">
                  <Form.Control ref={fieldRefs.workshopThumbnail} type="file" accept="image/*" style={{display: 'none'}} onChange={(e) => uploadNewThumbnail('workshopThumbnail', e)} />
                  <div className={`${styles.uploadThumbnailSection}`} onClick={() => fieldRefs.workshopThumbnail.current.click()}>
                      <div className={`${styles.uploadIcondiv}`}>
                        <img src="/Content/Images/General/document-upload.png"
                          className={`${styles.uploadbtnImage}`} alt="upload"
                        />
                      </div>
                      <label htmlFor="fileInput" className={`${styles.uploadNameTag}`}>
                        Upload a file
                      </label>
                  </div>
              </Col>
            </Row>
          </Col>

        </Row>
    
        <Row className="mt-2">
          <Col className="d-flex justify-content-end g-4">
            <Button variant="outline-secondary" className={styles.cancelButton} onClick={backtoPrevious}> &nbsp; Cancel &nbsp; </Button> &nbsp;&nbsp;
            <Button variant="primary" className={styles.successProceedButton} onClick={saveUpdatedWorkshop} disabled={isLoading}>
            {isLoading ? <Loader /> : <span> &nbsp; {type === 'update-workshop' ? "Preview" : "Save" } &nbsp; </span>}
            </Button>
          </Col>
        </Row>
      </div>

    <br/><br/>
    </Container>
  );
};

export default EditUnpublishedWorkshop;
