import { useState } from 'react';
import TransparentHeader from '../../Navbar/TransparentHeader';
import SigninUser from './SigninUser';
import ResetPassword from './ResetPassword';
import VerifyOtp from './VerifyOtp';
import SetNewPassword from './SetNewPassword';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { useParams } from 'react-router-dom';


const Signin = () => {
  
  const { loginstatus } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('create_experience');

  const handleStatusChange = (status) => {
    setVerificationStatus(status);
  };

  return (
    <div className="App HomeViewportHt">
      <TransparentHeader />
      
      {loginstatus === 'form' && <SigninUser />}
      {loginstatus === 'password-reset' && <ResetPassword onVerificationStatus={handleStatusChange} />}
      {verificationStatus === 'otp-verification' && <VerifyOtp onVerificationStatus={handleStatusChange} />}
      {verificationStatus === 'set-newpassword' && <SetNewPassword onVerificationStatus={handleStatusChange} />}
      {verificationStatus === 'password-success' && <ResetPasswordSuccess />}

    </div>

  );
}

export default Signin;
