import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import signStyles from './signup.module.css';
import loginApiServices from '../../../Services/loginApiServices';



const OtpVerification = ({ email, otpId, updateSessionId }) => {

  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpError, setOtpError] = useState("");
  const [localSessionId, setLocalSessionId] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  useEffect(() => {
    updateSessionId(localSessionId);
  }, [localSessionId, updateSessionId]);


  // Initialize refs
  const inputRefs = Array.from({ length: 4 }).map(() => React.createRef());
  
  const applyErrorAnimation = () => {
    inputRefs.forEach((ref) => {
      if (ref.current) {
        ref.current?.classList.add('blink-error');
        setTimeout(() => {
          ref.current?.classList.remove('blink-error');
        }, 1000);
      }
    });
  };


const handleChange = (index, value) => {
  const digit = value.replace(/\D/g, '');
  setOtp((prevOtp) => {
    const newOtp = [...prevOtp];
    newOtp[index] = digit;
    return newOtp;
  });
  // Move to the previous input if backspacing in an empty input
  if (digit === '' && index > 0) {
    if (inputRefs[index - 1] && inputRefs[index - 1].current) {
      inputRefs[index - 1].current.focus(); // Move to the previous input
    }
  }
  // Move to the next input
  if (digit !== '' && index <= 3) {
    if (inputRefs[index + 1] && inputRefs[index + 1].current) {
      inputRefs[index + 1].current.focus(); // Move to the next input
    // } else if (index === 2) {
    //   if (inputRefs[index + 1] && inputRefs[index + 1].current) {
    //     inputRefs[index + 1].current?.focus();
    //    }
    }
  }
};

  const handleKeyDown = (index, event) => {
    // Move to the previous input on backspace
    if (event.key === 'Backspace' && index > 0) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = ''; // Clear the current input
        return newOtp;
      });
      // Move the focus to the previous input
      if (inputRefs[index - 1] && inputRefs[index - 1].current) {
        inputRefs[index - 1].current.focus();
      }
    }
  };


  const verifyOtp = async () => {
    setOtpError('');
    
    if(email === null && email.length === 0){
        setOtpError('Please enter your email id to proceed');
        return;
    }else if (otpId === null && otpId.length === 0) {
        setOtpError('Enter the OTP received on email');
        return;
    }

    const otpString = otp.join("");

    if(otpString.length !== 4){
        setOtpError('Please enter the correct OTP');
        setOtp(['', '', '', '']);
        applyErrorAnimation();
        return;
    }

    try {
      
      const response = await loginApiServices.verifyEmailOtp(email,otpId,otpString);
      if (response.statusCode === 200 || response.statusCode === 0) {
        if(response.data !== null && response.data.length >0 && response.data[0].verified){
            setLocalSessionId(response.data[0].sessionId);
            setIsOtpVerified(true);
          } else {
            setOtp(['', '', '', '']);
            setIsOtpVerified(false);
            setOtpError(response.data[0].message);
            applyErrorAnimation();
          }
      } else {
        setOtp(['', '', '', '']);
        setIsOtpVerified(false);
        setOtpError(response.message);
        applyErrorAnimation();
      }
    } catch (error) {
      setOtp(['', '', '', '']);
      setIsOtpVerified(false);
      console.error("verifyOtp Error:", error);
    }
  };


useEffect(() => {
  const otpString = otp.join('');
  if (otpString.length === 4 && !isOtpVerified) {
    verifyOtp();
  }
});


  return (

    <div>
      <div className="d-flex align-items-center justify-content-between g-3">
        <label className={signStyles.OtpLabeltext}> One Time Password </label> &nbsp;
        {otp.map((digit, index) => (
          <Form.Control
            key={index}
            type="tel"
            className={`${signStyles.OtpBox} ${otpError ? `blink-error ${signStyles['blinkerror']}` : ''}`}
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={inputRefs[index]}
          />
        ))}

      </div>
      {otpError && (
        <Form.Text className="text-danger" align="center">
          {otpError}
        </Form.Text>
      )}
    </div>
  );
};

export default OtpVerification;
