import { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from './Navbar/Header';
import AboutProfile from './BaseComponents/AboutProfile';
import UserProfile from './BaseComponents/UserProfile';
import UploadProfile from './Profile/UploadProfile';
import BasicDetails from './Profile/BasicDetails';
import Bio from './Profile/Bio';
import ContactDetails from './Profile/ContactDetails';
import BankDetails from './Profile/BankDetails';
import Interests from './Profile/Interests';
// import StripeDashboard from './Profile/StripeDashboard';
import { useParams } from 'react-router-dom';


const UpdateUserProfile = () => {
  
    // const navigate = useNavigate();
    // const [stripeUrl, setStripeUrl] = useState('');
    const [loginStatus] = useState('loggedIn');
    let { profileStatus } = useParams();
    const [isStripe, setIsStripe] = useState('');

    useEffect(() => {
      const profileData = localStorage.getItem('userProfileData');
      if (profileData) {
        const parsedData = JSON.parse(profileData);
        // console.log("User Data::", parsedData)
        if(parsedData.stripeAccountExists) {
          setIsStripe(true);
        }
      }
    }, []);


    // const onStripeDashboard = (url) => {
    //   debugger
    //   setStripeUrl(url);
    //   navigate('/profile/stripe-dashboard');
    // };


  return (
    <div className="sellerBankSection">

      <Header loginStatus={loginStatus} />

      <main className="MainpartSetup viewportHt">

      <Row className={`mainPad`}>

          <Col md={3}>
            <UserProfile />
            <br />
            <AboutProfile isStripe={isStripe} />
          </Col>

          <Col md={9}>
            {profileStatus === 'update_profile_picture' && <UploadProfile />}
            {profileStatus === 'update_basic_details' && <BasicDetails />}
            {profileStatus === 'update_bio' && <Bio />}
            {profileStatus === 'update_contact_details' && <ContactDetails />}
            {profileStatus === 'stripe-account-details' && <BankDetails isStripe={isStripe} />}
            {profileStatus === 'update_interests' && <Interests />}
            {/* {profileStatus === 'stripe-dashboard' && <StripeDashboard stripeUrl={stripeUrl} />} */}

          </Col>

          </Row>
      </main>

    </div>

  );
}


export default UpdateUserProfile;