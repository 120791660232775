import { useState } from 'react';
import Header from './Navbar/Header';
import UpdateBankDetails from './SellerDashboard/UpdateBankDetails';
import { useParams } from 'react-router-dom';


const SellerBankDetails = () => {
  
    const [loginStatus] = useState('loggedIn');
    const { bankStatus } = useParams();


  return (

    <div className="sellerBankSection">

      <Header loginStatus={loginStatus} />

      <main className="MainpartSetup viewportHt">

          {bankStatus === 'seller_bank_details' && <UpdateBankDetails />}
          
      </main>

    </div>

  );
}

export default SellerBankDetails;

