import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Image, Container, Table, Badge } from 'react-bootstrap';
import sellStyles from './SellerStyle.module.css';



const UserWorkshopsList = ({ workshopsList }) => {
    
    // console.log("workshopsList::: ", workshopsList);

    return (

        <Container fluid className='RightPadding'>
            <Row>
                <h2 className={sellStyles.headerText}>All Workshops
                    <Badge pill bg="warning" text="dark" style={{marginLeft: '15px'}}>{workshopsList.length}</Badge>
                </h2>
            </Row>

            <Row className={`${sellStyles.userWorkshopsListTable} RightPadding`}>
                {workshopsList && workshopsList.length > 0 ? (
                    <div className="table-container">
                    <Table hover className={sellStyles.userWorkshopsTable}>
                        <thead className="thead-dark">
                            <tr>
                                <th style={{ width: '40%', textAlign: 'left', borderRadius: '15px 0px 0px 15px' }}>Workshop name</th>
                                <th>Date</th>
                                <th>Rating</th>
                                <th>Sales</th>
                                <th style={{borderRadius: '0px 15px 15px 0px'}} >View</th>
                            </tr>
                        </thead>
                        <tbody className="tbody-scroll">
                            {workshopsList.map((workshop, index) => (
                            <tr key={index} className={sellStyles.TBodyRowHover} id={workshop.id}>
                                <td>
                                    <Link to={`/experiencehub/experience/${workshop.id}`}>
                                    <div className='mb-2 mt-2 d-flex align-items-center'>
                                        {workshop.icon ? (
                                        <Image
                                            src={workshop.icon}
                                            alt="Experience Thumbnail"
                                            className={sellStyles.userWSImage}
                                        />
                                        ) : (
                                            <Image
                                            src="/Content/Images/SellerExpStudio/noImageBG.jpeg"
                                            alt="Experience Thumbnail"
                                            className={sellStyles.userWSImage}
                                            />
                                        )}
                                        <div className={sellStyles.userWSContentBox}>
                                            <h3 className={sellStyles.truncatedText} data-tooltip={workshop.caption}>{workshop.caption}</h3>
                                            <label className={sellStyles.truncatedText} data-tooltip={workshop.desc}>{workshop.desc}</label>
                                        </div>
                                    </div>
                                    </Link>
                                </td>
                                <td>{workshop.dateCreated} <br/> <span className={sellStyles.publishedTestStatus}>published</span></td>
                                <td>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <span style={{ fontSize: '20px', color: '#FFD700' }}>★</span>
                                        <span>{workshop.rating ?? 0}</span>
                                    </div>
                                </td>
                                <td>
                                    <Badge pill bg="success">
                                        {workshop.totalSales}
                                    </Badge>
                                </td>
                                <td>
                                    <Link to={`/host/update-workshop/${workshop.id}`} className='text-end'>
                                        <label className={sellStyles.viewLabelClick}>
                                            Edit
                                        </label>
                                    </Link>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                    <br/>
                    </div>
                ) : (
                <Row>
                    <div colSpan="3" style={{ textAlign: 'center', padding: '1em 0em', fontSize: '1.1em', fontWeight: 'bold', background: '#E0E0FC' }}>
                        You have not created any workshops.
                    </div>
                </Row>
                )}
            </Row>
        </Container>

    );
};

export default UserWorkshopsList;
