import { Row, Col } from 'react-bootstrap';
import UserProfile from '../BaseComponents/UserProfile';
import SendBird from '../SendBird';
import EngageActions from '../BaseComponents/EngageActions';
import HubOverview from './HubOverview';
import styles from './hub.module.css';


const HubDashboardDetails = () => {


  return (

      <main className="MainpartSetup HomeViewportHt">
        <Row className="mainPad stickySidebar">
          <Col xs={12} lg={3} className={`stickySidebarOverview ${styles.responsiveDisplay}`}>
            <UserProfile />
            <br />
            <EngageActions />
          </Col>
          
          <Col xs={12} lg={6} >
            <HubOverview />
          </Col>
          
          <Col xs={12} lg={3} className={`${styles.responsiveDisplay}`}>
            <SendBird />
          </Col>
        </Row>

      </main>


  );
}

export default HubDashboardDetails;
