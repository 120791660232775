import { useState, useEffect } from 'react';
import Feed from './Feed/Feed';
import userServices from '../../Services/userServices';
import { BeatLoader } from 'react-spinners';
import styles from './Main.module.css'; // Ensure to import the styles


const Content = () => {
  const [feeds, setFeeds] = useState([]);
  const [startIndex, setStartIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [stopFetch, setStopFetch] = useState(false);

  const fetchData = async (index) => {
    try {
      setIsLoading(true);
      const response = await userServices.getUserFeeds(index);

      if (response.statusCode === 200) {
        const json = await response.data;
        if (json.length === 0) {
          setStopFetch(true);
        } else {
          setFeeds((prevFeeds) => [...prevFeeds, ...json]);
        }
      } else {
        setStopFetch(true);
      }
    } catch (error) {
      console.log(error);
      setStopFetch(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!stopFetch) {
      fetchData(startIndex);
    }
  }, [startIndex, stopFetch]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight - 1 && !isLoading && !stopFetch) {
        setStartIndex((prevIndex) => prevIndex + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, stopFetch]);

  return (
    <div className='row minPadding'>
      {feeds.length > 0 ? (
        <Feed feeds={feeds} setFeeds={setFeeds} />
      ) : (
        !isLoading && (
          <div className={styles.feedsContainerBox}>
            <label>No feeds available.</label>
          </div>
        )
      )}
      {isLoading && (
        <div className={styles.feedsContainerBox}>
          {/* <ClipLoader color="#2E637D" loading={true} size={50} /> */}
          <BeatLoader color="#2E637D" loading={true} height={10} width={200} />
          <label> Loading... </label>
        </div>
      )}
    </div>
  );
};

export default Content;
