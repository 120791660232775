import { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AccStyles from './ExperienceStudio.module.css';


const AccountFailure = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/seller-experiences/publish-experience');
    }, 2000);
  }, [navigate]);
  

  return (
    <Container fluid className={`${AccStyles.Background}`}>
            <Row className={`mainPad ${AccStyles.viewportSetup}`}>   
              <Row className={`text-center ${AccStyles.contentSection}`}>
                    <img
                      src="/Content/Images/General/account_success.png"
                      className={AccStyles.stripeStatusIcon}
                      alt="failed"
                    />
                    <h2  className={AccStyles.stripeAccountStatus}>Failed</h2>
                    <label className={AccStyles.stripeAccountContent}> There was an error - please try again </label>
                </Row>
            </Row>
    </Container>
  );
};

export default AccountFailure;
