import AuthHeader from './Auth/AuthHeader';
import AuthBody from './Auth/AuthBody';
import AuthFooter from './Auth/AuthFooter';


const AuthPage = () => {
  
    return (
      <>
        <header>
          <AuthHeader />
        </header>
        <main>
          <AuthBody />
        </main>
        <footer>
          <AuthFooter />
        </footer>
      </>
  );
}

export default AuthPage;
