// Loader.js
import React, { useContext } from 'react';
import ReactLoading from 'react-loading';
import AuthContext from '../Context/AuthContext';
import styles from './loading.module.css';


const Loader = () => {
  
    const { isLoading } = useContext(AuthContext);
    if (!isLoading) {
        return null;
    }

  return (
    <div className={`${styles.loadingOverlay} HomeViewportHt`}>
        <div className={styles.loadingOverlaydiv}>
            <ReactLoading type="balls" color="#FFF" />
            <br />
            <label className={styles.loadingOverlayText}> Loading, please wait... </label>
      </div>
    </div>
  );
};

export default Loader;
