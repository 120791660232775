import {  useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, InputGroup, Form } from 'react-bootstrap';
import expStyles from './ExperienceStudio/ExperienceStudio.module.css';
import LoadingOverlay from '../BaseComponents/LoadingOverlay';
import { SlArrowLeft } from "react-icons/sl";
import experienceServices from '../../Services/experienceServices';
import { FaCheckCircle } from "react-icons/fa";


const UpdateBankDetails = () => {

  // console.log("KK::", bankStatus);
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountBsb, setAccountBsb] = useState('');
  const [confirmFlag, setConfirmFlag] = useState(false); 
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    accountNameError: "",
    accountNumberError: "",
    accountBsbError: "",
    confirmFlagError: "",
  });

  const handleInputs = (e) => {
    const { name, type, checked, value } = e.target;
  
    if (type === 'checkbox') {
      setConfirmFlag(checked);
    } else {
      const setStateFunction = {
        accountName: setAccountName,
        accountNumber: setAccountNumber,
        accountBsb: setAccountBsb,
      }[name];
  
      if (typeof setStateFunction === 'function') {
        setStateFunction(value);
      }
    }
  
    // Clear the error message for the current input
    setErrors({ ...errors, [name]: '' });
    setErrorMessage("");
  };


  useEffect(() => {
  const fetchSellerBankDetails = async () => {
    try {
      
      setIsLoading(true);
      const response = await experienceServices.fetchSellerBankDetails();
      
      if (response.statusCode === 200) {
        // Assuming the response has properties like accountName, accountNumber, etc.
        if(response.data !== null && response.data !== "") {
          setAccountName(response.data.accName || '');
          setAccountNumber(response.data.accNum || '');
          setAccountBsb(response.data.bsb || '');
          setConfirmFlag(response.data.success || false);
        }
      }
      
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  fetchSellerBankDetails();
  }, []);


  const saveSellerBankDetails = async (e) => {
    
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const newErrors = { 
      accountNameError: "",
      accountNumberError: "",
      accountBsbError: "",
      confirmFlagError: ""
    };
    setErrors(newErrors);
    
    if (!accountName) {
      newErrors.accountNameError = "Please enter Account holder name";
      document.querySelector("#accountName").focus();
      setErrors(newErrors);
      return;
    }
    if (!accountNumber) {
      newErrors.accountNumberError = "Please enter Account Number";
      document.querySelector("#accountNumber").focus();
      setErrors(newErrors);
      return;
    }
    if (!accountBsb) {
      newErrors.accountBsbError = "Please enter Bsb Details";
      document.querySelector("#accountBsb").focus();
      setErrors(newErrors);
      return;
    }
    if (!confirmFlag) {
      newErrors.confirmFlagError = "Please agree the Terms & Condiions";
      setErrors(newErrors);
      return;
    }
    
    if (newErrors.accountNameError || newErrors.accountNumberError ||
      newErrors.accountBsbError || newErrors.confirmFlagError) {
      setErrors(newErrors);
      return;
    }
    
    try {
      setIsLoading(true);
      
      const response = await experienceServices.updateSellerBankDetails(accountName, accountNumber, accountBsb, confirmFlag);
      
      if (response.statusCode === 200) {
        
        if(response.data !== null && response.data[0].updated){
          setSuccessMessage("Bank Details Updated Successfully.");
        } else {
          setErrorMessage(response.message);
        }
        
        } else {
          setErrorMessage(response.message);
        }
      
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    
    <Container fluid className={`mainPad`}>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs="auto">
          <div className="d-flex align-items-center">
            <Link to="/seller/dashboard">
              <SlArrowLeft className={expStyles.HoverBackOption} /> &nbsp;&nbsp;&nbsp;&nbsp;
            </Link>
            <p className={expStyles.labelname}>Bank Details </p>
          </div>
        </Col>
      </Row>

      <Row className={expStyles.ESWhiteBackdrop}>
          <Row className='mb-3'>
            <Form.Text align="center" style={{ color: 'red', fontWeight: 'bold' }}>{errorMessage}</Form.Text>
            <Form.Text align="center" style={{ color: 'green', fontWeight: 'bold' }}>{successMessage}</Form.Text>
          </Row>

          
        <Row>
        <Col md={4}>
        
        <Row>
          <div className={expStyles.labelContent}> * Please enter valid bank details for future payments </div>
        </Row>
        <br />

        <Row>
        <Col md={3}>
        <Row className={`d-flex justify-content-between ${expStyles.progressframe}`} >
          <Col className={expStyles.topline}>
            <div className={expStyles.strline} />
          </Col>
          <Col>
          <FaCheckCircle className={expStyles.circleCompleted} />
          </Col>
          <Col className={expStyles.bottomline}>
            <div className={expStyles.strline} />
          </Col>
        </Row>
        <Row>
          <p className={expStyles.NameTag} >Start</p>
        </Row>
        </Col>

        <Col md={3}>
        <Row className={expStyles.progressframe}>
          <Col className={expStyles.topline}>
            <div className={expStyles.strline} />
          </Col>
          <Col>
          <FaCheckCircle className={expStyles.circleCompleted} />
          </Col>
          <Col className={expStyles.bottomline}>
            <div className={expStyles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={expStyles.NameTag} >Select Media </p>
          </Row>
        </Col>

        <Col md={3}>
        <Row className={expStyles.progressframe}>
          <Col className={expStyles.topline}>
            <div className={expStyles.rectangle} />
          </Col>
          <Col>
            <div className={expStyles.currentRunIcon}>
              <div className={expStyles.blueBot}></div>
            </div>
          </Col>
          <Col className={expStyles.bottomline}>
            <div className={expStyles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={expStyles.NameTag} >Bank details </p>
        </Row>
        </Col>
        
        <Col md={3}>
        <Row className={expStyles.progressframe}>
          <Col className={expStyles.topline}>
            <div className={expStyles.strline} />
          </Col>
          <Col>
          <div className={expStyles.indicator2} />
          </Col>
          <Col className={expStyles.bottomline}>
            <div className={expStyles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={expStyles.NameTag} >Publish </p>
          </Row>
        </Col>
        </Row>
      
        <br />
        {isLoading && <LoadingOverlay />}
        <Row>

        <br/>
        <Form.Group controlId="accountName" className='mb-2'>
          <Form.Label className={expStyles.formlabel} >Account Name*</Form.Label>
          <Form.Control
            required
            className={expStyles.formControl}
            type="text"
            placeholder=""
            value={accountName}
            onChange={(e) => {setAccountName(e.target.value); handleInputs(e);}}       
          />
          
          {errors.accountNameError && <div className={expStyles.errorMessage}>{errors.accountNameError}</div>}
        </Form.Group>

        <Form.Group controlId="accountNumber" className='mb-2'>
          <Form.Label className={expStyles.formlabel}>Account Number*</Form.Label>
          <InputGroup>
            <Form.Control 
            required
            className={expStyles.formControl}
            type="tel"
            value={accountNumber}
            onChange={(e) => {setAccountNumber(e.target.value); handleInputs(e);}}
            placeholder='' />
          </InputGroup>
          {errors.accountNumberError && <div className={expStyles.errorMessage}>{errors.accountNumberError}</div>}
        </Form.Group>

        <Form.Group controlId="accountBsb" className='mb-2'>
          <Form.Label className={expStyles.formlabel} >Bank BSB*</Form.Label>
          <InputGroup>
          <Form.Control 
            required
            className={expStyles.formControl}
            type="text" 
            value={accountBsb}
            onChange={(e) => {setAccountBsb(e.target.value); handleInputs(e);}}
            placeholder='' />
            </InputGroup>
          {errors.accountBsbError && <div className={expStyles.errorMessage}>{errors.accountBsbError}</div>}
        </Form.Group>


        <Form.Group className='mt-2'>
        <Form.Check className={expStyles.BankformCheck}
             type="checkbox"
             id="bank-checkbox"
             inline
              label={
                <Form.Label className={expStyles.formlabel}>By continuing I confirm that, I have read and agree with the Terms & Conditions.</Form.Label>
              }
             
             checked={confirmFlag}
             onChange={() => setConfirmFlag(!confirmFlag)}
              />
        <Form.Text className="text-danger">{errors.confirmFlagError}</Form.Text>
                                
        </Form.Group>
        </Row>
        
        </Col>

        <Col md={2}></Col>
        <Col md={1} className={expStyles.verticalLine} >

        </Col>
        <Col md={4}>
          <Row>

            <h5 className={expStyles.labelname}>About payment Terms</h5>
            <br />
            <br />
            <p className={expStyles.labelContent}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Praesent semper feugiat nibh sed pulvinar proin gravida hendrerit.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
             incididunt ut labore et dolore magna aliqua. Praesent semper feugiat.
            </p>
          </Row>
        </Col>
        </Row>

        <br /><br />

        <Row className="justify-content-end">
          <Col xs="auto">
            <Link to="/seller/dashboard">
              <Button variant="outline-primary" >&nbsp;&nbsp; Cancel&nbsp;&nbsp;</Button>&nbsp;&nbsp;
            </Link>
          </Col>
          <br/><br/>
          <Col xs="auto">
            <Button variant="primary" onClick={saveSellerBankDetails}>&nbsp;&nbsp; Update &nbsp;&nbsp;</Button>
          </Col>
        </Row>
       
        <br /><br />
      
      </Row>
      
    </Container>
  );
};

export default UpdateBankDetails;
