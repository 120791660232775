import { Row, Col } from 'react-bootstrap';
import Leftsidebar from "./Leftsidebar";
import Content from "./Content";
import SendBird from '../SendBird';
import styles from './Main.module.css';


const MainPart = () => {
  

  return (
    
    <main className="MainpartSetup HomeViewportHt">
       
      <Row className={`mainPad stickySidebar`}>
        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <Leftsidebar />
        </Col>

        <Col xs={12} lg={6}>
          <Content />
        </Col>

        <Col xs={12} lg={3} className={styles.responsiveDisplay}>
          <SendBird />
        </Col>

      </Row>

    </main>
    
  );
};

export default MainPart;