import React, { useState } from 'react';
import styles from './base.module.css';


const Tooltip = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div 
            className={styles.tooltipContainer}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            onTouchStart={() => setIsVisible(!isVisible)} // for mobile
        >
            {children}
            {isVisible && <div className={styles.tooltipContent}>{content}</div>}
        </div>
    );
};

export default Tooltip;

