import { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import styles from './ExperienceStudio.module.css';
import { Link } from 'react-router-dom';
import { SlArrowLeft } from "react-icons/sl";
import experienceServices from '../../../Services/experienceServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';

const countries = [
  { country: 'Australia', code: 'AU' },
  // { country: 'Austria', code: 'AT' },
  // { country: 'Belgium', code: 'BE' },
  // { country: 'Brazil', code: 'BR' },
  // { country: 'Bulgaria', code: 'BG' },
  // { country: 'Canada', code: 'CA' },
  // { country: 'Croatia', code: 'HR' },
  // { country: 'Cyprus', code: 'CY' },
  // { country: 'Czech Republic', code: 'CZ' },
  // { country: 'Denmark', code: 'DK' },
  // { country: 'Estonia', code: 'EE' },
  // { country: 'Finland', code: 'FI' },
  // { country: 'France', code: 'FR' },
  // { country: 'Germany', code: 'DE' },
  // { country: 'Gibraltar', code: 'GI' },
  // { country: 'Greece', code: 'GR' },
  // { country: 'Hong Kong', code: 'HK' },
  // { country: 'Hungary', code: 'HU' },
  // { country: 'India', code: 'IN' },
  // { country: 'Indonesia', code: 'ID' },
  // { country: 'Ireland', code: 'IE' },
  // { country: 'Italy', code: 'IT' },
  // { country: 'Japan', code: 'JP' },
  // { country: 'Latvia', code: 'LV' },
  // { country: 'Liechtenstein', code: 'LI' },
  // { country: 'Lithuania', code: 'LT' },
  // { country: 'Luxembourg', code: 'LU' },
  // { country: 'Malaysia', code: 'MY' },
  // { country: 'Malta', code: 'MT' },
  // { country: 'Mexico', code: 'MX' },
  // { country: 'Netherlands', code: 'NL' },
  // { country: 'New Zealand', code: 'NZ' },
  // { country: 'Norway', code: 'NO' },
  // { country: 'Poland', code: 'PL' },
  // { country: 'Portugal', code: 'PT' },
  // { country: 'Romania', code: 'RO' },
  // { country: 'Singapore', code: 'SG' },
  // { country: 'Slovakia', code: 'SK' },
  // { country: 'Slovenia', code: 'SI' },
  // { country: 'Spain', code: 'ES' },
  // { country: 'Sweden', code: 'SE' },
  // { country: 'Switzerland', code: 'CH' },
  // { country: 'Thailand', code: 'TH' },
  // { country: 'United Arab Emirates', code: 'AE' },
  // { country: 'United Kingdom', code: 'GB' },
  // { country: 'United States', code: 'US' }
];

const SellerAccountCreation = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedCountry, setSelectedCountry] = useState({ country: 'Australia', code: 'AU' });

  const handleSelectChange = (event) => {
    const selectedCode = event.target.value;
    const selectedCountry = countries.find(country => country.code === selectedCode);
    setSelectedCountry(selectedCountry);
  };

  const validateSellerAccountDetails = async () => {
    try {
      
      setErrorMessage("");
      if (!selectedCountry || !selectedCountry.code) {
        setErrorMessage("Please select your region");
        return;
      } else {
        setErrorMessage("");
      }
      setIsLoading(true);
      const response = await experienceServices.createSellersAccount(selectedCountry);
      if (response.statusCode === 200) {
        if (response.data !== null && response.data[0].path !== null) {
            window.location.href = response.data[0].path;
        } else {
            setErrorMessage(response.message);
        }
      } else {
        setErrorMessage(response.message);
      }

    } catch (error) {
      console.error("API Error:", error);
      return false;
    }
  };

  return (
    <Container fluid className="mainPad viewportHt">

      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <Row className={styles.ESWhiteBackdrop}>

          <Row className="d-flex justify-content-between align-items-center">
            <Col xs="auto">
              <div className="d-flex align-items-center">
                <Link to="/seller-experiences/publish-experience">
                  <SlArrowLeft className={styles.HoverBackOption} /> &nbsp;&nbsp;&nbsp;&nbsp;
                </Link>
                <p className={styles.labelname}>Set up your banking details</p>
              </div>
              <div className={`mb-3 ${styles.labelContent}`}> Create a seller account with Stripe to continue </div>
            </Col>
          </Row>

          <Row className="d-flex justify-content-center align-items-center">
            {errorMessage && <div className={styles.errorMessage} style={{ textAlign: "center" }}>{errorMessage}</div>}
          </Row>

          <Row className="mb-3 mt-3 d-flex justify-content-center align-items-center">
            <Col className="d-flex flex-column align-items-center">
              <Form.Select aria-label="Country select" className={styles.countrySelectform}  onChange={handleSelectChange}>
                <option value="" disabled>Select the Country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country.code}>
                    {country.country}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Row className="mb-5 mt-5 d-flex justify-content-center align-items-center">
            <Col className="d-flex flex-column align-items-center">
              <Button className={styles.stripeProceedButton} onClick={validateSellerAccountDetails}>
                &nbsp; Proceed &nbsp;
              </Button>
            </Col>
          </Row>
        </Row>
      )}
    </Container>
  );
};

export default SellerAccountCreation;
