import { useEffect } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import payStyles from './cart.module.css';
import SmallFooter from '../../BaseComponents/SmallFooter';
import { useNavigate } from 'react-router-dom';


const PaymentSuccess = () => {
  
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      // window.location.href = `/home`;
      navigate('/home');
    }, 2000);
  }, [navigate]);
  

  return (
    <Container fluid className={`${payStyles.Background}`}>

            <Row className={`mainPad ${payStyles.viewportSetup}`}>   
                    <Row className={`text-center ${payStyles.contentSection}`}>
                        <label className={`${payStyles.paymentSuccessMsg}`}>Transaction Successful!</label>
                    
                            <Col>  
                                <Image
                                    src='/Content/Images/icons/icons_approve.png'
                                    alt="success_icon"
                                    className={payStyles.AprooveImg}   
                                    />
                            </Col>

                            <Link to='/home'>
                            <Button className={payStyles.dashboardBtn}>
                             Go to Dashboard
                            </Button>
                            </Link>

                    </Row>
            </Row>
               
            <Row className={`${payStyles.footerSec}`}>
                <SmallFooter />  
            </Row>

        
    </Container>
  );
};

export default PaymentSuccess;
