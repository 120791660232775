import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styles from './ExperienceStudio.module.css';
import { Link } from 'react-router-dom';
import { SlArrowLeft } from "react-icons/sl";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaCheckCircle } from "react-icons/fa";
import experienceServices from '../../../Services/experienceServices';
import LoadingOverlay from '../../BaseComponents/LoadingOverlay';


const MediaSequence = ({ onStatusChange, selectedMediaData, appendSelectedSequence }) => {

  // console.log("In MediaSequence Comp ::", selectedMediaData);
  const failureErrorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [failureError, setFailureError] = useState(false);
  const [currentSelectedMedia, setCurrentSelectedMedia] = useState(null);
  const [sequence, setSequence] = useState(1);
  const totalCount = selectedMediaData.length;
  const [sequenceOrder, setSequenceOrder] = useState(selectedMediaData);

  useEffect(() => {
    if (selectedMediaData.length > 0) {
      setCurrentSelectedMedia(selectedMediaData[0]);
    }
    if (failureError && failureErrorRef.current) {
          failureErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          failureErrorRef.current.style.backgroundColor = '#ffdddd'; // Highlight error message
        }
    }, [selectedMediaData, failureError]);


  const onDragEnd = (result) => {  
    if (!result.destination) {
      return;
    }
    const reorderedItems = Array.from(sequenceOrder);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setSequenceOrder(reorderedItems);

  };

  const clicktoSelectMediaItems = (media) => {
      setCurrentSelectedMedia((prevSelectedMedia) =>
        prevSelectedMedia === media ? null : media
      );
      const mediaIndex = selectedMediaData.findIndex((item) => item === media);
      if (mediaIndex !== -1) {
        setSequence(mediaIndex + 1);
      }
  };
  //console.log(sequenceOrder);
  
  const saveSequenceMedia = async () => {
    setFailureError("");
    try {
      setIsLoading(true);
      
      const response = await experienceServices.saveUploadedContentstoWorkshop(sequenceOrder, "contents", sequenceOrder.length);
      // console.log("appendSequenceMediatoDetails Response:", response);
      if (response !== null && response.length > 0) {
        appendSelectedSequence(response);
        onStatusChange('experience-details');
      } else {
        onStatusChange('setting-sequence');
        setFailureError(response.message || "Error while processing media files, try after some time.");
      }
    } catch (error) {
      setFailureError(error.message || "Error while processing media files, try after some time.");
    } finally {
      setIsLoading(false);
    }
  };

  const backtoPrevious = () => {
    onStatusChange('select-media');
  };


  return (
    <Container fluid className="mainPad">
      <Row className={styles.ESWhiteBackdrop}>
        <Row className="d-flex justify-content-between align-items-center">
          <Col xs="auto">
            <div className="d-flex align-items-center">
              <Link onClick={backtoPrevious}>
                <SlArrowLeft className={styles.HoverBackOption} /> &nbsp;&nbsp;&nbsp;&nbsp;
              </Link>
              <p className={styles.labelname}> Set Video Sequence </p>
            </div>
          </Col>
        </Row>
        <Row className='mb-3'>
          <div className={styles.labelContent}> Click and drag your videos to set the sequence. </div>
          {failureError && <div ref={failureErrorRef} key={failureError} className={styles.errorMessage} style={{textAlign: 'center'}}>{failureError}</div>}
        </Row>

        <Row>
        <Col md={1} xs={3}>
        <Row className={`d-flex justify-content-between  ${styles.progressframe}`} >
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <FaCheckCircle className={styles.circleCompleted} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Start</p>
          </Row>
        </Col>

   
        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.rectangle} />
          </Col>
          <Col>
            <div className={styles.currentRunIcon}>
              <div className={styles.blueBot}></div>
            </div>
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Select Media </p>
          </Row>
        </Col>
      

        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Product Details </p>
          </Row>
        </Col>
       
        
        <Col md={1} xs={3}>
        <Row className={styles.progressframe}>
          <Col className={styles.topline}>
            <div className={styles.strline} />
          </Col>
          <Col>
          <div className={styles.indicator2} />
          </Col>
          <Col className={styles.bottomline}>
            <div className={styles.strline} />
          </Col>
        </Row>
        <Row>
            <p className={styles.NameTag} >Publish </p>
          </Row>
        </Col>
        </Row>
      
        {isLoading ? (
          <LoadingOverlay />
          ) : (
          <Row className={`d-flex justify-content-between mb-4`}>
            
            <Col md={5}>
              {currentSelectedMedia && (
                <div className={styles.itemContainer}>
                  {currentSelectedMedia.type.startsWith('image') ? (
                    <img
                      className={styles.MediaSettingCont}
                      alt={currentSelectedMedia.name}
                      src={URL.createObjectURL(currentSelectedMedia)}
                    />
                  ) : currentSelectedMedia.type.startsWith('video') ? (
                    <video
                      className={styles.MediaSettingCont}
                      alt={currentSelectedMedia.name}
                      controls
                      controlsList='nodownload'
                    >
                      <source
                        src={URL.createObjectURL(currentSelectedMedia)}
                        type={currentSelectedMedia.type}
                      />
                    </video>
                  ) : null}
                </div>
              )}

            </Col>   

            <Col md={7}>
                <Row className="d-flex justify-content-start align-items-center minPadding">
                  <div className={styles.sequenceIndicator}>
                    {sequence} Of {totalCount}
                  </div>

                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="media-items">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {sequenceOrder.map((media, index) => (
                            <Draggable key={media.name} draggableId={media.name} index={index}>
                              {(dragProvided) => (
                                <div
                                  ref={dragProvided.innerRef}
                                  {...dragProvided.draggableProps}
                                  {...dragProvided.dragHandleProps}
                                  className={`d-inline-block ${styles.inlineBlock}`}
                                  onClick={() => clicktoSelectMediaItems(media)}
                                >
                                  {media.type.startsWith('image') ? (
                                    <img
                                      className={styles.ImagesSize}
                                      alt={media.name}
                                      width="99"
                                      height="99"
                                      src={URL.createObjectURL(media)}
                                    />
                                  ) : media.type.startsWith('video') ? (
                                    <video
                                      className={styles.ImagesSize}
                                      alt={media.name}
                                      width="120"
                                      height="120"
                                      // controls
                                    >
                                      <source src={URL.createObjectURL(media)} type={media.type} />
                                      Your browser does not support the video tag.
                                    </video>
                                  ) : null}
                                <div style={{position: 'relative'}}>
                                {media.type.startsWith('video') && <div style={{ position: 'absolute', top: '-100px', height: '120px', width: '120px' }}></div>}
                                  <Row className="d-flex justify-content-center align-items-center text-blue">
                                    <span className={styles.centeredText}>{index + 1}</span>
                                  </Row>
                                </div>
                                </div>
                                  
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Row>
            </Col>
          </Row>
      )}
      <Row className="justify-content-end">
        <Col xs="auto">
          <Button onClick={backtoPrevious} variant="outline-primary">&nbsp;&nbsp; Back &nbsp;&nbsp;</Button>&nbsp;&nbsp;
        </Col>
        <Col xs="auto">
          <Button variant="primary" onClick={saveSequenceMedia}>&nbsp;&nbsp; Confirm &nbsp;&nbsp;</Button>
        </Col>
      </Row>

      </Row>
    <br/><br/><br/>
    </Container>
  );
};

export default MediaSequence;