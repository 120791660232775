import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Nav, Tab, Button } from 'react-bootstrap';
import genStyles from './general.module.css';


const HostFAQs = () => {

    useEffect(() => {
        const handleTabClick = () => {
            window.scrollTo(0, 0); // Scrolls to the top of the page when a tab is clicked
        };
        document.querySelectorAll('.nav-link').forEach((tab) => {
            tab.addEventListener('click', handleTabClick);
        });
        return () => {
            document.querySelectorAll('.nav-link').forEach((tab) => {
                tab.removeEventListener('click', handleTabClick);
            });
        };
    }, []);

    const navigate = useNavigate();

    const handleClose = () => {
        navigate(-1); // Go back to the previous page
    };


  return (

      <main>
        <div className={`viewportHt ${genStyles.generalBoxContainer}`}>
                
                <Row className='minPadding'>
                    <Link onClick={handleClose} style={{textDecoration: 'none', textAlign: 'end'}}>
                        <label className={genStyles.CloseLabel}>Close</label>
                    </Link>
                </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3} className={`minPadding`}>
                    <Nav variant="pills" className={`flex-column ${genStyles.MainBoxSetup}`}>
                        <Button className={genStyles.mainButtonPP}>Host FAQs</Button>
                        
                    </Nav>
                    </Col>

                    <Col sm={9} className={`minPadding`}>
                    <Tab.Content className={`${genStyles.MainBoxSetup}`}>
                        <h2 className={` ${genStyles.mainHeaderTerms} m-0 `} >Hapifyr Host's Frequently asked Questions & Answers </h2>
                        
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>
      </main>


  );
}

export default HostFAQs;
