import React, { useState } from 'react';
import Header from './Navbar/Header';
import ExperienceHubStreamPage from './Engage/ExperienceHubStreamPage';

const ExperienceHubItem = () => {

  const [loginStatus] = useState('loggedIn');


  return (

    <div className="ExperienceHubDashboardDiv">
      
      <Header loginStatus={loginStatus} />
      
      <ExperienceHubStreamPage />
       
      <footer/>
    </div>

  );
}

export default ExperienceHubItem;
