import { useState, useEffect } from 'react';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import SendBirdChatLists from './Messaging/SendBirdChatLists';
import OnetoOneChat from './Messaging/OnetoOneChat';
import ListOfUsersforGroup from './Messaging/ListOfUsersforGroup';
import MessagingFooter from './Messaging/MessagingFooter';
import { Container } from 'react-bootstrap';


const SendBird = ({ openChat, openUrl }) => {
    
    // console.log('openChat', openChat, "openURL", openUrl);
    const [type, setType] = useState('conversations');
    const [form, setForm] = useState(null);
    const [channelUrl, setChannelUrl] = useState(null);
    const [openUsers, setOpenUsers] = useState(false);

    useEffect(() => {
      // console.log('Props changed:', { openChat, openUrl }); // Debugging
      if (openChat) {
          setType(openChat);
      }
      if (openUrl) {
          setChannelUrl(openUrl);
      }
  }, [openChat, openUrl]);

    const handleType = (type) => {
        setType(type);
      };
    const handleForm = (form) => {
      setForm(form);
      };
    const handleChannelURL = (url) => {
        setChannelUrl(url);
      };

  return (
    <Container className='stickySidebar noPadding'>
      <div className="MessagingSection boxBody">
        <SendbirdProvider>
          {type === 'conversations' && <SendBirdChatLists changeType={handleType} changeUrl={handleChannelURL} onOpenUsers={() => setOpenUsers(true)} activeForm={form} />}
          {type === 'one-to-one' && <OnetoOneChat channelUrl={channelUrl} changeType={handleType} changeForm={handleForm} />}
          {type === 'group-chat' && <OnetoOneChat channelUrl={channelUrl} changeType={handleType} changeForm={handleForm} />}
          {type === 'create-group' && <ListOfUsersforGroup openUsers={openUsers} changeType={handleType} openingGroupChat={handleChannelURL} changeForm={handleForm} closeModal={() => setOpenUsers(false)} /> }

        </SendbirdProvider>
      </div>
      
      <MessagingFooter />
    </Container>

  );
}

export default SendBird;

