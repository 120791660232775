import { useEffect } from 'react';
import { Container, Row, Image } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './base.module.css';


const CommonSuccess = () => {
  
  const navigate = useNavigate();
  const { commonStatus } = useParams();

  useEffect(() => {
    setTimeout(() => {
        if(commonStatus === 'stripe-account-details') {
            const profileData = localStorage.getItem('userProfileData');
            if (profileData) {
                const parsedData = JSON.parse(profileData);
                parsedData.stripeAccountExists = true;
              localStorage.setItem('userProfileData', JSON.stringify(parsedData));
            }
            navigate('/profile/stripe-account-details');
        } else {
            return false;
        }
    }, 2000);
  }, [navigate, commonStatus]);


  return (
    <Container fluid className={`${styles.Background}`}>
            <Row className={`mainPad ${styles.viewportSetup}`}>
                <Row className={`text-center ${styles.contentSection}`}>
                    <Image
                      src="/Content/Images/General/user-success-bg.png"
                      className={styles.commonSuccessIcon}
                      alt="success"
                    />
                    <h2 className={styles.commonSuccessStatus}>Success</h2>
                    {commonStatus === 'stripe-account-details' && (
                        <label className={styles.commonSuccessText}>
                            Your Stripe account signup has been successfully completed.
                        </label>
                    )}
                </Row>
            </Row>
    </Container>
  );
};

export default CommonSuccess;
