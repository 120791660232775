import React from 'react';
import { Row, Col } from 'react-bootstrap';
import UserProfile from '../BaseComponents/UserProfile';
import SellerDBmidContent from './SellerDBmidContent';
import SendBird from '../SendBird';
import SellerdbActions from './SellerdbActions';
import styles from './SellerStyle.module.css';



const SellerdbContent = () => {


  return (
      <main className="MainpartSetup HomeViewportHt">
        <Row className={`mainPad stickySidebar`}>
          <Col xs={12} lg={3} className={`${styles.responsiveDisplay}`} >
            <UserProfile />
            <br />
            <SellerdbActions />
          </Col>
          <Col xs={12} lg={6}>
            <SellerDBmidContent />
          </Col>
          
          <Col xs={12} lg={3} className={`${styles.responsiveDisplay}`}>
            <SendBird />
          </Col>
        </Row>
      </main>

  );
}

export default SellerdbContent;
